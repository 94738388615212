import { AnimateSharedLayout, motion } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import AdMarketDropdown from '../Dropdown'
import s from './ADMarketTreeDropDown.module.scss'

type TreeValue = {} & {
	id: number
	title: string
	parent_id: number | null
}

interface ADMarketTreeDropDownProps {
	selectedIDs: number[]
	tree: TreeValue[]
	onChange: (ids: number[]) => void
	children: ({
		onChange,
		isItemInSelected,
	}: {
		onChange: (ids: number[]) => void
		isItemInSelected: (itemID: number) => boolean
		searchString: string
	}) => JSX.Element
	withSearch?: boolean
	displayCount?: number
	error: null | string
}

const ADMarketTreeDropDown = ({
	selectedIDs,
	onChange,
	withSearch = true,
	children,
	displayCount = 7,
	tree,
	error,
}: ADMarketTreeDropDownProps) => {
	const [search, setSearch] = useState<string>('')

	const isItemInSelected = useCallback(
		(itemID: number) => {
			return selectedIDs.includes(itemID)
		},
		[selectedIDs]
	)

	const selectedRegions = useMemo<TreeValue[]>(() => {
		const result: TreeValue[] = []
		iterateTree(tree, (val) => {
			if (selectedIDs.includes(val.id)) result.push(val)
		})
		return result
	}, [selectedIDs, tree])

	return (
		<>
			<AdMarketDropdown
				disabled={true}
				menu={({ close }) => (
					<div className={s.menu}>
						{withSearch && (
							<div className={s.search}>
								<Form.Control
									type={'text'}
									placeholder={'Поиск'}
									onChange={(e) => setSearch(e.target.value)}
								/>
								<i className="bi bi-search"></i>
							</div>
						)}
						<div className={s.menuData}>
							{children &&
								children({
									onChange,
									isItemInSelected,
									searchString: search,
								})}
						</div>
						<div className={s.menuControls}>
							<div>
								<a
									onClick={(e) => {
										e.preventDefault()

										const ids: number[] = []
										iterateTree(tree, (val: TreeValue) => {
											ids.push(val.id)
										})

										onChange(ids)
										close()
									}}
									href={'/'}
								>
									Выбрать все
								</a>
							</div>
							<div>
								<a
									onClick={(e) => {
										e.preventDefault()
										onChange([])
									}}
									href={'/'}
								>
									Снять все
								</a>
							</div>
							<div>
								<Button
									onClick={close}
									style={{ marginLeft: '-12px' }}
								>
									Готово
								</Button>
							</div>
						</div>
					</div>
				)}
				zIndex={50}
				offset={0}
				menuWidth={'100%'}
			>
				<div
					className={`form-control form-control-lg noselect ${
						s.trigger
					} ${error ? 'is-invalid' : ''}`}
					tabIndex={1}
				>
					<AnimateSharedLayout>
						{selectedRegions &&
							selectedRegions.map((item, index) => {
								if (index > displayCount - 1) return null
								return (
									<motion.div
										key={item.id}
										className={s.itemSelected}
										layout
									>
										{item.title}
										<i
											className={
												'bi bi-x ps-1 text-secondary'
											}
											onClick={(e) => {
												e.stopPropagation()
												const new_value =
													selectedIDs.filter(
														(data) =>
															![
																item.id,
																item.parent_id,
															].includes(data)
													)
												onChange(new_value)
											}}
										/>
									</motion.div>
								)
							})}
						{selectedRegions.length > displayCount && (
							<motion.div className={s.itemSelected} layout>
								+{selectedRegions.length - displayCount}
							</motion.div>
						)}
						{selectedRegions.length === 0 && (
							<span className={'text-secondary'}>
								Выберите значения из выпадающего списка
							</span>
						)}
					</AnimateSharedLayout>
					{!error && (
						<div
							style={{
								position: 'absolute',
								top: 'calc(50% + 2)',
								right: 16,
							}}
						>
							<svg
								width="10"
								height="5"
								viewBox="0 0 10 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0H0L5 5L10 0Z" fill="#495057" />
							</svg>
						</div>
					)}
				</div>
			</AdMarketDropdown>
			{error && (
				<div
					className={'invalid-feedback'}
					style={{ display: 'block' }}
				>
					{error || 'Ошибка'}
				</div>
			)}
		</>
	)
}

export function iterateTree(tree, cb) {
	for (let item of tree) {
		cb(item)
		if (item.children) iterateTree(item.children, cb)
	}
}

export default ADMarketTreeDropDown
