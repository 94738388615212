import { useCallback, useEffect } from 'react'
import useUserData from '../../stores/userData'
import { useEventListeners } from '../../utils/useEventListeners'

const ACTIVITY_THRESHOLD = 12 * 60 * 60 * 1e3

export default function ActivityController() {
	const [, useInterface] = useUserData()
	const { addEventListener, cleanupListeners } = useEventListeners()
	const updateActivityTime = useCallback(() => {
		const activityTime = localStorage.getItem('admarket-last-active')
		if (activityTime) {
			if (Number(new Date()) > +activityTime + ACTIVITY_THRESHOLD) {
				useInterface.logout()
			} else {
				localStorage.setItem(
					'admarket-last-active',
					`${Number(new Date())}`
				)
			}
		}
	}, [useInterface])
	useEffect(() => {
		updateActivityTime()
	})
	useEffect(() => {
		addEventListener(document, 'click', updateActivityTime)
		addEventListener(window, 'beforeunload', updateActivityTime)
		return cleanupListeners
	}, [updateActivityTime, addEventListener, cleanupListeners])

	// function updateActivityTime() {
	//   const activityTime = localStorage.getItem("admarket-last-active");
	//   if (activityTime) {
	//     if (Number(new Date()) > +activityTime + ACTIVITY_THRESHOLD) {
	//       useInterface.logout();
	//     } else {
	//       localStorage.setItem("admarket-last-active", `${Number(new Date())}`);
	//     }
	//   }
	// }

	return null
}
