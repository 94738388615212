import s from './ImageFullScreenWrapper.module.css'

import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import ReactDOM from 'react-dom'

interface IImageFullScreenWrapper {
	open: boolean
	setOpen: any
}

const ImageFullScreenWrapper: React.FC = (props) => {
	const [open, setOpen] = useState(false)
	return (
		<>
			<div
				style={{ cursor: 'zoom-in', width: 'fit-content' }}
				onClick={() => setOpen(true)}
			>
				{props.children}
			</div>
			<AnimatePresence exitBeforeEnter>
				{open && <Portal {...props} open={open} setOpen={setOpen} />}
			</AnimatePresence>
		</>
	)
}

const Portal = ({ open, setOpen, ...props }) => {
	return ReactDOM.createPortal(
		<Body open={open} setOpen={setOpen} {...props} />,
		document.body
	)
}

const Body: React.FC<IImageFullScreenWrapper> = ({
	open,
	children,
	setOpen,
}) => {
	const bodyStyle = useRef<string>('')
	useEffect(() => {
		if (open) {
			bodyStyle.current = document.body.style.overflow
			document.body.style.overflow = 'hidden'
			document.body.style.cursor = 'zoom-out'
		}

		return () => {
			document.body.style.overflow = bodyStyle.current
			document.body.style.cursor = ''
		}
	}, [open])

	useEffect(() => {
		const handleEscape = (e: KeyboardEvent) =>
			e.key === 'Escape' ? setOpen(false) : null

		document.body.addEventListener('keydown', handleEscape)

		return () => document.body.removeEventListener('keydown', handleEscape)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	let elements = React.Children.toArray(children)

	if (elements.length === 1) {
		// @ts-ignore
		elements = React.cloneElement(elements[0], {
			style: {
				width: 'inherit',
				height: 'inherit',
				maxWidth: 'inherit',
				maxHeight: 'inherit',
				cursor: 'zoom-out',
			},
		})
	}
	return (
		<motion.dialog
			className={s.body}
			id={'image-fullscreen'}
			open
			onClick={() => setOpen(false)}
			// transition={{ duration: 1 }}
		>
			<motion.div
				className={s.content}
				initial={{ scale: 0.2, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				exit={{ scale: 0.2, opacity: 0 }}
				transition={{ duration: 0.2 }}
			>
				{elements}
			</motion.div>
		</motion.dialog>
	)
}
export default ImageFullScreenWrapper
