import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { ReactComponent as GoLeftIcon } from 'src/assets/images/go-left.svg'
import s from './backToSelectorsCard.module.scss'

type Props = {
	onBackClick: () => void
}
export const BackToSelectorsCard = ({ onBackClick }: Props) => {
	return (
		<Card className={s.backToSelectorsCard} onClick={onBackClick}>
			<div className={s.backToSelectorsCardContent}>
				<GoLeftIcon />

				<p>Вернуться ко всем форматам рекламы</p>
			</div>
		</Card>
	)
}
