import ReactDOM from 'react-dom'
import React, { useEffect, useState } from 'react'
import s from './Bottomsheet.module.css'
import {
	AnimatePresence,
	motion,
	PanInfo,
	useDragControls,
} from 'framer-motion'
// @ts-ignore
import { useResizeDetector } from 'react-resize-detector'

interface IBottomSheet {
	show: boolean
	onHide: () => void
	bgOpacity?: number
	Footer?: React.ReactNode
	withCloseButton?: boolean
	fullSize?: boolean
	touchEvents?: boolean
}

const BottomSheet: React.FC<IBottomSheet> = (props) => {
	return ReactDOM.createPortal(
		<AnimatePresence exitBeforeEnter>
			{props.show && <Body {...props} />}
		</AnimatePresence>,
		document.body
	)
}

export default BottomSheet

const Body: React.FC<IBottomSheet> = ({
	children,
	show,
	onHide,
	bgOpacity = 0.5,
	Footer,
	withCloseButton,
	fullSize = false,
	touchEvents = true,
}) => {
	const { ref } = useResizeDetector({
		refreshMode: 'debounce',
	})
	const controls = useDragControls()

	const [maxHeight, setMaxHeight] = useState(fullSize ? '75vh' : '50vh')
	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [show])
	const onDrag = (
		event: MouseEvent | TouchEvent | PointerEvent,
		info: PanInfo
	) => {
		if (info.offset.y > 20) {
			if (info.offset.y > 200) {
				return onHide()
			}
			if (maxHeight === '75vh') {
				if (fullSize) return onHide()
				return setMaxHeight('50vh')
			} else {
				onHide()
			}
		}
		if (info.offset.y < -20) {
			setMaxHeight('75vh')
		}
	}

	const variants = {
		init: { top: '110%', maxHeight: maxHeight },
		half: {
			top: `calc(100% - 50vh - env(safe-area-inset-bottom)`,
			maxHeight: '50vh',
			height: '50vh',
		},
		full: {
			top: `calc(100% - 75vh - env(safe-area-inset-bottom)`,
			maxHeight: '75vh',
			height: '75vh',
		},
	}
	return (
		<div className={s.container}>
			<motion.div
				className={s.bg}
				onClick={onHide}
				initial={{ opacity: 0 }}
				animate={{ opacity: bgOpacity }}
				exit={{ opacity: 0 }}
				key={'bg'}
				style={{ touchAction: 'none' }}
			/>
			<motion.div
				key={'content'}
				ref={ref}
				className={s.sheet}
				onClick={(e) => {
					e.stopPropagation()
				}}
				initial={'init'}
				exit={'init'}
				variants={variants}
				animate={maxHeight === '50vh' ? 'half' : 'full'}
				dragListener={false}
				dragControls={controls}
				drag="y"
				onDragEnd={onDrag}
				dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
			>
				<motion.div
					className={s.touch}
					onPointerDown={(e) => controls.start(e)}
					onClick={() => {
						if (maxHeight === '50vh') {
							setMaxHeight('75vh')
						}
						if (maxHeight === '75vh') {
							setMaxHeight('50vh')
						}
					}}
					style={!touchEvents ? { pointerEvents: 'none' } : {}}
				/>
				<motion.div
					className={s.children}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					{withCloseButton && (
						<div className={s.close} onClick={onHide}>
							<i
								className="bi bi-x"
								style={{ fontSize: '20px' }}
							/>
						</div>
					)}
					{children}
				</motion.div>
				{Footer && <div className={s.footer}>{Footer}</div>}
			</motion.div>
		</div>
	)
}
