import React, { useEffect } from 'react'
import s from './MobileModal.module.css'
import {
	AnimatePresence,
	motion,
	PanInfo,
	useDragControls,
} from 'framer-motion'
import ReactDOM from 'react-dom'
import { useEventListeners } from '../../utils/useEventListeners'
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg'

interface IBottomSheet {
	show: boolean
	onHide: () => void
	bgOpacity?: number
	withCloseButton?: boolean
	title: string
	zIndex?: number
	paddingContent?: string
}
const MobileModal: React.FC<IBottomSheet> = (props) => {
	return ReactDOM.createPortal(
		<AnimatePresence exitBeforeEnter>
			{props.show && <Body {...props} />}
		</AnimatePresence>,
		document.body
	)
}

const Body: React.FC<IBottomSheet> = ({
	show,
	onHide,
	title,
	children,
	zIndex = 1000,
	paddingContent = '1em',
}) => {
	const { addEventListener, cleanupListeners } = useEventListeners()
	useEffect(() => {
		addEventListener(
			document.getElementById('touch-area'),
			'touchstart',
			(e) => {
				e.stopPropagation()
				e.preventDefault()
			}
		)
		return cleanupListeners
	}, [addEventListener, cleanupListeners])
	const controls = useDragControls()
	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [show])
	const onDrag = (
		event: MouseEvent | TouchEvent | PointerEvent,
		info: PanInfo
	) => {
		if (info.offset.x > 60) {
			return onHide()
		}
	}
	return (
		<motion.dialog
			className={s.body}
			initial={{ left: 1000 }}
			animate={{ left: 0 }}
			exit={{ left: 1000 }}
			id={'mobile-dialog'}
			open
			dragListener={false}
			dragControls={controls}
			drag="x"
			onDragEnd={onDrag}
			dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
			dragDirectionLock={true}
			style={{ zIndex, padding: '0 0 2em 0' }}
		>
			<motion.div
				className={s.touch}
				id={'touch-area'}
				onPointerDown={(e) => controls.start(e)}
			/>
			<div className={s.header}>
				<Arrow
					fill={'#000'}
					style={{
						position: 'absolute',
						left: 15,
						top: 14,
						transform: 'scale(1.6)',
					}}
					onClick={(e) => {
						e.stopPropagation()
						onHide()
					}}
				/>
				{title}
			</div>
			<div className={s.content} style={{ padding: paddingContent }}>
				{children}
			</div>
		</motion.dialog>
	)
}
export default MobileModal
