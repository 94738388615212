import React, { FC, useMemo, useState } from 'react'
import { InternetUploadForm } from './InternetUpload'
import { YandexIcon } from '../../Step2-ADformats/InternetAD/InternetSelector'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import FormLabelTooltip from '../../../../UtilityComponents/FormLabelTooltip'
import { InternetTooltips } from '../../Step2-ADformats/InternetAD/InternetTooltips'
import useCampaignData from '../../../../../stores/campaignData'
import { internetYandex } from '../../../../../constants/api'
import useBidRequest, { bidInfo } from '../CreateCreative/useBidRequest'
import CreativeFileInput from '../../../../UtilityComponents/CreativeFileInput'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import useResponsive from '../../../../../utils/useResponsive'
import { CreateCreativeFields } from '../CreateCreative/CreateCreativeForm'
import formatCreativeFormValues from '../CreateCreative/formatCreativeFormValues'
import { authFetch } from 'src/utils/authFetch'
// import Iframe from 'react-iframe'

interface IUploadYandex {
	values: InternetUploadForm
	setFieldValue: (field_name: keyof InternetUploadForm, value: any) => null
	setFieldError: (field_name: keyof InternetUploadForm, error: any) => null
	errors: { [key in keyof InternetUploadForm]?: string }
}

const UploadYandex: FC<IUploadYandex> = ({
	setFieldError,
	errors,
	setFieldValue,
	values,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isHaveBidRequest } = useBidRequest()
	const { isMobile } = useResponsive()
	const [modalOpen, setModalOpen] = useState(false)
	const [iframeUrl, setIframeUrl] = useState('')
	const [idframe, setIdFrame] = useState('')
	const modalType = useMemo<REUSABLE_MODALS>(() => {
		return isMobile
			? REUSABLE_MODALS.createCreativeMobileModal
			: REUSABLE_MODALS.createCreativeDesktopModal
	}, [isMobile])
	const showModal = useReusableModals()
	const bidInfo: bidInfo = {
		id: campaign.internet_yandex.id,
		type: 'internet_yandex',
	}

	const patchSingleField = async (
		field: keyof InternetUploadForm,
		value: string | number
	) => {
		const correctFieldName = field.replace(field.split('_')[0] + '_', '')
		const prevValue = campaign.internet_yandex[correctFieldName]
		if (value === prevValue) return
		const formData = new FormData()
		formData.append(correctFieldName, value.toString())
		await campaignInterface.patchInternetYandex(true)
	}

	const controlValidationYandexText = () => {
		if (values.yandex_text !== '' && values.yandex_text.length <= 81) {
			patchSingleField('yandex_text', values.yandex_text).then()
		} else if (values.yandex_text.length > 81) {
			setFieldError(
				'yandex_text',
				'Тех.ограничение "Яндекс" длина не должна привышать 81 символ'
			)
		} else {
			setFieldError('yandex_text', 'Введите текст объявления')
		}
	}

	const controlValidationYandexHeading = () => {
		if (
			values.yandex_heading !== '' &&
			values.yandex_heading.length <= 81
		) {
			patchSingleField('yandex_heading', values.yandex_heading).then()
		} else if (values.yandex_heading.length > 81) {
			setFieldError(
				'yandex_heading',
				'Тех.ограничение "Яндекс" длина не должна привышать 81 символ'
			)
		} else {
			setFieldError('yandex_heading', 'Введите заголовок')
		}
	}

	const handleCreateMaterial = async () => {
		const requestData = {
			cls_name: 'yandexinternetadvertising',
			instance_id: campaign.internet_yandex.id,
		}

		try {
			const response = await authFetch({
				method: 'POST',
				url: `/core/api/v1/vinstant/adv_companies/${campaign.id}/`,
				body: requestData,
			})

			const url: any = response
			// setIframeUrl(url.redirect_url);
			// setIdFrame(url.banner_id)
			// setModalOpen(true);

			// const editorWindow = window.open(url.redirect_url, 'Редактор', 'width=800,height=600');

			// window.location.href = url.redirect_url;
			window.open(url.redirect_url, '_blank')
		} catch (error) {
			console.error('Ошибка при отправке запроса:', error)
		}
	}

	const closeModal = () => {
		setModalOpen(false)
		setIframeUrl('')
	}

	return (
		<div className={'d-flex flex-column gap-4'}>
			{modalOpen && (
				<Modal
					onClose={closeModal}
					show={modalOpen}
					dialogClassName="modal-fullscreen"
				>
					<Modal.Header closeButton>
						<button className="close" onClick={closeModal}>
							&times;
						</button>
					</Modal.Header>
					<Modal.Body>
						{/* <iframe src={iframeUrl} title="Модальное окно" className="modal-iframe" /> */}
						{/* <Iframe
							url={iframeUrl}
							width="100%"
							height="500px"
							id={idframe}
							className="modal-iframe"
							display="initial"
							position="relative"
						/> */}

						{/* <Embed url={iframeUrl} /> */}
					</Modal.Body>
				</Modal>
			)}
			<div className={'d-flex justify-content-between'}>
				<h5 className={'mb-0'}>Яндекс</h5>
				<div>
					<YandexIcon />
				</div>
			</div>
			<Alert
				variant={'secondary'}
				className={'d-flex align-items-start gap-3 mb-0'}
			>
				<i
					className={'bi bi-info-circle'}
					style={{ fontSize: '20px' }}
				/>
				<span>
					Укажите информацию ниже и загрузите материал для рекламного
					баннера. Если материал нет, мы можем сделать его за вас. Для
					этого нажмите на кнопку «Создать материал».
				</span>
			</Alert>
			{/*Heading*/}
			<Form.Group>
				<Form.Label>
					Что будете рекламировать?
					<FormLabelTooltip text={InternetTooltips.YANDEX.heading} />
				</Form.Label>
				<Form.Control
					type={'text'}
					size={'lg'}
					value={values.yandex_heading}
					onChange={(e) => {
						setFieldError('yandex_heading', undefined)
						setFieldValue('yandex_heading', e.target.value)
					}}
					onBlur={() => controlValidationYandexHeading()}
					isInvalid={!!errors.yandex_heading}
					placeholder={'Не задан'}
				/>
				<Form.Control.Feedback type={'invalid'}>
					{errors.yandex_heading}
				</Form.Control.Feedback>
			</Form.Group>
			{/*Text*/}
			<Form.Group>
				<Form.Label>
					Описание вашего товара или услуги{' '}
					<FormLabelTooltip text={InternetTooltips.YANDEX.text} />
				</Form.Label>
				<Form.Control
					type={'text'}
					size={'lg'}
					value={values.yandex_text}
					onChange={(e) => {
						setFieldError('yandex_text', undefined)
						setFieldValue('yandex_text', e.target.value)
					}}
					onBlur={() => controlValidationYandexText()}
					isInvalid={!!errors.yandex_text}
					placeholder={'Не задан'}
				/>
				<Form.Control.Feedback type={'invalid'}>
					{errors.yandex_text}
				</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>
					Рекламный материал
					<FormLabelTooltip
						text={
							'Требования к материалам:\n' +
							'Формат: JPEG, PNG, GIF.\n' +
							'Разрешение: 1920 x 1080 px.\n' +
							'Размер: до 5 МБ.'
						}
					/>
				</Form.Label>

				<CreativeFileInput
					bidInfo={bidInfo}
					initialValue={{
						src: campaign.internet_yandex.image,
						name: 'Текущий материал',
					}}
					updateUrl={internetYandex.params(campaign.id)}
					nameInBody={'image'}
					description={
						' Изображения — JPEG, PNG. Разрешение: 1920 x 1080 px. Размер: до 5 МБ.'
					}
					removeMethod={'PATCH'}
					onFileLoad={() => campaignInterface.refetchSelected()}
					onFileDelete={() => campaignInterface.refetchSelected()}
				/>
			</Form.Group>
			{/*<ModalInternetMediaRequirements internetName={'YANDEX'} />*/}
			{!isHaveBidRequest(bidInfo) && !campaign.internet_yandex.image && (
				<Button
					variant={'light'}
					className={'w-100'}
					onClick={
						handleCreateMaterial

						// () => {
						// 	showModal<CreateCreativeFields>(modalType).then(
						// 		async (values) => {
						// 			await campaignInterface.createNewBidRequest(
						// 				formatCreativeFormValues(values),
						// 				bidInfo
						// 			)
						// 			showModal(
						// 				REUSABLE_MODALS.showCreateCreativeSuccess
						// 			).then()
						// 		}
						// 	)
						// }
					}
				>
					Создать материал
				</Button>
			)}
		</div>
	)
}

export default UploadYandex
