import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

export const NoImage = () => (
	<div
		style={{
			width: '80px',
			height: '80px',
			minWidth: '80px',
			minHeight: '80px',
			background: '#F5F5F5',
			borderRadius: '4px',
			border: '1px solid #CED4DA',
		}}
		className={
			'd-flex justify-content-center align-items-center text-secondary mt-2'
		}
	>
		Нет
	</div>
)
export const PlaceholderPDF = ({ link }) => (
	<a
		style={{
			width: '80px',
			height: '80px',
			minWidth: '80px',
			minHeight: '80px',
			background: '#F5F5F5',
			borderRadius: '4px',
			border: '1px solid #CED4DA',
		}}
		className={
			'd-flex justify-content-center align-items-center text-secondary'
		}
		href={link}
		target={'_blank'}
		rel={'noreferrer'}
	>
		PDF
	</a>
)
export const PlaceholderVIDEO = ({ link }) => {
	const [open, setOpen] = useState(false)

	return (
		<>
			<a
				style={{
					width: '80px',
					height: '80px',
					minWidth: '80px',
					minHeight: '80px',
					background: '#F5F5F5',
					borderRadius: '4px',
					border: '1px solid #CED4DA',
					cursor: 'pointer',
				}}
				className={'d-flex justify-content-center align-items-center'}
				onClick={() => setOpen(true)}
				rel={'noreferrer'}
			>
				<video
					style={{
						width: '80px',
						height: '80px',
						minWidth: '80px',
						minHeight: '80px',
					}}
					src={link}
				/>
			</a>
			<Modal show={open} onHide={() => setOpen(false)} centered>
				<video controls src={link} />
			</Modal>
		</>
	)
}
