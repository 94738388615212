import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import AuthPage from './components/auth/AuthPage'
import ROUTES from './constants/Routes'
import React from 'react'
import ToastsContextProvider from './components/UtilityComponents/ToastsContextProvider'
import ActivityController from './components/UtilityComponents/ActivityController'
import InviteProviderPage from './components/_provider/provider_invite/InviteProviderPage'
import PrivateUserRoute from './components/UtilityComponents/PrivateUserRoute'
import ProviderPage from './components/_provider/provider_page/ProviderPage'
import PrivateProviderRoute from './components/UtilityComponents/PrivateProviderRoute'
import CreateCampaignPage from './components/_client/create-campaign/CreateCampaignPage'
import { ConfirmModalProvider } from './components/UtilityComponents/ConfirmModalProvider'
import NotificationCheckRoute from './components/UtilityComponents/NotificationCheckRoute'
import ProfileCheckRoute from './components/UtilityComponents/ProfileCheckRoute'
import SettingsCheckRoute from './components/UtilityComponents/SettingsCheckRoute'
import ErrorBoundary from './components/UtilityComponents/ErrorBoundary'
import * as Sentry from '@sentry/react'
import DeveloperRouteCheck from './components/UtilityComponents/DeveloperRouteCheck'
import UserOperationsHistoryPage from './components/_client/user-operations/UserOperationsHistoryPage'
import ReusableModalsProvider from './components/UtilityComponents/ReusableModals/ReusableModalsProvider'
import CreateReportPage from './components/_client/create-report/CreateReportPage'

function AppRouter() {
	return (
		<>
			<ActivityController />
			<ToastsContextProvider>
				<ConfirmModalProvider>
					<ReusableModalsProvider>
						<BrowserRouter>
							<Switch>
								<Route path={'/'}>
									<CreateCampaignPage />
								</Route>
							</Switch>
						</BrowserRouter>
					</ReusableModalsProvider>
				</ConfirmModalProvider>
			</ToastsContextProvider>
		</>
	)
}

export default Sentry.withErrorBoundary(AppRouter, {
	fallback: ErrorBoundary,
})
