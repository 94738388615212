import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import useQuery from '../../../../utils/useQuery'
import { BASE_URL } from '../../../../constants/api'
import ADMarketTreeDropDown from '../../../UtilityComponents/ADMarketTreeDropDown/ADMarketTreeDropDown'
import { TREE_DATA } from 'src/mock'
import { TOOLTIPS } from '../tooltips.data'
import FormLabelTooltip from 'src/components/UtilityComponents/FormLabelTooltip'
interface IRegionSelector {
	value: number[]
	setFieldValue: (name: string, value: number[]) => null
	setFieldError: (name: string, error: any) => null
	errors: string
}

type Region = {
	id: number
	title: string
	parent_id: number
}

type RegionWithChildren = Region & { children: RegionWithChildren[] }

const RegionSelector: FC<IRegionSelector> = ({
	errors,
	setFieldError,
	setFieldValue,
	value,
}) => {
	const regionsTree = TREE_DATA.map(withParentInChilds) || []

	const handleNodeToggle = (node: RegionWithChildren) => {
		setFieldError('regions', null)
		if (value.includes(node.id)) {
			//already selected
			if (node.children.length !== 0) {
				const all_ids = getAllChildrenIds(node)
				setFieldValue(
					'regions',
					value.filter(
						(e) =>
							!all_ids.includes(e) &&
							e !== node.parent_id &&
							e !== node.id
					)
				)
			} else {
				setFieldValue(
					'regions',
					value.filter(
						(id) => id !== node.id && id !== node.parent_id
					)
				)
			}
		} else {
			const nextValueWithDuples = [
				...value,
				node.id,
				...getAllChildrenIds(node),
			]

			let valuesObj = {}
			nextValueWithDuples.forEach((id) => {
				valuesObj[id] = id
			})

			if (node.parent_id && regionsTree?.[0]) {
				const parentNode = getTreeItem(regionsTree[0], node.parent_id)
				let allSelected = true
				for (let child of parentNode?.children || []) {
					if (!valuesObj[child.id] && child.id !== node.id) {
						allSelected = false
						break
					}
				}
				if (allSelected) valuesObj[node.parent_id] = node.parent_id
			}

			setFieldValue('regions', Object.values(valuesObj))
		}
	}

	return (
		<Form.Group>
			<Form.Label>Регион показа рекламы {' '}</Form.Label>
			<ADMarketTreeDropDown
				selectedIDs={value}
				tree={regionsTree}
				onChange={(items) => {}}
				error={errors}
			>
				{({ onChange, isItemInSelected, searchString }) => (
					<div className={'p-3'}>
						<RegionsTree
							tree={
								(regionsTree as RegionWithChildren[])[0] || {
									children: [],
								}
							}
							onChange={onChange}
							isItemInSelected={isItemInSelected}
							searchString={searchString}
							handleNodeToggle={handleNodeToggle}
						/>
					</div>
				)}
			</ADMarketTreeDropDown>
		</Form.Group>
	)
}

const RegionsTree: FC<{
	tree: RegionWithChildren
	onChange: (ids: number[]) => void
	isItemInSelected: (itemID: number) => boolean
	searchString: string
	handleNodeToggle: (node: RegionWithChildren) => void
}> = ({ tree, onChange, isItemInSelected, searchString, handleNodeToggle }) => {
	const [showTree, setShowTree] = useState(tree?.parent_id === null)

	const isItemHalfChecked = (item): boolean => {
		if (isItemInSelected(item.id) || item.children.length === 0)
			return false
		let counter = 0
		item.children.forEach((ch) => {
			if (isItemInSelected(ch.id) || isItemHalfChecked(ch)) counter++
		})
		return counter !== 0 && counter !== item.children.length
	}

	const recursiveCheckSearchAndSelected = (
		tr: RegionWithChildren,
		searchString: string
	): boolean => {
		if (tr.parent_id === null) return true

		if (
			tr.title.toLowerCase().includes(searchString.toLowerCase()) ||
			isItemInSelected(tr.id)
		)
			return true
		if (tr.children.length === 0) return false
		let condition = true
		let result = false
		while (condition) {
			for (const ch of tr.children) {
				if (
					recursiveCheckSearchAndSelected(ch, searchString) ||
					isItemInSelected(ch.id)
				) {
					result = true
					condition = false
				}
			}
			condition = false
		}
		return result
	}

	const isRender = useMemo(() => {
		if (searchString === '') return true
		if (tree.title.toLowerCase().includes(searchString.toLowerCase()))
			return true
		return recursiveCheckSearchAndSelected(tree, searchString)
	}, [searchString, tree])
	const isHalfChecked = isItemHalfChecked(tree)

	if (!tree || !isRender) return null

	if (tree.children.length === 0)
		return (
			<div
				className={'d-flex align-items-center'}
				onClick={() => handleNodeToggle(tree)}
			>
				<i
					className={'p-1'}
					style={{ height: 'fit-content', cursor: 'pointer' }}
				>
					{isHalfChecked ? (
						<HalfCheckIcon />
					) : isItemInSelected(tree.id) ? (
						<CheckIcon />
					) : (
						<UnCheckIcon />
					)}
				</i>
				<span className={'ms-2'} style={{ cursor: 'pointer' }}>
					{' '}
					{tree.title}
				</span>
			</div>
		)
	return (
		<>
			<div className={'d-flex align-items-center'}>
				<i
					onClick={() => setShowTree((p) => !p)}
					style={{ cursor: 'pointer', height: 'fit-content' }}
					className={'p-1'}
				>
					{showTree ? (
						<svg
							width="12"
							height="7"
							viewBox="0 0 12 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M5.52873 0.86201L0.862061 5.52868L1.80487 6.47149L6.00013 2.27622L10.1954 6.47149L11.1382 5.52868L6.47154 0.86201C6.21119 0.601661 5.78908 0.601661 5.52873 0.86201Z"
								fill="#6C757D"
							/>
						</svg>
					) : (
						<svg
							width="12"
							height="6"
							viewBox="0 0 12 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M5.52873 5.60948L0.862061 0.942809L1.80487 0L6.00013 4.19526L10.1954 0L11.1382 0.942809L6.47154 5.60948C6.21119 5.86983 5.78908 5.86983 5.52873 5.60948Z"
								fill="#6C757D"
							/>
						</svg>
					)}
				</i>
				<i
					className={'p-1'}
					style={{ height: 'fit-content', cursor: 'pointer' }}
					onClick={() => handleNodeToggle(tree)}
				>
					{isHalfChecked ? (
						<HalfCheckIcon />
					) : isItemInSelected(tree.id) ? (
						<CheckIcon />
					) : (
						<UnCheckIcon />
					)}
				</i>
				<span
					className={'ms-2'}
					onClick={() => handleNodeToggle(tree)}
					style={{ cursor: 'pointer' }}
				>
					{tree.title}
				</span>
			</div>
			{showTree && (
				<ul>
					{tree.children.map((tr) => (
						<RegionsTree
							tree={tr}
							key={tr.id}
							onChange={onChange}
							isItemInSelected={isItemInSelected}
							searchString={searchString}
							handleNodeToggle={handleNodeToggle}
						/>
					))}
				</ul>
			)}
		</>
	)
}

const CheckIcon = () => (
	<svg
		width="13"
		height="14"
		viewBox="0 0 13 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.5"
			y="1"
			width="12"
			height="12"
			rx="1.5"
			fill="#007BFF"
			stroke="#007BFF"
		/>
		<path d="M3 7.5L5 9.5L10.5 3.5" stroke="white" strokeWidth="2" />
	</svg>
)

const UnCheckIcon = () => (
	<svg
		width="13"
		height="14"
		viewBox="0 0 13 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.5"
			y="1"
			width="12"
			height="12"
			rx="1.5"
			fill="white"
			stroke="#CED4DA"
		/>
	</svg>
)

const HalfCheckIcon = () => (
	<svg
		width="13"
		height="14"
		viewBox="0 0 13 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.5"
			y="1"
			width="12"
			height="12"
			rx="1.5"
			fill="#007BFF"
			stroke="#007BFF"
		/>
		<path d="M2.5 7H10.5" stroke="white" strokeWidth="2" />
	</svg>
)

const getAllChildrenIds = (node: RegionWithChildren): number[] => {
	if (node.children.length === 0) return []
	return [
		node.children.map(({ id }) => id),
		node.children.map(getAllChildrenIds).flat(),
	].flat()
}

function withParentInChilds(item): RegionWithChildren {
	if (!item.children)
		return {
			...item,
			children: [],
		}
	return {
		...item,
		parent_id: item.parent_id || null,
		children: item.children.map((child) =>
			withParentInChilds({
				...child,
				parent_id: item.id,
			})
		),
	}
}

function getTreeItem(node, id): RegionWithChildren | null {
	if (node.id === id) return node
	for (let child of node.children) {
		const childResult = getTreeItem(child, id)
		if (childResult) return childResult
	}
	return null
}

export default RegionSelector
