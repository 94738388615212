const titles = [
	'перейти',
	'в магазин',
	'вступить',
	'выбрать',
	'забронировать',
	'загрузить',
	'заказать',
	'записаться',
	'зрегистрироваться',
	'играть',
	'купить',
	'купить билет',
	'меню',
	'начать',
	'открыть',
	'подать заявку',
	'подробнее',
	'позвонить',
	'получить',
	'попробовать',
	'связаться',
	'слушать',
	'смотреть',
	'создать',
	'узнать',
	'пройти опрос',
	'начать тест',
]
const values = [
	'visitSite',
	'to_shop',
	'signUp',
	'choose',
	'book',
	'download',
	'order',
	'enroll',
	'register',
	'playGame',
	'buy',
	'buy_ticket',
	'see_menu',
	'begin',
	'open',
	'apply',
	'learnMore',
	'call',
	'get',
	'try',
	'contactUs',
	'listen',
	'watch',
	'create',
	'learn',
	'takeSurvey',
	'startTest',
]

type VkButtonTextMapType<K, V> = Map<K, V> & {
	generateArray: () => { label: string; value: string }[]
}

const VkButtonTextMap: VkButtonTextMapType<string, string> = new Map<
	string,
	string
>(titles.map((title, index) => [values[index], title])) as VkButtonTextMapType<
	string,
	string
>
VkButtonTextMap.generateArray = function () {
	const tmp: any = []
	this.forEach((label, value) => tmp.push({ value: value, label: label }))
	return tmp
}

export default VkButtonTextMap
