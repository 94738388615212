import React, { useEffect } from 'react'
import { SelectedIcon } from '../AdFormatSelector'
import s from '../InternetAD/InternetSelector.module.scss'
import useCampaignData from '../../../../../stores/campaignData'
import { ReactComponent as AdStream_logo } from './icons/adstream.svg'
import { ReactComponent as AdStream_logo_click } from './icons/adstreamSelected.svg'
import { ReactComponent as Teletarget_logo } from './icons/teletarget.svg'
import { ReactComponent as Teletarget_logo_click } from './icons/teletargetSelected.svg'

export const TVIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5 18a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-6v2h3v2H8v-2h3v-2H5Zm-1-3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v10Zm6.573-9.32A1 1 0 0 0 9 6.5v7a1 1 0 0 0 1.573.82l5-3.5a1 1 0 0 0 0-1.64l-5-3.5ZM13.256 10 11 11.58V8.42L13.256 10Z"
			clipRule="evenodd"
		/>
	</svg>
)

export const VideoIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path fill="currentColor" d="M13 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M7 6h.17A3.001 3.001 0 0 0 10 8h2a3 3 0 1 0 0-6h-2a3.001 3.001 0 0 0-2.83 2H7a3 3 0 0 0-3 3v3H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-.95l2.41 1.758A1 1 0 0 0 22 21V11a1 1 0 0 0-1.59-.808L18 11.95V11a1 1 0 0 0-1-1H6V7a1 1 0 0 1 1-1Zm3-2a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2h-2Zm6 8H4v8h12v-8Zm2 5.575 2 1.458v-6.066l-2 1.458v3.15Z"
			clipRule="evenodd"
		/>
	</svg>
)

export const BannerIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M2 5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5Zm2 1v12h8V6H4Z"
			clipRule="evenodd"
		/>
		<path fill="currentColor" d="M20 7h2v10h-2V7Zm-2-2h-2v14h2V5Z" />
	</svg>
)

const TvSelector = ({
	formats,
	setFormats,
	onError,
	addToStack,
	removeFromStack,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	function clickHandle(key) {
		const tmp = formats?.find((el) => el.key === key)
		tmp.isSelected = !tmp.isSelected
		setFormats((p) => p.map((el) => (el.key === key ? tmp : el)))
		tmp.isSelected
			? addToStack({ Component: tmp.Component, key: key }, 'tv')
			: removeFromStack(key)
		patchCampaignToNewValue(key, tmp.isSelected)
	}
	async function patchCampaignToNewValue(campaignName, value) {
		if (campaignName === 'banner') {
			await campaignInterface.saveTeletargetFilters(campaign?.id, {
				is_selected: !!value,
			})
			if (!value) onError({})
		}
		if (campaignName === 'video') {
			await campaignInterface.saveVideoFilters(campaign?.id, {
				is_selected: !!value,
			})
			if (!value) onError({})
		}
	}

	return (
		<>
			<div
				className={'d-flex justify-content-between'}
				style={{ marginBottom: '8px' }}
			>
				<h4 className={'mb-0'}>ТВ-реклама</h4>
				<div>
					<TVIcon />
				</div>
			</div>
			<div style={{ marginBottom: '24px' }}>
				Выберите способы показа рекламы. Реклама будет транслироваться
				на площадках{' '}
				<a
					href="https://adstream.ru/"
					target={'_blank'}
					rel={'noreferrer'}
				>
					Adstream
				</a>{' '}
				и{' '}
				<a
					href="https://teletarget.ru/"
					target={'_blank'}
					rel={'noreferrer'}
				>
					Teletarget
				</a>
				.
			</div>
			<div className={s.container}>
				{formats.map((format) => (
					<div
						key={format.key}
						className={
							(format?.disabled
								? s.formatDisabled
								: format.isSelected
								? s.formatSelected
								: s.format) + ' noselect'
						}
						onClick={() => {
							clickHandle(format.key)
						}}
					>
						<div className={s.icon}>
							{format?.isSelected && <SelectedIcon />}
							{!format?.isSelected &&
								(format.key === 'video' ? (
									<VideoIcon />
								) : (
									<BannerIcon />
								))}
						</div>
						<div className={s.title}>
							<div>{format.title}</div>
							<div>
								{format.key === 'video' &&
									((format.isSelected && (
										<AdStream_logo_click />
									)) ||
										(!format.isSelected && (
											<AdStream_logo />
										)))}
								{format.key === 'banner' &&
									((format.isSelected && (
										<Teletarget_logo_click />
									)) ||
										(!format.isSelected && (
											<Teletarget_logo />
										)))}
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default TvSelector
