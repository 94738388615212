import produce from 'immer'
import createStoreHook from '../utils/createStoreHook'
import { createStoreon } from 'storeon'
import makeStoreResettable from '../utils/makeStoreResettable'
import { storeonDevtools } from 'storeon/devtools'
import { authFetch } from '../utils/authFetch'
import {
	UserNotifications,
	UserReadNotifications,
	UserUnReadNotifications,
} from '../constants/notification_api'
import WsController from './wsController'

type NotificationStoreState = {
	data: any
	ready: boolean
	count: number
	next: string
	lastOffset: number
	notifications_count_not_read: number
	alerts: any[]
}

const DEFAULT_NOTIF_AMOUNT = 6

export const notificationDataStore = createStoreon<NotificationStoreState, any>(
	[
		(store) => {
			makeStoreResettable(store, {
				data: [],
				ready: false,
				count: null,
				next: null,
				lastOffset: null,
				notifications_count_not_read: null,
				alerts: [],
			})

			store.on(
				'receive',
				produce((state, data) => {
					const { results, count, next, lastOffset } = data
					state.data = results
					state.count = count
					state.next = next
					state.lastOffset = lastOffset
					state.ready = true
				})
			)
			store.on(
				'addData',
				produce((state, data) => {
					const { results, count, next, lastOffset } = data
					//проверить, если хоть один id из новых есть в текущих - выход;
					state.data = [...state.data, ...results]
					state.count = count
					state.next = next
					state.lastOffset = lastOffset
					state.ready = true
				})
			)
			store.on(
				'newAlert',
				produce((state, alert) => {
					state.alerts = [alert, ...state.alerts]
					//если в глобальном списке нет - добавляем;
					if (
						!state.data.some((current) => current.id === alert.id)
					) {
						notificationDataStoreInterface.fetchNotifications()
					}
				})
			)
			store.on(
				'removeAlert',
				produce((state, remove_alertID) => {
					state.alerts = state.alerts.filter(
						(alert) => alert.id !== remove_alertID
					)
				})
			)
			store.on(
				'logout',
				produce((state, data) => {
					state.data = null
					state.count = null
					state.next = null
					state.lastOffset = null
					state.notifications_count_not_read = null

					state.ready = true
				})
			)
			store.on(
				'notifications_count_not_read',
				produce((state, data) => {
					state.notifications_count_not_read = parseInt(data.counts)
				})
			)
			store.on(
				'read_all_notifications',
				produce((state, data) => {
					state.data = state.data.map((notification) => ({
						...notification,
						is_readed: true,
					}))
				})
			)
			store.on(
				'read_notification',
				produce((state, data) => {
					state.data = state.data.map((notification) => {
						if (data.id === notification.id) {
							notification.is_readed = true
						}
						return notification
					})
				})
			)
			store.on(
				'unread_notification',
				produce((state, data) => {
					state.data = state.data.map((notification) => {
						if (data.id === notification.id) {
							notification.is_readed = false
						}
						return notification
					})
				})
			)
		},
		process.env.NODE_ENV !== 'production' &&
			storeonDevtools({ name: 'admarket-notificationsData' }),
	]
)

export const notificationDataStoreInterface = {
	name: 'NotificationData',

	get() {
		return notificationDataStore.get().data
	},
	getLastOffset() {
		return notificationDataStore.get().lastOffset
	},
	getCount() {
		return notificationDataStore.get().count
	},
	getNotificationsCountNotRead() {
		return notificationDataStore.get().notifications_count_not_read
	},
	getAlerts() {
		return notificationDataStore.get().alerts
	},

	subscribe(callback) {
		return notificationDataStore.on('@changed', callback)
	},
	subscribeOnAlerts(callback) {
		notificationDataStore.on('newAlert', callback)
		notificationDataStore.on('removeAlert', callback)
	},

	checkDataIsReady() {
		return notificationDataStore.get().ready
	},

	async fetch() {
		await notificationDataStoreInterface.fetchNotifications()
		await notificationDataStoreInterface.initWS()
	},

	async fetchNotifications(offset = 0) {
		const res = []
		const withOffset = Object.assign(res, { lastOffset: offset })
		await notificationDataStore.dispatch('receive', withOffset)
	},

	async loadNextNotifications() {
		const currentOffset = notificationDataStoreInterface.getLastOffset()
		const res = await authFetch({
			url: UserNotifications.params(
				DEFAULT_NOTIF_AMOUNT,
				currentOffset + DEFAULT_NOTIF_AMOUNT
			),
			method: 'GET',
		})
		const withOffset = Object.assign(res, {
			lastOffset: currentOffset + DEFAULT_NOTIF_AMOUNT,
		})
		await notificationDataStore.dispatch('addData', withOffset)
	},

	async changeNotificationCountsNotRead(counts) {
		await notificationDataStore.dispatch('notifications_count_not_read', {
			counts: counts,
		})
	},

	async initWS() {
		const ws = new WsController()
	},

	async removeAlert(id) {
		await notificationDataStore.dispatch('removeAlert', id)
	},
	async readNotification(id) {
		await authFetch({
			url: UserReadNotifications,
			method: 'PUT',
			body: {
				uids: [id],
			},
		})
		await notificationDataStore.dispatch('read_notification', { id })
	},
	async unReadNotification(id) {
		await authFetch({
			url: UserUnReadNotifications,
			method: 'PUT',
			body: {
				uids: [id],
			},
		})
		await notificationDataStore.dispatch('unread_notification', { id })
	},
	async readAllNotifications() {
		await authFetch({
			url: UserReadNotifications,
			method: 'PUT',
			body: {
				read_all: true,
			},
		})
		await notificationDataStore.dispatch('read_all_notifications')
	},
}

const useNotificationData = createStoreHook(notificationDataStoreInterface)

export default useNotificationData
