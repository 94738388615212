// import { useEffect, useState } from "react";
// import { useEventListeners } from "./useEventListeners";
//
// const useResponsive = () => {
//   const [width, setWidth] = useState(document.documentElement.clientWidth);
//   const { addEventListener, cleanupListeners } = useEventListeners();
//   useEffect(() => {
//     addEventListener(window, "resize", () => {
//       setWidth(document.documentElement.clientWidth);
//     });
//     return cleanupListeners;
//   }, []);
//   return {
//     isMobile: width <= 600,
//     isTablet: width <= 1132,
//     isMobileMap: width <= 820,
//     isDesktop: width > 1132,
//   };
// };
//

import { useLayoutEffect, useState } from 'react'

const queries = [
	'(max-width: 600px)',
	'(min-width: 601px) and (max-width: 1132px)',
	'(max-width: 820px)',
	'(min-width: 1132px)',
]

interface useResponsiveType {
	isMobile: boolean
	isTablet: boolean
	isMobileMap: boolean
	isDesktop: boolean
}

const useResponsive = (): useResponsiveType => {
	const mediaQueryLists = queries.map((query) => matchMedia(query))

	const getValues = () => mediaQueryLists.map((list) => list.matches)

	const [values, setValues] = useState(getValues)
	useLayoutEffect(() => {
		const handler = () => setValues(getValues)

		mediaQueryLists.forEach((list) => {
			try {
				// Chrome & Firefox
				list.addEventListener('change', handler)
			} catch (e1) {
				try {
					// Safari
					list.addListener(handler)
				} catch (e2) {
					console.error(e2)
				}
			}
		})

		return () =>
			mediaQueryLists.forEach((list) => {
				try {
					// Chrome & Firefox
					list.removeEventListener('change', handler)
				} catch (e1) {
					try {
						// Safari
						list.removeListener(handler)
					} catch (e2) {
						console.error(e2)
					}
				}
			})
	})

	return {
		isDesktop: values[3],
		isMobile: values[0],
		isMobileMap: values[2],
		isTablet: values[1],
	}
}
export default useResponsive
