import React, { useRef, useState } from 'react'
import bem from '../../utils/bem'
import '../../styles/page.scss'
import useResponsive from '../../utils/useResponsive'
import Header from './Header/Header'

const cn = bem('page')

export function withPage(pageProps: any = null) {
	return function wrapWithPage(Component) {
		const WithPage = function WithPage(props) {
			return (
				<Page {...pageProps}>
					<Component {...props} />
				</Page>
			)
		}

		WithPage.displayName = `Page(${
			Component.displayName || Component.name
		})`
		return WithPage
	}
}

function Page({ children, noSidebar = true, isProvider = false }) {
	const { isTablet } = useResponsive()
	const [sidebarForceOpen, setSidebarForceOpen] = useState(
		!noSidebar &&
			(['open', null].includes(localStorage?.getItem('sidebar'))
				? !isTablet
				: false)
	)

	const sidebarRef = useRef(null)
	function handlePageClick(e) {
		//закрытие сайдбара, пока не нужно
		return false
		// if (
		//   sidebarForceOpen &&
		//   !e.nativeEvent.composedPath().includes(sidebarRef.current)
		// ) {
		//   setSidebarForceOpen(false);
		// }
	}
	return (
		<div
			className={cn(null, {
				sideBarOpen: sidebarForceOpen && !isTablet,
				wide: noSidebar,
			})}
			onClick={handlePageClick}
		>
			<Header
				onSidebarOpen={() => setSidebarForceOpen((p) => !p)}
				noSidebar={noSidebar}
				sidebarForceOpen={sidebarForceOpen}
			/>
			<div className={cn('body', { provider: isProvider })}>
				{children}
			</div>
		</div>
	)
}
