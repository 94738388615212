import React from 'react'
import bem from '../../utils/bem'
import { Spinner } from 'react-bootstrap'
import '../../styles/fallback.scss'

const cn = bem('fallback')

export default function Fallback() {
	return (
		<div className={cn()}>
			<Spinner animation={'border'} variant={'primary'} />
		</div>
	)
}
