export const InternetTooltips = {
	YANDEX: {
		heading:
			'В соответсвии с требованиями Яндекс укажите ваш продукт или услугу.',
		text: 'В соответсвии с требованиями Яндекс укажите описание вашего товара или услуги.',
	},
	VK: {
		heading:
			'В соответсвии с требованиями VK укажите ваш продукт или услугу.',
		text: 'В соответсвии с требованиями VK укажите описание вашего товара или услуги.',
		strategy:
			'Стратегия, при которой вы указываете свой бюджет и система стремится обеспечить наилучшие результаты с учётом выбранного бюджета.',
		button_text:
			'Выберете наиболее подходящий призыв к действию для вашего рекламного объявления',
	},
}
