import React from 'react'
import s from './ADFormatSellector.module.scss'
import { Card } from 'react-bootstrap'
import useCampaignData from '../../../../stores/campaignData'
import {ReactComponent as InternetIcon } from './internet.svg'
import {ReactComponent as BillboardIcon} from '../../../../assets/images/billboard_2.svg'

export const SelectedIcon = () => (
	<svg
		width="18"
		height="14"
		viewBox="0 0 18 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.4571 2.3107L6.95706 12.8107C6.56654 13.2012 5.93337 13.2012 5.54285 12.8107L0.542847 7.8107L1.95706 6.39648L6.24995 10.6894L16.0428 0.896484L17.4571 2.3107Z"
		/>
	</svg>
)

export const AdIcon = ({
	name,
	withCurrentColor = false,
}: {
	name: 'indoor' | 'outdoor' | 'internet' | 'tv' | 'transport'
	withCurrentColor?: boolean
}): JSX.Element => {
	switch (name) {
		case 'internet' :
			return (
				<InternetIcon/>

			)
		case 'transport':
			return (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg"><path d="M6.55787 2.90741L12 9.43796L17.4421 2.90741L21.7543 18H23V20H15V18H15.656L15.0618 16.0194L12 19.5186L8.93821 16.0194L8.34403 18H9V20H1V18H2.2457L6.55787 2.90741ZM4.32573 18H6.25597L8.06179 11.9806L12 16.4814L15.9382 11.9806L17.744 18H19.6743L16.5579 7.09262L12 12.5621L7.44213 7.09262L4.32573 18Z" fill="inherit"></path></svg>
			)
		case 'outdoor':
			return (
				<BillboardIcon/>
			)
		case 'tv':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						fillRule="evenodd"
						d="M5 18a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-6v2h3v2H8v-2h3v-2H5Zm-1-3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v10Zm6.573-9.32A1 1 0 0 0 9 6.5v7a1 1 0 0 0 1.573.82l5-3.5a1 1 0 0 0 0-1.64l-5-3.5ZM13.256 10 11 11.58V8.42L13.256 10Z"
						clipRule="evenodd"
						fill={withCurrentColor ? 'currentColor' : '#212529'}
					/>
				</svg>
			)
		case 'indoor':
			return (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8 16V5H16V16H13H11H8ZM11 18H7C6.44772 18 6 17.5523 6 17V4C6 3.44772 6.44772 3 7 3H17C17.5523 3 18 3.44772 18 4V17C18 17.5523 17.5523 18 17 18H13V21H11V18Z"
						fill={withCurrentColor ? 'currentColor' : '#212529'}
					/>
				</svg>
			)

		default:
			return <></>
	}
}

const AdFormatSelector = ({
	formats,
	setFormats,
	cn,
	addToStack,
	removeFromStack,
}) => {
	const [campaign, campaignInterface] = useCampaignData()

	function clickHandle(key) {
		const tmp = formats?.find((el) => el.key === key)
		tmp.isSelected = !tmp.isSelected
		tmp?.data?.forEach((val) => {
			val.isSelected = false
		})
		tmp.isSelected
			? addToStack({ Component: tmp.Component, key: key })
			: removeFromStack(key)
		if (!tmp.isSelected && key === 'internet') {
			removeFromStack(['vk', 'yandex'])
		}
		// if (!tmp.isSelected && key === 'tv') {
		// 	removeFromStack(['video', 'banner'])
		// }
		setFormats((p) => p.map((el) => (el.key === key ? tmp : el)))

		ActionByADType(key, tmp.isSelected).then()
	}

	async function ActionByADType(key, isSelected) {
		if (key === 'outdoor') {
			await campaignInterface.patchCampaign({
				outdoor_adv_is_selected: isSelected,
			})
		}
		if (key === 'indoor') {
			await campaignInterface.patchCampaign({
				indoor_adv_is_selected: isSelected,
			})
		}
	}
	return (
		<>
			<Card className={cn('form')} style={{ zIndex: 12 }}>
				<h2 className={cn('subtitle')} style={{ marginBottom: '8px' }}>
					Форматы рекламы
				</h2>
				<div
					className={cn('separator')}
					style={{ margin: 0, padding: 0, marginBottom: '24px' }}
				/>
				<div style={{ position: 'relative', width: '100%' }}>
					<div style={{ marginBottom: '16px' }}>
						Выберите один или несколько форматов рекламы
					</div>
					<div className={s.container}>
						{formats.map((format) => (
							<div
								className={
									format?.isSelected
										? s.formatSelected
										: s.format
								}
								key={format.key}
								onClick={(e) => {
									e.preventDefault()
									clickHandle(format.key)
								}}
							>
								<div className={s.icon}>
									{format.isSelected && <SelectedIcon />}
									{!format.isSelected && (
										<AdIcon name={format.key} />
									)}
								</div>
								<span className={s.title + ' noselect'}>
									{format.title}
								</span>
							</div>
						))}
					</div>
				</div>
			</Card>
		</>
	)
}

export default AdFormatSelector
