import useCampaignData from '../../../../../stores/campaignData'
import { useMemo } from 'react'
import { Bid_Keys } from '../../../../../stores/ADMarketTypes.types'

export type bidInfo = { type: Bid_Keys; id: number }

type ReturnType = {
	isHaveBidRequest: (bidInfo: bidInfo) => boolean
}

const useBidRequest = (): ReturnType => {
	const [campaign] = useCampaignData()

	const map = useMemo<Map<string, number[]>>(() => {
		// @ts-ignore
		return new Map(Object.entries(campaign.bid_requests))
	}, [campaign.bid_requests])
	return {
		isHaveBidRequest: (bidInfo) => {
			if (!map.has(bidInfo.type)) return false
			return map.get(bidInfo.type)?.includes(bidInfo.id) || false
		},
	}
}

export default useBidRequest
