import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import AdMarketSelect from 'src/components/UtilityComponents/ADMarketSelect/AdMarketSelect'
import { SUBJECTS_DATA } from 'src/mock'

interface SubjectSelectorProps {
	value: string
	setFieldValue: (name: string, value: any) => null
	setFieldError: (name: string, error: any) => null
	error: string
}

const SubjectSelector: FC<SubjectSelectorProps> = ({
	value,
	setFieldValue,
	setFieldError,
}) => {

	const subjects = SUBJECTS_DATA


	return (
		<Form.Group className={'mt-4'}>
			<Form.Label>Тематика рекламы</Form.Label>
			<div>
                <AdMarketSelect
					isDisabled={true}
					value={subjects.find((item) => item.value === value) || null}
					options={subjects}
					onChange={(newValue) => {
						setFieldError('subject', undefined)
						setFieldValue(
							'subject',
							newValue?.value || ''
						)
					}}
				/>
			</div>
		</Form.Group>
	)
}

export default SubjectSelector
