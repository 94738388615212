import { withParams } from './api'

const BASE_NOTIFICATION_URL = '/notificator/api/v1'

export const UserNotifications = withParams(
	(limit, offset) =>
		`${BASE_NOTIFICATION_URL}/usernotifies/?limit=${limit}&offset=${offset}`
)

export const UserReadNotifications = `${BASE_NOTIFICATION_URL}/usernotifies/read/`
export const UserUnReadNotifications = `${BASE_NOTIFICATION_URL}/usernotifies/unread/`
