export const AdStreamMediaRequirements = {
	video: [
		{ name: 'Формат:', value: 'MP4' },
		{ name: 'Кодек:', value: 'H.264 / HP / 4:2:0' },
		{ name: 'Стандарт разложения видео:', value: '1080p' },
		{ name: 'Разрешение:', value: '1280 × 720 px' },
		{ name: 'Соотношение сторон:', value: '16:9' },
		{ name: 'Частота кадров:', value: 'FPS 25' },
		{ name: 'Длительность:', value: '5 секунд' },
		{ name: 'Битрейт:', value: '5 Мбит/с' },
	],
	audio: [
		{ name: 'Кодек:', value: 'HE-AAC v1' },
		{ name: 'Формат сигнала:', value: 'Embedded in video' },
		{ name: 'Битрейт:', value: '96 Кбит/с' },
		{ name: 'Частота дискретизации:', value: '48 кГц' },
		{ name: 'Режим кодирования:', value: 'Stereo (2/0)' },
		{ name: 'Уровень LKFS:', value: '-23 ± 0.5' },
	],
}

export const TeletargetMediaRequirements = {}
