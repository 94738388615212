import React, { useEffect, useMemo, useState } from 'react'
import { MediaType } from './MediaInfo'
import '../../../styles/modalMediaformat.scss'
import bem from '../../../utils/bem'
import { Formik, useField } from 'formik'
import {
	Button,
	ButtonGroup,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
} from 'react-bootstrap'
import ButtonGroupBooleanSwitch from '../../UtilityComponents/ButtonGroupBooleanSwitch'

import { ConnectedFocusError } from 'focus-formik-error'
import useProviderData from '../../../stores/providerData'

const cn = bem('modal-media')
export const MEDIA_FORMATS = {
	image: ['PNG', 'JPEG', 'PDF'],
	video: ['MPEG4', 'MP4', 'MOV', 'AVI'],
	colorModel: ['RGB', 'HSL'],
	codec: ['Xvid', 'H264'],
}
type ModalMediaFormatProps = {
	show: boolean
	handleClose: React.MouseEventHandler<HTMLButtonElement>
	media?: MediaType
	setEdited?: any
}
const ModalMediaFormat: React.FC<ModalMediaFormatProps> = ({
	show,
	handleClose,
	media,
	setEdited,
}) => {
	const [startEditing, setstartEditing] = useState(false)
	const [submitDisabled, setSubmitDisabled] = useState(false)
	const [, ProviderInterface] = useProviderData()
	const Init = useMemo(() => {
		if (media) {
			let tmp = { ...media }
			MEDIA_FORMATS.image.forEach((el) => {
				tmp[el] = !!media.image_formats.find(
					(format) => format.toLowerCase() === el.toLowerCase()
				)
			})
			MEDIA_FORMATS.video.forEach((el) => {
				tmp[el] = !!media.video_formats.find(
					(format) => format.toLowerCase() === el.toLowerCase()
				)
			})
			// @ts-ignore
			delete tmp['state']
			// @ts-ignore
			delete tmp['in_archive']
			return tmp
		} else {
			let tmp = {
				additional_info: '',
				name: 'Медиаформат',
				is_image: true,
				is_video: true,
				video_formats: [],
				image_formats: [],
				min_height: '',
				max_height: '',
				min_width: '',
				max_width: '',
				aspect_ratio: '',
				color_model: '',
				codec: '',
				min_fps: '',
				max_fps: '',
				min_bitrate: '',
				max_bitrate: '',
				duration: '',
				with_audio_track: false,
				min_dpi: '',
				max_dpi: '',
				external_link: '',
				video_max_size: '',
				image_max_size: '',
			}
			MEDIA_FORMATS.image.forEach((el, i) => {
				tmp[el] = i <= 1
			})
			MEDIA_FORMATS.video.forEach((el, i) => {
				tmp[el] = i <= 1
			})
			return tmp
		}
	}, [media])
	async function handleSubmit(values) {
		setSubmitDisabled(true)
		let data = { ...values }
		data.image_formats = []
		data.video_formats = []
		delete data.id
		//Заполняем image formats и чистим ненужные данные
		MEDIA_FORMATS.image.forEach((el) => {
			if (data[el]) data.image_formats.push(el.toLowerCase())
			return delete data[el]
		})
		//заполняем video formats и чистим ненужные данные
		MEDIA_FORMATS.video.forEach((el) => {
			if (data[el]) data.video_formats.push(el.toLowerCase())
			return delete data[el]
		})
		//Обработка специфичных полей изображения
		if (!data.is_image) {
			;['color_model', 'min_dpi', 'max_dpi', 'image_max_size'].forEach(
				(el) => {
					return delete data[el]
				}
			)
		} else {
			data['color_model'] = data['color_model'].toLowerCase()
			data['min_dpi'] = ConvertToNumberOrNull(data['min_dpi'])
			data['max_dpi'] = ConvertToNumberOrNull(data['max_dpi'])
			data['image_max_size'] = ConvertToNumberOrNull(
				data['image_max_size']
			)
		}
		//Обработка специфичных полей видео
		if (!data.is_video) {
			;[
				'codec',
				'min_fps',
				'max_fps',
				'min_bitrate',
				'max_bitrate',
				'with_audio_track',
				'video_max_size',
			].forEach((el) => {
				return delete data[el]
			})
		} else {
			data['codec'] = data['codec'].toLowerCase()
			data['min_fps'] = ConvertToNumberOrNull(data['min_fps'])
			data['max_fps'] = ConvertToNumberOrNull(data['max_fps'])
			data['min_bitrate'] = ConvertToNumberOrNull(data['min_bitrate'])
			data['max_bitrate'] = ConvertToNumberOrNull(data['max_bitrate'])
			data['video_max_size'] = ConvertToNumberOrNull(
				data['video_max_size']
			)
		}
		//Обработка общих полей
		;[
			'min_height',
			'max_height',
			'min_width',
			'max_width',
			'duration',
		].forEach((el) => {
			data[el] = ConvertToNumberOrNull(data[el])
		})
		if (media) {
			await ProviderInterface.PatchMedia(values.id, data)
			// @ts-ignore
			handleClose()
		} else {
			await ProviderInterface.CreateMedia(data)
			// @ts-ignore
			handleClose()
		}
		setSubmitDisabled(false)
	}
	function handleValidate(values) {
		setstartEditing(true)
		const errors: any = {}
		// Object.entries(values).forEach(([key, value]) => {
		//   if (value === "") {
		//     errors[key] = "Обязательное поле";
		//   }
		// });
		if (values.name === '') {
			errors.name = 'Обязательное поле'
		}
		if (!values.is_video && !values.is_image) {
			errors.notype = 'Необходимо выбрать хотя бы один тип'
		}
		if (values.is_image) {
			let flag = false
			MEDIA_FORMATS.image.forEach((el) => {
				if (values[el]) {
					flag = true
				}
			})
			if (!flag)
				errors['image_formats'] =
					'Необходимо выбрать хотя бы один формат для изображения'
		}
		if (values.is_video) {
			let flag = false
			MEDIA_FORMATS.video.forEach((el) => {
				if (values[el]) {
					flag = true
				}
			})
			if (!flag)
				errors['video_formats'] =
					'Необходимо выбрать хотя бы один формат для видео'
		}
		if (values.external_link !== '') {
			if (!values.external_link?.startsWith('https://')) {
				errors.external_link = 'Ссылка должна начинаться с https://'
			} else {
				if (!validateUrl(values.external_link))
					errors.external_link = 'Введите правильный URL'
			}
		}
		// console.log(JSON.stringify(values, null, 4));
		return errors
	}
	// @ts-ignore
	useEffect(() => {
		return () => setEdited('')
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop={startEditing ? 'static' : true}
				keyboard={!startEditing}
				dialogClassName={cn()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{!media
							? 'Создание медиа-формата'
							: 'Редактирование медиа-формата'}
					</Modal.Title>
				</Modal.Header>
				<Formik
					initialValues={Init}
					validate={handleValidate}
					onSubmit={handleSubmit}
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnMount={false}
				>
					{({ setFieldValue, values, handleSubmit, errors }: any) => (
						<Form>
							<ConnectedFocusError />
							<Modal.Body>
								<Col>
									<Form.Label>Название</Form.Label>
									<InputGroup hasValidation>
										<Form.Control
											type={'text'}
											value={values.name}
											onChange={(e) =>
												setFieldValue(
													'name',
													e.target.value
												)
											}
											isInvalid={!!errors.name}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.name}
										</Form.Control.Feedback>
									</InputGroup>
									<Form.Label>Тип</Form.Label>
									<InputGroup
										hasValidation
										style={{ flexDirection: 'column' }}
									>
										<CustomCheckBox
											label={'Изображение'}
											name={'is_image'}
											checked={values.is_image}
										/>
										<CustomCheckBox
											label={'Видео'}
											name={'is_video'}
											checked={values.is_video}
										/>
										{errors.notype && (
											<div
												className={'invalid-feedback'}
												style={{ display: 'block' }}
											>
												{errors.notype}
											</div>
										)}
									</InputGroup>
									<Col>
										<Row className={'w-100'}>
											<Form.Label
												style={{ marginLeft: '12px' }}
											>
												Формат
											</Form.Label>
											<Col>
												{MEDIA_FORMATS.image.map(
													(e, i) => (
														<CustomCheckBox
															label={e}
															name={e}
															checked={values[e]}
															className={
																'small-check'
															}
															disabled={
																!values.is_image
															}
															key={e}
														/>
													)
												)}
											</Col>
											<Col>
												{MEDIA_FORMATS.video.map(
													(e, i) => (
														<CustomCheckBox
															key={i}
															label={e}
															name={e}
															checked={values[e]}
															className={
																'small-check'
															}
															disabled={
																!values.is_video
															}
														/>
													)
												)}
											</Col>
										</Row>
									</Col>
									{(errors.video_formats ||
										errors.image_formats) && (
										<Row
											className={'w-100'}
											style={{ marginTop: 0 }}
										>
											<Col>
												{values.is_image &&
													errors.image_formats && (
														<div
															className={
																'invalid-feedback'
															}
															style={{
																display:
																	'block',
															}}
														>
															{
																errors.image_formats
															}
														</div>
													)}
											</Col>
											<Col>
												{values.is_video &&
													errors.video_formats && (
														<div
															className={
																'invalid-feedback'
															}
															style={{
																display:
																	'block',
															}}
														>
															{
																errors.video_formats
															}
														</div>
													)}
											</Col>
										</Row>
									)}
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Мин. ширина (px)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.min_width}
													onChange={(e) =>
														setFieldValue(
															'min_width',
															e.target.value
														)
													}
													isInvalid={
														!!errors.min_width
													}
													placeholder={'0 px'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.min_width}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col>
											<Form.Label>
												Макс. ширина (px)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.max_width}
													onChange={(e) =>
														setFieldValue(
															'max_width',
															e.target.value
														)
													}
													isInvalid={
														!!errors.max_width
													}
													placeholder={'0 px'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.max_width}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Row>
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Мин. высота (px)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.min_height}
													onChange={(e) =>
														setFieldValue(
															'min_height',
															e.target.value
														)
													}
													isInvalid={
														!!errors.min_height
													}
													placeholder={'0 px'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.min_height}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col>
											<Form.Label>
												Макс. высота (px)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.max_height}
													onChange={(e) =>
														setFieldValue(
															'max_height',
															e.target.value
														)
													}
													isInvalid={
														!!errors.max_height
													}
													placeholder={'0 px'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.max_height}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Row>

									<Row className={'w-100'}>
										<Col>
											<Form.Label>Мин. DPI</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.min_dpi}
													onChange={(e) =>
														setFieldValue(
															'min_dpi',
															e.target.value
														)
													}
													isInvalid={!!errors.min_dpi}
													disabled={!values.is_image}
													placeholder={'0 dpi'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.min_dpi}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col>
											<Form.Label>Макс. DPI</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.max_dpi}
													onChange={(e) =>
														setFieldValue(
															'max_dpi',
															e.target.value
														)
													}
													isInvalid={!!errors.max_dpi}
													disabled={!values.is_image}
													placeholder={'0 dpi'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.max_dpi}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Row>

									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Макс. размер изображения
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={
														values.image_max_size
													}
													onChange={(e) =>
														setFieldValue(
															'image_max_size',
															e.target.value
														)
													}
													isInvalid={
														!!errors.image_max_size
													}
													placeholder="0 Мб"
													disabled={!values.is_image}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.image_max_size}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col>
											<Form.Label>
												Соотношение сторон
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'text'}
													value={values.aspect_ratio}
													onChange={(e) =>
														setFieldValue(
															'aspect_ratio',
															e.target.value
														)
													}
													isInvalid={
														!!errors.aspect_ratio
													}
													placeholder={'16:9'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.aspect_ratio}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Row>
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Длительность показа (c)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.duration}
													onChange={(e) =>
														setFieldValue(
															'duration',
															e.target.value
														)
													}
													isInvalid={
														!!errors.duration
													}
													placeholder={'0 сек'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.duration}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col></Col>
									</Row>

									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Цветовая модель
											</Form.Label>
											<ButtonGroupSwitch
												elements={
													MEDIA_FORMATS.colorModel
												}
												initialValue={
													values.color_model
												}
												setFieldValue={setFieldValue}
												name={'color_model'}
												disabled={!values.is_image}
											/>
										</Col>
									</Row>

									<Row className={'w-100'}>
										<Col>
											<Form.Label>Кодек</Form.Label>
											<ButtonGroupSwitch
												elements={MEDIA_FORMATS.codec}
												initialValue={values.codec}
												setFieldValue={setFieldValue}
												name={'codec'}
												disabled={!values.is_video}
											/>
										</Col>
									</Row>
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Мин. кадровая частота (кадр/c)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.min_fps}
													onChange={(e) =>
														setFieldValue(
															'min_fps',
															e.target.value
														)
													}
													isInvalid={!!errors.min_fps}
													disabled={!values.is_video}
													placeholder={'0 fps'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.min_fps}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col>
											<Form.Label>
												Макс. кадровая частота (кадр/c)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.max_fps}
													onChange={(e) =>
														setFieldValue(
															'max_fps',
															e.target.value
														)
													}
													isInvalid={!!errors.max_fps}
													disabled={!values.is_video}
													placeholder={'0 fps'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.max_fps}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Row>
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Мин. битрейт (бит/c)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.min_bitrate}
													onChange={(e) =>
														setFieldValue(
															'min_bitrate',
															e.target.value
														)
													}
													isInvalid={
														!!errors.min_bitrate
													}
													disabled={!values.is_video}
													placeholder={'0 Мбит/с'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.min_bitrate}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col>
											<Form.Label>
												Макс. битрейт (бит/c)
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={values.max_bitrate}
													onChange={(e) =>
														setFieldValue(
															'max_bitrate',
															e.target.value
														)
													}
													isInvalid={
														!!errors.max_bitrate
													}
													disabled={!values.is_video}
													placeholder={'0 Мбит/с'}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.max_bitrate}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Row>
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Макс. размер видео
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'number'}
													value={
														values.video_max_size
													}
													onChange={(e) =>
														setFieldValue(
															'video_max_size',
															e.target.value
														)
													}
													isInvalid={
														!!errors.video_max_size
													}
													placeholder="0 Мб"
													disabled={!values.is_video}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.video_max_size}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
										<Col></Col>
									</Row>
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Звуковая дорожка
											</Form.Label>
											<ButtonGroupBooleanSwitch
												elements={['Да', 'Нет']}
												initialValue={
													values.with_audio_track
												}
												setFieldValue={setFieldValue}
												name={'with_audio_track'}
												disabled={!values.is_video}
											/>
										</Col>
									</Row>

									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Дополнительная информация
											</Form.Label>
											<Form.Control
												type={'text'}
												value={values.additional_info}
												onChange={(e) =>
													setFieldValue(
														'additional_info',
														e.target.value
													)
												}
												isInvalid={
													!!errors.additional_info
												}
												as={'textarea'}
												rows={4}
												spellCheck={false}
											/>
										</Col>
									</Row>
									<Row className={'w-100'}>
										<Col>
											<Form.Label>
												Ссылка на требования
											</Form.Label>
											<InputGroup
												hasValidation
												style={{ marginBottom: 0 }}
											>
												<Form.Control
													type={'text'}
													value={values.external_link}
													onChange={(e) =>
														setFieldValue(
															'external_link',
															e.target.value
														)
													}
													isInvalid={
														!!errors.external_link
													}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.external_link}
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Row>
								</Col>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="secondary"
									onClick={handleClose}
								>
									Отменить
								</Button>
								<Button
									variant="primary"
									onClick={handleSubmit}
									disabled={
										Object.keys(errors).length !== 0 ||
										submitDisabled
									}
								>
									{media ? 'Сохранить' : 'Создать'}
								</Button>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	)
}
export default ModalMediaFormat

const CustomCheckBox = ({ label, disabled = false, ...props }) => {
	// @ts-ignore
	const [field] = useField(props)
	return (
		<Form.Label style={{ color: disabled ? '#6C757D' : '#212529' }}>
			<Form.Check
				type="checkbox"
				{...field}
				{...props}
				disabled={disabled}
			/>
			{label}
		</Form.Label>
	)
}

export const ButtonGroupSwitch = ({
	elements,
	initialValue,
	setFieldValue,
	disabled,
	fullWidth = false,
	...props
}) => {
	// @ts-ignore
	const [active, setActive] = useState(
		initialValue ? initialValue : elements[0]
	)
	useEffect(() => {
		setFieldValue(props.name, initialValue ? initialValue : elements[0])
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<ButtonGroup size={'lg'} style={fullWidth ? { width: '100%' } : {}}>
			{elements.map((el, i) => (
				<Button
					variant={
						el.toUpperCase() === active.toUpperCase() && !disabled
							? 'primary'
							: 'secondary'
					}
					onClick={() => {
						setActive(el)
						setFieldValue(props.name, el)
					}}
					key={i}
					disabled={disabled}
					className={disabled ? 'btn-group-disabled' : ''}
				>
					{el}
				</Button>
			))}
		</ButtonGroup>
	)
}

const ConvertToNumberOrNull = (value) => {
	if (value === '') return null
	return parseInt(value)
}
export function validateUrl(value) {
	return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
		value
	)
}
