import React, { FC } from 'react'
import useQuery from '../../../../utils/useQuery'
import { BASE_URL } from '../../../../constants/api'
import AdMarketMultiplyDropdown from '../../../UtilityComponents/ADMarketMultiplyDropdown/ADMarketMultiplyDropdown'
import { Form } from 'react-bootstrap'
import { AGES_DATA } from 'src/mock'
import FormLabelTooltip from 'src/components/UtilityComponents/FormLabelTooltip'
import { TOOLTIPS } from '../tooltips.data'

interface IAgeSelector {
	value: number[]
	setFieldValue: (name: string, value: number[]) => null
	setFieldError: (name: string, error: any) => null
	error: string
}

const AgeSelector: FC<IAgeSelector> = ({
	setFieldValue,
	value,
	setFieldError,
	error,
}) => {
	const ages = AGES_DATA
	return (
		<Form.Group className={'mt-4'}>
			<Form.Label>Возраст {' '}</Form.Label>
			<AdMarketMultiplyDropdown
				list={ages?.map((el) => el.title) || []}
				selected={(() => {
					const selected: string[] = []
					ages?.forEach((age) =>
						value.includes(age.id) ? selected.push(age.title) : null
					)
					return selected
				})()}
				error={error}
				onSelectedChange={(newValues) => {}}
				noCheck
			/>
		</Form.Group>
	)
}

export default AgeSelector
