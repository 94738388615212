import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import AdMarketSelect from 'src/components/UtilityComponents/ADMarketSelect/AdMarketSelect'
interface IWatchTimeSelector {
	start_time: string
	end_time: string
	setFieldValue: (name: string, value: string) => null
	setFieldError: (name: string, error: any) => null
	errors: {}
}

const WatchTimeSelector: FC<IWatchTimeSelector> = ({
	start_time,
	end_time,
	errors,
	setFieldError,
	setFieldValue,
}) => {

	const periods = [
		{
			value: '6:00-12:00',
			label: '6:00-12:00',
		},
		{
			value: '9:00-15:00',
			label: '9:00-15:00',
		},
		{
			value: '12:00-18:00',
			label: '12:00-18:00',
		},
		{
			value: '15:00-21:00',
			label: '15:00-21:00',
		},
		{
			value: 'День 06:00-21:00',
			label: 'День 06:00-21:00',
		},
		{
			value: 'Круглосуточно',
			label: 'Круглосуточно',
		},
	]

	return (
		<Form.Group className={'mt-4'}>
			<Form.Label>Время показа рекламы</Form.Label>
			<div>
                <AdMarketSelect
					isDisabled={true}
					value={periods.find((item) => item.value === start_time) || null}
					options={periods}
					onChange={(newValue) => {
						setFieldError('start_time', undefined)
						setFieldValue(
							'start_time',
							newValue?.value || ''
						)
					}}
				/>
			</div>
		</Form.Group>
	)
}

export default WatchTimeSelector
