import { userDataStoreInterface } from '../stores/userData'
import axios, { AxiosError } from 'axios'

interface IAuthProgressFetch {
	url: string
	method: string
	body: {}
	onProgress?: ({
		isLoading,
		percent,
		total,
	}: {
		isLoading: boolean
		percent: number
		total: number
	}) => void
	onLoad?: (res: any) => any
	signal?: AbortSignal
}

async function authProgressFetch({
	url,
	method,
	body,
	onProgress,
	onLoad,
	signal,
}: IAuthProgressFetch) {
	const token = userDataStoreInterface.token()
	if (!token) return console.error('token is empty!')
	const form_data = new FormData()
	for (const key in body) {
		form_data.append(key, body[key])
	}
	try {
		//TODO Добавить обработку ошибок
		const res = await axios({
			url,
			method,
			data: form_data,
			headers: {
				Authorization: `Token ${token}`,
				'content-type': 'application/json',
			},
			onUploadProgress: function (progressEvent) {
				const { total, loaded } = progressEvent
				if (typeof onProgress === 'function')
					onProgress({
						isLoading: loaded === total,
						percent: (loaded / total) * 100,
						total,
					})
			},
			signal,
		})
		if (typeof onLoad === 'function') onLoad(res?.data)
	} catch (e: any) {
		return e?.response?.data
	}
}

export default authProgressFetch
