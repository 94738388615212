import React, { FC, useMemo } from 'react'

interface IInputLengthCounter {
	value: string
	maxLength: number
}

const InputLengthCounter: FC<IInputLengthCounter> = ({ maxLength, value }) => {
	const currentLength = useMemo(() => {
		return value.length
	}, [value])
	return (
		<span className={'w-100 text-secondary'} style={{ fontSize: '12px' }}>
			{currentLength}
			{` / `}
			{maxLength}
		</span>
	)
}

export default InputLengthCounter
