import * as Sentry from '@sentry/browser'
import { userDataStoreInterface } from '../stores/userData'
import { sleep } from './sleep'
import { notificationDataStoreInterface } from '../stores/notificationsData'

export type RequestInfo = {
	method: string
	url: string
	raw?: boolean
	query?: {
		[param: string]: string | number | boolean | string[] | number[]
	}
	body?: { [param: string]: any } | Blob
	headers?: string[][]
}

export class RequestError extends Error {
	data: null | object
	code: number

	constructor(code: number, data: any) {
		super()

		this.data = data
		this.code = code
	}
}

type RequestBody =
	| string
	| URLSearchParams
	| Blob
	| ArrayBufferView
	| ArrayBuffer
	| FormData
	| ReadableStream<Uint8Array>
	| null
	| undefined

type Headers = string[][]

function prepareRequestInfo(
	request: RequestInfo,
	headers: Headers = []
): Request {
	const requestUrl = new URL(request.url, window.location.origin)
	if (request.query) {
		Object.keys(request.query).forEach(
			(key) =>
				request.query &&
				request.query[key] &&
				requestUrl.searchParams.append(
					key,
					request.query[key].toString()
				)
		)
	}

	let body: RequestBody
	if (request.method !== 'GET') {
		if (!request.body) {
			body = '{}'
		}
		if (request.body instanceof Blob || request.body instanceof FormData) {
			body = request.body
		} else {
			body = JSON.stringify(request.body)
		}
	}

	return new Request(requestUrl.toString(), {
		body,
		mode: 'cors',
		method: request.method,
		headers: [
			...(request.body instanceof FormData
				? []
				: [['content-type', 'application/json']]),
			...(request.headers || []),
			...headers,
		],
	})
}

function createRequest(request: RequestInfo, headers: Headers = []) {
	return () => prepareRequestInfo(request, headers)
}

export async function authFetch<K>(request: RequestInfo): Promise<K> {
	const token = await userDataStoreInterface.token()
	const jwt_token = await userDataStoreInterface.jwt_token()

	request.headers = [['Authorization', `Token ${token}`]]
	if (jwt_token) {
		request.headers = [
			...request.headers,
			['X-JWT-AUTHORIZATION', `Token ${jwt_token}`],
		]
	}
	const req = createRequest(request)

	return doFetch<K>(req, request.raw)
}

export async function plainFetch<K>(request: RequestInfo): Promise<K> {
	const req = createRequest(request)

	return doFetch<K>(req, request.raw)
}

const STATUS_CODES = {
	OK: [200, 201, 204],
	RETRY: [500, 503, 429],
	CLIENT_ERROR: [400, 401, 402, 404, 403, 409, 412, 425],
}

function isRetryable(request: Request): boolean {
	return request.method !== 'POST'
}

async function doFetch<K>(
	requestCreator: () => Request,
	raw: boolean = false
): Promise<K> {
	const initialRetries = 3
	let retries = initialRetries

	while (retries) {
		const request = requestCreator()

		retries -= 1
		const response = await fetch(request)

		switch (true) {
			case STATUS_CODES.OK.includes(response.status):
				if (response.status === 204) debugger
				//Если в хедере есть кол-во уведомлений, обновляем стор
				if (response.headers.get('x-notifications-count')) {
					const current_counts =
						await notificationDataStoreInterface.getNotificationsCountNotRead()
					const from_response = response.headers.get(
						'x-notifications-count'
					)
					if (
						current_counts !==
						parseInt(from_response ? from_response : '')
					) {
						await notificationDataStoreInterface.changeNotificationCountsNotRead(
							from_response
						)
					}
				}
				return raw ? response : response.json()
			case STATUS_CODES.CLIENT_ERROR.includes(response.status):
				Sentry.withScope((scope) => {
					scope.setExtras({
						text: response.text,
						code: response.status,
						url: request.url,
					})

					Sentry.captureMessage(`Request failed`)
				})
				throw new RequestError(response.status, await response.json())
			case response.status === 401:
				const res = await response.json()
				if (res.message === 'Expired JWT Token') {
					continue
				}
				throw new RequestError(response.status, res)
			case STATUS_CODES.RETRY.includes(response.status) ||
				isRetryable(request):
				const lastResponseText = await response.text()
				const lastResponseStatus = response.statusText
				if (retries === 0) {
					Sentry.withScope((scope) => {
						scope.setExtras({
							text: lastResponseText,
							statusText: lastResponseStatus,
							message: request.url,
						})

						Sentry.captureMessage(
							`Request failed after ${initialRetries}`
						)
					})
				}

				await sleep(150)

				continue
			default:
				throw new Error('Unexpected exception')
		}
	}

	throw new Error(`Request failed after ${initialRetries}`)
}
