const ROUTES = {
	ROUTE_AUTH: '/auth/',
	ROUTE_CAMPAIGNS: '/campaigns/',
	ROUTE_CAMPAIGN_INFO: '/campaigns/:id',
	CREATE_CAMPAIGN: '/create-campaign/',
	CREATE_REPORT:'/create-report/',
	ROUTE_INVITE_PROVIDER: '/provider_invite/',
	ROUTE_PROVIDER: '/provider/',
	ROUTE_NOTIFICATIONS: '/notifications/',
	ROUTE_PROFILE: '/profile/',
	ROUTE_SETTINGS: '/settings/',
	ROUTE_OPERATIONS: '/operations/',
}

export default ROUTES
