import React, { FC, useMemo, useState } from 'react'
import { Button, ButtonGroup, Dropdown, Form, Modal } from 'react-bootstrap'
import useUserData from '../../../../../stores/userData'
import { Formik } from 'formik'
import useResponsive from '../../../../../utils/useResponsive'
import InputMask from 'react-input-mask'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'
import FileInput from '../../../../UtilityComponents/FileInput'
import FormLabelTooltip from '../../../../UtilityComponents/FormLabelTooltip'
import { CreateCreativeTooltips } from './CreateCreativeTooltips'
import { ScrollToError } from '../../../../UtilityComponents/ScrollToFormikError'

interface ICreateCreativeForm {
	onHide: () => void
	onSubmit: (values: CreateCreativeFields) => void
}

export type CreateCreativeFields = {
	full_name: string
	phone_number: string
	email: string
	logo: File | null
	target_audience?: string
	brand_book?: File | null
	url?: string
	color?: string
	style?: string
	ideas?: string
	// style_image?: File | null
	ideas_image?: File | null
	required_elements?: string
	required_file?: Blob | null
}

const CreateCreativeForm: FC<ICreateCreativeForm> = ({ onSubmit, onHide }) => {
	const [user] = useUserData()
	const { isMobile } = useResponsive()
	const [colorPaletteStyle, setColorPaletteStyle] = useState<
		'brandbook' | 'url' | 'color'
	>('brandbook')
	const initialValues = useMemo<CreateCreativeFields>(() => {
		return {
			full_name:
				user.last_name && user.first_name && user.middle_name
					? `${user.last_name} ${user.first_name} ${user.middle_name}`
					: '',
			phone_number: user.phone_number || '',
			email: user.email || '',
			logo: null,
			target_audience: '',
			brand_book: null,
			url: '',
			color: '',
			style: '',
			ideas: '',
			ideas_image: null,
			required_elements: '',
			required_file: null,
		}
	}, [user])

	const handleValidate = (
		values: CreateCreativeFields
	): { [k in keyof CreateCreativeFields]?: string } => {
		const errors = {}
		;['full_name', 'phone_number', 'email'].forEach((fname) => {
			if (!values[fname]) errors[fname] = 'Обязательное поле'
		})
		return errors
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, formikHelpers) => {
				onSubmit(values)
			}}
			validate={handleValidate}
			enableReinitialize={true}
			validateOnChange={false}
		>
			{({
				values,
				errors,
				setFieldValue,
				setFieldError,
				isValid,
				handleSubmit,
			}: {
				values: CreateCreativeFields
				errors: { [k in keyof CreateCreativeFields]?: string }
				setFieldValue: (
					field: keyof CreateCreativeFields,
					value: any
				) => void
				setFieldError: (
					field: keyof CreateCreativeFields,
					error?: string
				) => void
				isValid: boolean
				handleSubmit: any
			}) => (
				<Form
					className={'d-flex flex-column'}
					style={{ gap: isMobile ? '16px' : '24px' }}
					onSubmit={handleSubmit}
				>
					<ScrollToError />

					<p
						className={'mb-0'}
						style={{ fontSize: isMobile ? '14px' : '16px' }}
					>
						Создадим за вас материал бесплатно. В среднем, это
						занимает пару часов. Чем больше вы заполните полей, тем
						точнее будет дизайн материала. Если возникнут вопросы,
						специалист напишет вам на почту.
					</p>
					<Form.Group>
						<Form.Label>Ваше имя</Form.Label>
						<Form.Control
							type={'text'}
							value={values.full_name}
							onChange={(e) => {
								setFieldError('full_name', undefined)
								setFieldValue('full_name', e.target.value)
							}}
							isInvalid={!!errors.full_name}
							size={'lg'}
						/>
						<Form.Text>Обязательное поле</Form.Text>
						<Form.Control.Feedback type={'invalid'}>
							{errors.full_name}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>Телефон</Form.Label>
						<InputMask
							mask={'+7 (999) 999-99-99'}
							value={values.phone_number}
							onChange={(e) => {
								setFieldError('phone_number', undefined)

								setFieldValue('phone_number', e.target.value)
							}}
						>
							{(inputProps) => (
								<Form.Control
									size="lg"
									type={'phone'}
									isInvalid={!!errors.phone_number}
									{...inputProps}
								/>
							)}
						</InputMask>
						<Form.Text>Обязательное поле</Form.Text>
						<Form.Control.Feedback type={'invalid'}>
							{errors.phone_number}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>Email</Form.Label>
						<Form.Control
							type={'email'}
							value={values.email}
							onChange={(e) => {
								setFieldError('email', undefined)
								setFieldValue('email', e.target.value)
							}}
							isInvalid={!!errors.email}
							size={'lg'}
						/>
						<Form.Text>Обязательное поле</Form.Text>
						<Form.Control.Feedback type={'invalid'}>
							{errors.email}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Ваш логотип
							<FormLabelTooltip
								text={CreateCreativeTooltips.logo}
							/>
						</Form.Label>
						<FileInput
							onUpload={(file) => {
								setFieldValue('logo', file)
								setFieldError('logo', undefined)
							}}
							value={values.logo}
							onRemove={() => setFieldValue('logo', null)}
							size={20}
							// allowedExtensions={['JPEG,PNG,TIFF,JPG']}
							descr={
								'Допустимые форматы — JPEG, PNG, TIFF. Размер — 1440 × 720 px, не более 20 МБ.'
							}
							error={errors.logo}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Целевая аудитория
							<FormLabelTooltip
								text={CreateCreativeTooltips.audience}
							/>
						</Form.Label>
						<Form.Control
							type={'text'}
							value={values.target_audience}
							onChange={(e) => {
								setFieldError('target_audience', undefined)
								setFieldValue('target_audience', e.target.value)
							}}
							isInvalid={!!errors.target_audience}
							size={'lg'}
						/>
						<Form.Control.Feedback type={'invalid'}>
							{errors.target_audience}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Цветовая палитра
							<FormLabelTooltip
								text={CreateCreativeTooltips.color_palette}
							/>
						</Form.Label>
						<br />
						<ButtonGroup
							vertical={isMobile}
							className={'w-100 mb-3'}
						>
							{['brandbook', 'url', 'color'].map((type) => (
								<Button
									key={type}
									variant={
										type === colorPaletteStyle
											? 'primary'
											: 'secondary'
									}
									onClick={() => {
										if (colorPaletteStyle === type) return
										setColorPaletteStyle(
											type as
												| 'brandbook'
												| 'url'
												| 'color'
										)
									}}
								>
									{(() => {
										switch (type) {
											case 'brandbook':
												return 'Загрузить описание'
											case 'url':
												return 'Ссылка'
											case 'color':
												return 'Указать цвет'
										}
									})()}
								</Button>
							))}
						</ButtonGroup>
						{(() => {
							switch (colorPaletteStyle) {
								case 'brandbook':
									return (
										<FileInput
											onUpload={(file) => {
												setFieldValue(
													'brand_book',
													file
												)
												setFieldError(
													'brand_book',
													undefined
												)
											}}
											value={values.brand_book}
											onRemove={() =>
												setFieldValue(
													'brand_book',
													null
												)
											}
											size={20}
											descr={
												'Допустимые форматы — JPEG, PNG, TIFF. Размер — 1440 × 720 px, не более 20 МБ.'
											}
										/>
									)
								case 'url':
									return (
										<Form.Control
											type={'text'}
											value={values.url}
											onChange={(e) => {
												setFieldError('url', undefined)
												setFieldValue(
													'url',
													e.target.value
												)
											}}
											isInvalid={!!errors.url}
											size={'lg'}
											placeholder={'https://'}
										/>
									)
								case 'color':
									return (
										<div className={'d-flex gap-3'}>
											<Form.Control
												type={'color'}
												value={values.color}
												onChange={(e) => {
													setFieldError(
														'color',
														undefined
													)
													setFieldValue(
														'color',
														e.target.value
													)
												}}
												isInvalid={!!errors.color}
												size={'lg'}
												style={{
													minWidth: '48px',
													minHeight: '48px',
												}}
											/>
											<Form.Control
												type={'text'}
												value={values.color}
												readOnly
												size={'lg'}
												disabled
											/>
										</div>
									)
							}
						})()}
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Стиль
							<FormLabelTooltip
								text={CreateCreativeTooltips.style}
							/>
						</Form.Label>
						<Form.Control
							type={'text'}
							value={values.style}
							onChange={(e) => {
								setFieldError('style', undefined)
								setFieldValue('style', e.target.value)
							}}
							isInvalid={!!errors.style}
							size={'lg'}
						/>
						<Form.Control.Feedback type={'invalid'}>
							{errors.style}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Обязательные элементы
							<FormLabelTooltip
								text={CreateCreativeTooltips.required_elemets}
							/>
						</Form.Label>
						<Form.Control
							type={'text'}
							value={values.required_elements}
							onChange={(e) => {
								setFieldError('required_elements', undefined)
								setFieldValue(
									'required_elements',
									e.target.value
								)
							}}
							isInvalid={!!errors.required_elements}
							size={'lg'}
						/>
						<Form.Control.Feedback type={'invalid'}>
							{errors.required_elements}
						</Form.Control.Feedback>
						<div className={'mt-3'}>
							<FileInput
								onUpload={(file) => {
									setFieldValue('required_file', file)
									setFieldError('required_file', undefined)
								}}
								value={values.required_file}
								onRemove={() =>
									setFieldValue('required_file', null)
								}
								size={20}
								descr={
									'Допустимые форматы — JPEG, PNG, TIFF. Размер не более 20 МБ.'
								}
							/>
						</div>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Идеи
							<FormLabelTooltip
								text={CreateCreativeTooltips.ideas}
							/>
						</Form.Label>
						<Form.Control
							type={'text'}
							value={values.ideas}
							onChange={(e) => {
								setFieldError('ideas', undefined)
								setFieldValue('ideas', e.target.value)
							}}
							isInvalid={!!errors.ideas}
							size={'lg'}
						/>
						<Form.Control.Feedback type={'invalid'}>
							{errors.ideas}
						</Form.Control.Feedback>
						<div className={'mt-3'}>
							<FileInput
								onUpload={(file) => {
									setFieldValue('ideas_image', file)
									setFieldError('ideas_image', undefined)
								}}
								value={values.ideas_image}
								onRemove={() =>
									setFieldValue('ideas_image', null)
								}
								size={20}
								descr={
									'Допустимые форматы — JPEG, PNG, TIFF. Размер не более 20 МБ.'
								}
							/>
						</div>
					</Form.Group>
					<div
						className={'d-flex gap-2 mt-2'}
						style={{
							justifyContent: isMobile ? 'center' : 'flex-end',
						}}
					>
						<Button
							variant={'secondary'}
							style={{ width: isMobile ? '50%' : 'fit-content' }}
							onClick={onHide}
						>
							Отменить
						</Button>
						<Button
							variant={'primary'}
							style={{ width: isMobile ? '50%' : 'fit-content' }}
							disabled={!isValid}
							type={'submit'}
						>
							Создать материал
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default CreateCreativeForm
