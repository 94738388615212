import React, { FC } from 'react'

interface IRowLabelValue {
	label: string | number | React.ReactNode
	value: string | number | React.ReactNode
	isFirst?: boolean
	fontSize?: string
	show?: boolean
}

const RowLabelValue: FC<IRowLabelValue> = ({
	label,
	value,
	isFirst = false,
	fontSize = '16px',
	show = true,
}) => {
	if (!show) return null
	return (
		<div
			className={'d-flex flex-column'}
			style={{
				fontSize,
				marginTop: isFirst ? '0' : '16px',
			}}
		>
			<span className={'text-secondary mb-1'}>{label}</span>
			<div>{value}</div>
		</div>
	)
}

export default RowLabelValue
