import React from 'react'
import s from './NotificationsIcon.module.scss'

const NotificationsIcon = ({
	onClick,
	isNotificationPage,
	notifications_count_not_read,
}) => {
	return (
		<div
			className={s.notificationIcon}
			onClick={onClick}
			style={isNotificationPage ? { backgroundColor: '#F5F5F5' } : {}}
			id={'notification-header-icon'}
		>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17 7C17 5.14349 16.2625 3.36301 14.9497 2.05025C13.637 0.737499 11.8565 0 10 0C8.14349 0 6.36301 0.737498 5.05025 2.05025C3.7375 3.36301 2.99994 5.14349 2.99994 7V9.58579L1.70705 10.8787C0.535476 12.0503 0.535476 13.9497 1.70705 15.1213L2.29284 15.7071C2.48037 15.8946 2.73473 16 2.99994 16H6C6 17.0609 6.42143 18.0783 7.17157 18.8284C7.92172 19.5786 8.93913 20 10 20C11.0609 20 12.0783 19.5786 12.8284 18.8284C13.5786 18.0783 14 17.0609 14 16H16.9999C17.2652 16 17.5195 15.8946 17.7071 15.7071L18.2928 15.1213C19.4644 13.9497 19.4644 12.0503 18.2928 10.8787L16.9999 9.58579L17 7ZM4.99994 10V7C4.99994 5.67392 5.52679 4.40215 6.46447 3.46447C7.40215 2.52678 8.67392 2 10 2C11.3261 2 12.5979 2.52678 13.5355 3.46447C14.4732 4.40215 14.9999 5.67392 14.9999 7V10C14.9999 10.2652 15.1053 10.5196 15.2928 10.7071L16.8786 12.2929C17.2691 12.6834 17.2691 13.3166 16.8786 13.7071L16.5857 14H3.41416L3.12126 13.7071C2.73074 13.3166 2.73074 12.6834 3.12126 12.2929L4.70705 10.7071C4.89459 10.5196 4.99994 10.2652 4.99994 10ZM8 16C8 16.5304 8.21071 17.0391 8.58579 17.4142C8.96086 17.7893 9.46957 18 10 18C10.5304 18 11.0391 17.7893 11.4142 17.4142C11.7893 17.0391 12 16.5304 12 16H8Z"
					fill="#6C757D"
				/>
			</svg>
			{notifications_count_not_read > 0 && (
				<span className={s.NewNotificationDisplay} />
			)}
		</div>
	)
}

export default NotificationsIcon
