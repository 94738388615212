import produce from 'immer'
import moment from 'moment'
import { createStoreon } from 'storeon'
import { storeonDevtools } from 'storeon/devtools'
import {
	advertisingCompanies,
	advertisingCompaniesStats,
	BidRequest,
	campaignToPayment,
	cancelBooking,
	channelAdv,
	companyInfo,
	companyToCancel,
	companyToDraft,
	companyToModeration,
	confirmBudget,
	createADCompany,
	deleteBooking,
	deleteCompanyMedia,
	internetVk,
	internetYandex,
	invoices,
	payments,
	teletargetFilters,
	updateBooking,
	updateCompanyMedia,
	uploadCompanyMedia,
} from '../constants/api'
import { authFetch } from '../utils/authFetch'
import createStoreHook from '../utils/createStoreHook'
import makeStoreResettable from '../utils/makeStoreResettable'
import {
	AdStreamType,
	BookingState,
	CampaignInfoType,
	CampaignInfoTypeBookingsSeparation,
	CampaignStates,
	InternetState,
	Invoice,
	Payment,
	TvState,
} from './ADMarketTypes.types'
import { userDataStore } from './userData'
import makeBookingsSeparation from './makeBookingsSeparation'
import { bidInfo } from '../components/_client/create-campaign/Step3-Upload/CreateCreative/useBidRequest'
import { getPromiseOrValue } from 'src/utils/useQuery'
import { COMPANY_DATA_STEP1 } from 'src/mock'

type CampaignDataState = {
	data: CampaignInfoTypeBookingsSeparation
	ready: boolean
	list: any
}

export const CampaignDataStore = createStoreon<CampaignDataState, any>([
	(store) => {
		makeStoreResettable(store, {
			data: null,
			ready: false,
			list: { results: [] },
		})

		store.on(
			'receive',
			produce((state, data) => {
				state.data =
					Object?.keys(data).length === 0
						? data
						: makeBookingsSeparation(data)
				state.ready = true
			})
		)
		store.on(
			'list',
			produce((state, data) => {
				state.list = data
			})
		)
	},
	process.env.NODE_ENV !== 'production' &&
		storeonDevtools({ name: 'admarket-campaignsData' }),
])
// Создание нового стора для запроса fetchCampaignStats
export const CampaignStatsStore = createStoreon<any>([
	(store) => {
		makeStoreResettable(store, {})

		store.on('receiveStats', (state, stats) => {
			return stats
		})
	},
	process.env.NODE_ENV !== 'production' &&
		storeonDevtools({ name: 'campaign-stats' }),
])

export const campaignStatsStoreInterface = {
	get() {
		return CampaignStatsStore.get()
	},

	subscribe(callback) {
		return CampaignStatsStore.on('@changed', callback)
	},
	async fetchCampaignStats(id) {
		try {
			const stats = await authFetch({
				url: advertisingCompaniesStats.params(id),
				method: 'GET',
			})
			if (stats) {
				CampaignStatsStore.dispatch('receiveStats', stats)
			}
		} catch (error) {
			console.error('Failed to fetch campaign stats:', error)
		}
	},
}

export const campaignDataStoreInterface = {
	name: 'CampaignData',

	get() {
		return CampaignDataStore.get().data
	},
	list() {
		return CampaignDataStore.get().list
	},

	subscribe(callback) {
		return CampaignDataStore.on('@changed', callback)
	},

	checkDataIsReady() {
		return userDataStore.get().ready
	},
	fetchCampaignStats(id) {
		return campaignStatsStoreInterface.fetchCampaignStats(id)
	},

	async fetch() {
		const dataUrl = '/core/api/v1/adv_companies/?limit=9999&offset=0'

		const collection: any[] = getPromiseOrValue(dataUrl)
		if (collection) {
			CampaignDataStore.dispatch('list', collection)
			CampaignDataStore.dispatch('receive', {})
		} else {
			CampaignDataStore.dispatch('list', { results: [] })
			CampaignDataStore.dispatch('receive', {})
		}
	},

	async refetchSelected() {
		const id = campaignDataStoreInterface.get().id
		if (!id) {
			// await campaignDataStoreInterface.fetch();
		} else {
			try {
				const campaign = await authFetch<CampaignInfoType>({
					url: companyInfo.params(id),
					method: 'GET',
				})
				CampaignDataStore.dispatch('receive', campaign)
			} catch (e) {
				CampaignDataStore.dispatch('receive', {})
			}
		}
	},

	async fetchCampaign(id, refreshPayment = false) {
		if (!id) return
		try {
			const campaign = await authFetch<CampaignInfoType>({
				url: refreshPayment
					? companyInfo.params(id) + '?payment=refresh'
					: companyInfo.params(id),
				method: 'GET',
			})
			CampaignDataStore.dispatch('receive', campaign)
		} catch (e) {
			CampaignDataStore.dispatch('receive', {})
			return e
		}
	},
	async refetchList() {
		const collection: any[] = await authFetch({
			url: advertisingCompanies,
			method: 'GET',
		})
		if (collection) {
			CampaignDataStore.dispatch('list', collection)
		} else {
			CampaignDataStore.dispatch('list', { results: [] })
		}
	},

	// async fetchCampaignStats(id) {

	// 	const stats: any[] = await authFetch({
	// 	  url: advertisingCompaniesStats.params(id),
	// 	  method: 'GET',

	// 	})
	// 	if (stats) {
	// 	  CampaignDataStore.dispatch('list', stats)
	// 	} else {
	// 	  CampaignDataStore.dispatch('list', { results: [] })
	// 	}
	//   },

	removeSelectedCampaign() {
		CampaignDataStore.dispatch('receive', {})
	},

	async createCampaign(body: {
		start_date: string
		end_date: string
		company_id: string
		name: string
		gender_ids: number[]
		age_ids: number[]
		region_ids: number[]
		start_time: string
		end_time: string
	}) {
		const campaign: any = await authFetch({
			url: createADCompany,
			method: 'POST',
			body,
		})
		await campaignDataStoreInterface.fetchCampaign(campaign.id)
		// CampaignDataStore.dispatch('receive', campaign)
		// await campaignDataStoreInterface.refetchList()
		return { id: campaign?.id }
	},
	async deleteCampaign(id) {
		try {
			await authFetch({
				url: companyInfo.params(id),
				method: 'DELETE',
			})
			await campaignDataStoreInterface.refetchList()
			CampaignDataStore.dispatch('receive', {})
		} catch (e) {
			return e
		}
	},
	async cancelCampaign(id) {
		try {
			await authFetch({
				url: companyToCancel.params(id),
				method: 'POST',
			})
			await campaignDataStoreInterface.refetchList()
			await campaignDataStoreInterface.refetchSelected()
		} catch (e) {
			return e
		}
	},

	async patchCampaign(body = {}) {
		const campaign = COMPANY_DATA_STEP1
		CampaignDataStore.dispatch('receive', {...campaign, ...body})
	},

	async updateBooking(advertising_company_id, bookings) {
		await authFetch({
			url: updateBooking.params(advertising_company_id),
			method: 'POST',
			body: bookings,
		})
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},

	async deleteBooking(id) {
		await authFetch({ url: deleteBooking.params(id), method: 'DELETE' })
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},
	async deleteAllBookingsByType(bookings_type: 'indoor' | 'outdoor') {
		const campaign = campaignDataStoreInterface.get()
		const ids: number[] = []
		if (bookings_type === 'indoor')
			campaign.indoor_bookings.forEach((b) => ids.push(b.id))
		if (bookings_type === 'outdoor')
			campaign.outdoor_bookings.forEach((b) => ids.push(b.id))

		await authFetch({
			url: updateBooking.params(campaign.id),
			method: 'DELETE',
			body: { booking_ids: ids },
		})
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},
	async cancelBooking(id) {
		await authFetch({
			url: cancelBooking.params(id),
			method: 'PATCH',
			body: { state: 'canceled' },
		})
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},

	async patchInternetVk(selected) {
		
	},
	async patchInternetYandex(selected) {
		
	},

	async changeInternetStatus(id, is_selected) {
		const form = new FormData()
		form.append('is_selected', is_selected)
		await authFetch({
			url: internetVk.params(id),
			method: 'PATCH',
			body: form,
		})
		await authFetch({
			url: internetYandex.params(id),
			method: 'PATCH',
			body: form,
		})
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},

	async uploadMedia(body: FormData) {
		await authFetch({ url: uploadCompanyMedia, method: 'POST', body })
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},
	async removeMedia(id) {
		await authFetch({
			url: deleteCompanyMedia.params(id),
			method: 'DELETE',
		})
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},
	async updateMedia(id, file) {
		const formData = new FormData()
		formData.append('file', file)
		await authFetch({
			url: updateCompanyMedia.params(id),
			method: 'PATCH',
			body: formData,
		})
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},
	async confirmBudget(id) {
		await authFetch({ url: confirmBudget.params(id), method: 'POST' })
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},

	async moveCampaignToModeration(id) {
		await authFetch({
			url: companyToModeration.params(id),
			method: 'POST',
			body: {},
		})
		await campaignDataStoreInterface.refetchSelected()
		await campaignDataStoreInterface.refetchList()
	},
	async moveCampaignToDraft(id) {
		const campaign: any = await authFetch({
			url: companyToDraft.params(id),
			method: 'POST',
			body: {},
		})
		await campaignDataStoreInterface.refetchList()
		return campaign?.id
	},
	async isBudgetConfirmed() {
		const id = campaignDataStoreInterface.get().id

		const campaign = await authFetch({
			url: companyInfo.params(id),
			method: 'GET',
		})
		CampaignDataStore.dispatch('receive', campaign)
		// @ts-ignore
		return campaign.is_budget_confirmed
	},
	async saveTeletargetFilters(id, body) {
		try {
			await authFetch({
				url: teletargetFilters.params(id),
				method: 'PATCH',
				body,
			})
			await campaignDataStoreInterface.refetchSelected()
		} catch (e: any) {
			return e?.data
		}
	},
	async saveVideoFilters(id, body, noRefreshCompany = false) {
		try {
			await authFetch({
				url: channelAdv.params(id),
				method: 'PATCH',
				body,
			})
			if (!noRefreshCompany) {
				await campaignDataStoreInterface.refetchSelected()
			}
		} catch (e: any) {
			return e?.data
		}
	},
	async checkAdStreamTaskStatus(id) {
		const res: AdStreamType & { task_id: string } = await authFetch({
			url: channelAdv.params(id),
			method: 'GET',
		})
		return res?.task_id === ''
	},
	getCampaignPeriod() {
		const campaign = campaignDataStoreInterface.get()
		const startMoment = moment(campaign.start_date)
		const endMoment = moment(campaign.end_date)
		const withYear = startMoment.year() !== endMoment.year()
		const withMonth = withYear || startMoment.month() !== endMoment.month()
		const start_text = startMoment.format(
			'D' + (withMonth ? ' MMMM' : '') + (withYear ? ' YYYY' : '')
		)
		return `c ${start_text} по ${endMoment.format('D MMMM YYYY')}`
	},
	isCanReturnedToDraft(): boolean {
		const campaign = campaignDataStoreInterface.get()
		const { state } = campaign
		if (state === CampaignStates.Canceled) return true
		if (
			state !== CampaignStates.Moderation &&
			state !== CampaignStates.Confirmed
		)
			return false
		const CONFIRMED_BOOKINGS_STATES = [
			BookingState.Confirm,
			BookingState.Reject,
			BookingState.Canceled,
		]

		const CONFIRMED_INTERNET_STATES = [
			InternetState.Confirm,
			InternetState.Reject,
			InternetState.Canceled,
		]
		const CONFIRMED_TV_STATES = [
			TvState.Confirm,
			TvState.Reject,
			TvState.Canceled,
		]
		const allBookingsInRightState = campaign.booking.reduce(
			(acc: boolean, booking) =>
				!CONFIRMED_BOOKINGS_STATES.includes(booking.state)
					? (acc = false)
					: acc
					? acc
					: (acc = false),
			true
		)
		const teletargetRightState = campaign.teletarget.is_selected
			? CONFIRMED_TV_STATES.includes(campaign.teletarget.state)
			: true

		const adstreamRightState = campaign.adstream.is_selected
			? CONFIRMED_TV_STATES.includes(campaign.adstream.state)
			: true

		const internetRightState =
			(campaign.internet_vk.is_selected
				? CONFIRMED_INTERNET_STATES.includes(campaign.internet_vk.state)
				: true) &&
			(campaign.internet_yandex.is_selected
				? CONFIRMED_INTERNET_STATES.includes(
						campaign.internet_yandex.state
				  )
				: true)

		return (
			allBookingsInRightState &&
			teletargetRightState &&
			internetRightState &&
			adstreamRightState
		)
	},
	async moveCampaignToPayment() {
		const { id } = campaignDataStoreInterface.get()
		try {
			await authFetch({
				url: campaignToPayment.params(id),
				method: 'POST',
			})
			await campaignDataStoreInterface.refetchSelected()
		} catch (e) {
			return e
		}
	},
	async createInvoice(): Promise<Invoice> {
		return new Promise<Invoice>(async (resolve, reject) => {
			try {
				const invoice: Invoice = await authFetch({
					url: invoices,
					method: 'POST',
					body: {
						advertising_campaign_id:
							campaignDataStoreInterface.get().id,
					},
				})
				await campaignDataStoreInterface.refetchSelected()
				resolve(invoice)
			} catch (e) {
				reject(e)
			}
		})
	},
	async createPayment(): Promise<Payment> {
		return new Promise<Payment>(async (resolve, reject) => {
			try {
				const payment: Payment = await authFetch({
					url: payments,
					method: 'POST',
					body: {
						advertising_campaign_id:
							campaignDataStoreInterface.get().id,
					},
				})
				await campaignDataStoreInterface.refetchSelected()
				resolve(payment)
			} catch (e) {
				reject(e)
			}
		})
	},
	async createNewBidRequest(bidData: FormData, bidInfo: bidInfo) {
		try {
			let id_field_name = ''
			switch (bidInfo.type) {
				case 'adstream':
					id_field_name = 'adstream_id'
					break
				case 'booking':
					id_field_name = 'booking_id'
					break
				case 'internet_vk':
					id_field_name = 'vk_id'
					break
				case 'internet_yandex':
					id_field_name = 'yandex_id'
					break
				case 'teletarget':
					id_field_name = 'teletarget_id'
					break
			}
			bidData.append(id_field_name, bidInfo.id.toString())
			await authFetch({ method: 'POST', url: BidRequest, body: bidData })
			await campaignDataStoreInterface.refetchSelected()
		} catch (e) {
			return e
		}
	},
	isAllMediaUpload(): boolean {
		const campaign = campaignDataStoreInterface.get()
		const { media: bookings_media } = campaign

		if (campaign.indoor_adv_is_selected) {
			const count = campaign.indoor_bookings.reduce((acc: number, el) => {
				const media = bookings_media.find((m) => m.booking_id === el.id)
				if (media) acc++
				return acc
			}, 0)
			if (count !== campaign.indoor_bookings.length) return false
		}
		if (campaign.outdoor_adv_is_selected) {
			const count = campaign.outdoor_bookings.reduce(
				(acc: number, el) => {
					const media = bookings_media.find(
						(m) => m.booking_id === el.id
					)
					if (media) acc++
					return acc
				},
				0
			)
			if (count !== campaign.outdoor_bookings.length) return false
		}
		if (campaign.internet_vk.is_selected && !campaign.internet_vk.image)
			return false
		if (
			campaign.internet_yandex.is_selected &&
			!campaign.internet_yandex.image
		)
			return false
		if (campaign.adstream.is_selected && !campaign.adstream.image)
			return false
		if (
			campaign.teletarget.is_selected &&
			!campaign.teletarget.image &&
			!campaign.teletarget.l_image
		)
			return false
		return true
	},
}

userDataStore.on('logout', () => {
	CampaignDataStore.dispatch('receive', {})
	CampaignDataStore.dispatch('list', { results: [] })
})

const useCampaign = createStoreHook(campaignDataStoreInterface)
const useCampaignData = (): [
	CampaignInfoTypeBookingsSeparation,
	typeof campaignDataStoreInterface
] => {
	const [campaign, campaignInterface] = useCampaign()
	return [campaign, campaignInterface]
}
export default useCampaignData
