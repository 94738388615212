import { AnimateSharedLayout, motion } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Dropdown, Form } from 'react-bootstrap'
import AdMarketDropdown from '../Dropdown'
import s from './ADMarketMultiplyDropdown.module.scss'

interface AdMarketMultiplyDropdownProps {
	list: string[]
	selected: string[]
	error: string
	onSelectedChange: (selected: string[]) => void
	displayCount?: number
	additionalInfo?: { title: string; info: string }[]
	withSeparator?: boolean
	withSearch?: boolean
	noCheck?: boolean
}

const AdMarketMultiplyDropdown = ({
	list,
	selected,
	error,
	onSelectedChange,
	displayCount = 7,
	additionalInfo,
	withSeparator,
	withSearch = false,
	noCheck = false,
}: AdMarketMultiplyDropdownProps) => {
	const selectedFiltered = useMemo(() => {
		return selected.filter((item) => list.includes(item)).sort()
	}, [selected, list])

	const [search, setSearch] = useState<string>('')

	const isItemInSelected = useCallback(
		(item) => {
			return selectedFiltered.includes(item)
		},
		[selectedFiltered]
	)

	useEffect(() => {
		if (noCheck) return
		onSelectedChange(selected.filter((item) => list.includes(item)).sort())
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const inputList = useMemo<string[]>(() => {
		if (!withSearch) return list
		if (search === '') return list
		return list.filter((item) =>
			item.toString().toLowerCase().includes(search.toLowerCase())
		)
	}, [list, withSearch, search])

	return (
		<>
			<AdMarketDropdown
				disabled={true}
				menu={({ close }) => (
					<div className={s.menu}>
						{withSearch && (
							<div className={s.search}>
								<Form.Control
									type={'text'}
									placeholder={'Поиск'}
									onChange={(e) => setSearch(e.target.value)}
								/>
								<i className="bi bi-search"></i>
							</div>
						)}
						<div className={s.menuData}>
							{inputList.map((item, index) => (
								<React.Fragment key={item}>
									<div
										className={
											!additionalInfo
												? s.menuItem
												: s.menuItemWithDesc +
												  ' noselect'
										}
										onClick={(e) => {
											if (isItemInSelected(item)) {
												e.stopPropagation()
												const new_value =
													selectedFiltered.filter(
														(data) => data !== item
													)
												onSelectedChange(new_value)
											} else {
												e.stopPropagation()
												onSelectedChange([
													...selectedFiltered,
													item,
												])
											}
										}}
									>
										<Form.Check
											type={'checkbox'}
											checked={
												isItemInSelected(item) || false
											}
											style={{ padding: '16px' }}
											onChange={() => {}}
										/>
										<div className={'d-flex flex-column'}>
											{item}
											{additionalInfo && (
												<span>
													{
														additionalInfo.find(
															(k) =>
																k.title === item
														)?.info
													}
												</span>
											)}
										</div>
									</div>
									{withSeparator && (
										<>
											{index + 1 !== list.length && (
												<Dropdown.Divider
													className={
														'm-0 p-0 opacity-50'
													}
												/>
											)}
										</>
									)}
								</React.Fragment>
							))}
						</div>
						<div className={s.menuControls}>
							<div>
								<a
									onClick={(e) => {
										e.preventDefault()
										onSelectedChange(list)
										close()
									}}
									href={'/'}
								>
									Выбрать все
								</a>
							</div>
							<div>
								<a
									onClick={(e) => {
										e.preventDefault()
										onSelectedChange([])
									}}
									href={'/'}
								>
									Снять все
								</a>
							</div>
							<div>
								<Button
									onClick={close}
									style={{ marginLeft: '-12px' }}
								>
									Готово
								</Button>
							</div>
						</div>
					</div>
				)}
				zIndex={50}
				offset={0}
				menuWidth={'100%'}
			>
				<div
					className={`form-control form-control-lg noselect ${
						s.trigger
					} ${error ? 'is-invalid' : ''}`}
					tabIndex={1}
				>
					<AnimateSharedLayout>
						{selectedFiltered.map((item, index) => {
							if (index > displayCount - 1) return null
							return (
								<motion.div
									key={item}
									className={s.itemSelected}
									layout
								>
									{item}
									<i
										className={
											'bi bi-x ps-1 text-secondary'
										}
										onClick={(e) => {
											e.stopPropagation()
											const new_value =
												selectedFiltered.filter(
													(data) => data !== item
												)
											onSelectedChange(new_value)
										}}
									/>
								</motion.div>
							)
						})}
						{selectedFiltered.length > displayCount && (
							<motion.div className={s.itemSelected} layout>
								+{selectedFiltered.length - displayCount}
							</motion.div>
						)}
						{selectedFiltered.length === 0 && (
							<span className={'text-secondary'}>
								Выберите значения из выпадающего списка
							</span>
						)}
					</AnimateSharedLayout>
					{!error && (
						<div
							style={{
								position: 'absolute',
								top: 'calc(50% + 2)',
								right: 16,
							}}
						>
							<svg
								width="10"
								height="5"
								viewBox="0 0 10 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0H0L5 5L10 0Z" fill="#495057" />
							</svg>
						</div>
					)}
				</div>
			</AdMarketDropdown>
			{error && (
				<div
					className={'invalid-feedback'}
					style={{ display: 'block' }}
				>
					{error || 'Ошибка'}
				</div>
			)}
		</>
	)
}

export default AdMarketMultiplyDropdown
