import React, { FC, useMemo } from 'react'
import { InternetUploadForm } from './InternetUpload'
import useCampaignData from '../../../../../stores/campaignData'
import { VkIcon } from '../../Step2-ADformats/InternetAD/InternetSelector'
import { Button, Form } from 'react-bootstrap'
import FormLabelTooltip from '../../../../UtilityComponents/FormLabelTooltip'
import { InternetTooltips } from '../../Step2-ADformats/InternetAD/InternetTooltips'
import { internetVk } from '../../../../../constants/api'
import InputLengthCounter from '../../../../UtilityComponents/InputLengthCounter'
import AdMarketSelect from '../../../../UtilityComponents/ADMarketSelect/AdMarketSelect'
import VkButtonTextData from './VkButtonText.data'
import useBidRequest, { bidInfo } from '../CreateCreative/useBidRequest'
import useResponsive from '../../../../../utils/useResponsive'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import CreativeFileInput from '../../../../UtilityComponents/CreativeFileInput'
import { CreateCreativeFields } from '../CreateCreative/CreateCreativeForm'
import formatCreativeFormValues from '../CreateCreative/formatCreativeFormValues'
import { authFetch } from 'src/utils/authFetch'

interface IUploadVk {
	values: InternetUploadForm
	setFieldValue: (field_name: keyof InternetUploadForm, value: any) => null
	setFieldError: (field_name: keyof InternetUploadForm, error: any) => null
	errors: { [key in keyof InternetUploadForm]?: string }
}

const UploadVk: FC<IUploadVk> = ({
	values,
	setFieldValue,
	setFieldError,
	errors,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isHaveBidRequest } = useBidRequest()
	const { isMobile } = useResponsive()
	const modalType = useMemo<REUSABLE_MODALS>(() => {
		return isMobile
			? REUSABLE_MODALS.createCreativeMobileModal
			: REUSABLE_MODALS.createCreativeDesktopModal
	}, [isMobile])
	const showModal = useReusableModals()
	const bidInfo: bidInfo = {
		id: campaign.internet_vk.id,
		type: 'internet_vk',
	}

	const patchSingleField = async (
		field: keyof InternetUploadForm,
		value: string | number
	) => {
		const correctFieldName = field.replace(field.split('_')[0] + '_', '')
		const prevValue = campaign.internet_vk[correctFieldName]
		if (value === prevValue) return
		const formData = new FormData()
		formData.append(correctFieldName, value.toString())
		await campaignInterface.patchInternetVk(true)
	}

	const handleCreateMaterial = async () => {
		const requestData = {
			cls_name: 'vkinternetadvertising',
			instance_id: campaign.internet_vk.id,
		}

		try {
			const response = await authFetch({
				method: 'POST',
				url: `/core/api/v1/vinstant/adv_companies/${campaign.id}/`,
				body: requestData,
			})

			const url: any = response
			// setIframeUrl(url.redirect_url);
			// setModalOpen(true);

			// const editorWindow = window.open(url.redirect_url, 'Редактор', 'width=800,height=600');

			// window.location.href = url.redirect_url;

			window.open(url.redirect_url, '_blank')
		} catch (error) {
			console.error('Ошибка при отправке запроса:', error)
		}
	}

	return (
		<div className={'d-flex flex-column gap-4'}>
			<div className={'d-flex justify-content-between'}>
				<h5 className={'mb-0'}>VK Реклама</h5>
				<div>
					<VkIcon />
				</div>
			</div>
			{/*Heading*/}
			<Form.Group>
				<Form.Label>
					Что будете рекламировать?{' '}
					<FormLabelTooltip text={InternetTooltips.VK.heading} />
				</Form.Label>
				<Form.Control
					type={'text'}
					size={'lg'}
					value={values.vk_heading}
					onChange={(e) => {
						setFieldError('vk_heading', undefined)
						setFieldValue('vk_heading', e.target.value)
					}}
					onBlur={() => {
						if (values.vk_heading !== '') {
							patchSingleField(
								'vk_heading',
								values.vk_heading
							).then()
						} else {
							setFieldError('vk_heading', 'Введите заголовок')
						}
					}}
					isInvalid={!!errors.vk_heading}
					placeholder={'Не задан'}
					maxLength={25}
				/>
				<Form.Control.Feedback type={'invalid'}>
					{errors.vk_heading}
				</Form.Control.Feedback>
				<InputLengthCounter value={values.vk_heading} maxLength={25} />
			</Form.Group>
			{/*Text*/}
			<Form.Group>
				<Form.Label>
					Описание вашего товара или услуги{' '}
					<FormLabelTooltip text={InternetTooltips.VK.text} />
				</Form.Label>
				<Form.Control
					type={'text'}
					size={'lg'}
					value={values.vk_text}
					onChange={(e) => {
						setFieldError('vk_text', undefined)
						setFieldValue('vk_text', e.target.value)
					}}
					onBlur={() => {
						if (values.vk_text !== '') {
							patchSingleField('vk_text', values.vk_text).then()
						} else {
							setFieldError('vk_text', 'Введите текст объявления')
						}
					}}
					isInvalid={!!errors.vk_text}
					placeholder={'Не задан'}
					maxLength={90}
				/>
				<Form.Control.Feedback type={'invalid'}>
					{errors.vk_text}
				</Form.Control.Feedback>
				<InputLengthCounter value={values.vk_text} maxLength={90} />
			</Form.Group>
			<Form.Group>
				<Form.Label>Рекламный материал</Form.Label>
				<CreativeFileInput
					bidInfo={bidInfo}
					initialValue={{
						src: campaign.internet_vk.image,
						name: 'Текущий материал',
					}}
					updateUrl={internetVk.params(campaign.id)}
					nameInBody={'image'}
					description={
						'Изображения — JPEG, PNG. Разрешение: 1920 x 1080 px. Размер: до 5 МБ.'
					}
					removeMethod={'PATCH'}
					onFileLoad={() => campaignInterface.refetchSelected()}
					onFileDelete={() => campaignInterface.refetchSelected()}
				/>
			</Form.Group>
			{/*<ModalInternetMediaRequirements internetName={'VK'} />*/}
			{!isHaveBidRequest(bidInfo) && !campaign.internet_vk.image && (
				<Button
					variant={'light'}
					className={'w-100'}
					onClick={handleCreateMaterial}
				>
					Создать материал
				</Button>
			)}
			<Form.Group>
				<Form.Label>
					Надпись на кнопке
					<FormLabelTooltip text={InternetTooltips.VK.button_text} />
				</Form.Label>
				<AdMarketSelect
					value={
						{
							label: VkButtonTextData.get(values.vk_button_text),
							value: values.vk_button_text,
						} || null
					}
					options={VkButtonTextData.generateArray()}
					onChange={(newValue) => {
						setFieldError('vk_button_text', undefined)
						patchSingleField(
							'vk_button_text',
							newValue?.value || ''
						).then()
					}}
				/>
			</Form.Group>
		</div>
	)
}

export default UploadVk
