export const CreateCreativeTooltips = {
	audience:
		'Укажите, для какой аудитории предназначен материал. Эта информация повлияет на выбор стиля. Например: «Для женщин и девушек в отношениях».',
	color_palette:
		'Загрузите или укажите ссылку на описание, чтобы специалист мог создать материал в едином стиле. Если его нет, тогда укажите какую цветовую гамму вы хотите видеть на материале, нужно ли на него добавлять ваш логотип, желаемый шрифт. Если нет определенных пожеланий, можете указать, какие цвета нельзя использовать, это также поможет специалисту.',
	style: 'Укажите желаемый стиль: мультипликация, фотореалистичный, текстовый, смешанный.',
	required_elemets:
		'Перечислите элементы, которые обязательно нужно включить в материал (фото, графические материалы, логотип, телефон, адрес и т.п.). Также можете добавить исходные изображения или данные, с которыми в дальнейшем будет работать специалист.',
	logo: 'Загрузите изображение логотипа. Если нет, тогда наш специалист подготовит его за вас.',
	ideas: 'Пришлите специалисту примеры, которые вам понравились. Уточните, что именно вас привлекло в них (сочетание цветов, шрифт, расположение элементов и т.д.). Даже если идей нет, найдите баннеры по вашей тематике и выберите несколько понравившихся для того, чтобы вы со специалистом быстрее нашли общий язык.',
}
