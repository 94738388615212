import React, { useContext, useEffect, useMemo, useState } from 'react'
import bem from '../../../utils/bem'
import '../../../styles/mediaformats-page.scss'
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import MediaInfo from './MediaInfo'
import ModalMediaFormat from './ModalMediaFormat'
import { useConfirm } from '../../UtilityComponents/ConfirmModalProvider'
import useProviderData from '../../../stores/providerData'
import { ToastsContext } from '../../UtilityComponents/ToastsContextProvider'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'
import useResponsive from '../../../utils/useResponsive'
import BottomSheet from '../../UtilityComponents/Bottomsheet'
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg'
import { useHistory } from 'react-router-dom'
import useHeaderTitle from 'src/components/page/useHeaderTitle'

const cn = bem('mediaformats-page')

const InitTabs = [
	{
		count: 0,
		display: 'Все',
		title: 'all',
	},
	{
		count: 0,
		display: 'Свободные',
		title: 'unused',
	},
	{
		count: 0,
		display: 'Забронированные',
		title: 'used',
	},
	{
		count: 0,
		display: 'Архив',
		title: 'archive',
	},
]
const MediaformatsPage = (props) => {
	const [provider, providerInterface] = useProviderData()
	const params = new URLSearchParams(props.location.search)
	const history = useHistory()
	const [tabs, setTabs] = useState<any>(InitTabs)
	// @ts-ignore
	const [filter, setFilter] = useState<string>(
		params.get('state') ? (params.get('state') as string) : 'all'
	)
	// @ts-ignore
	const [selected, setSelected] = useState<string>(
		params.get('id') ? (params.get('id') as string) : ''
	)
	const [edited, setEdited] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [bottomModal, setBottomModal] = useState(false)
	const [showLocationQuickView, setShowLocationQuickView] = useState(false)

	const { confirm } = useConfirm()
	const { isTablet } = useResponsive()
	const { addToast } = useContext(ToastsContext)

	const handleModalClose = () => {
		setEdited('')
		setShowModal(false)
	}
	async function handleMediaToArchive(id) {
		const name = provider.mediatypes.find((el) => el.id === id).name
		await providerInterface.ArchiveMedia(id)
		if (isTablet) setBottomModal(false)
		addToast({
			text: `Медиа-формат "${name}" перемещен в архив`,
			type: 'secondary',
			withAction: true,
			actionTitle: 'Отменить',
			actionCallBack: () => providerInterface.RestoreMedia(id),
		})
	}

	function handleMediaEdit(id) {
		setEdited(id)
		setBottomModal(false)
		setShowModal(true)
	}

	async function handleMediaFromArchive(id) {
		const name = provider.mediatypes.find((el) => el.id === id).name
		await providerInterface.RestoreMedia(id)
		if (isTablet) setBottomModal(false)
		addToast({
			text: `Медиа-формат "${name}" восстановлен.`,
			type: 'success',
		})
	}

	async function handleMediaDelete(id) {
		const name = provider?.mediatypes.find((el) => el.id === id).name
		const res = await confirm({
			text: `Медиа-формат «${name}» будет удален без возможности восстановления.`,
			title: 'Удалить навсегда?',
			closeButton: true,
			acceptText: 'Удалить',
			declineText: 'Отменить',
		})
		if (res) {
			await providerInterface.DeleteMedia(id)
			if (isTablet) setBottomModal(false)

			addToast({
				text: `Медиа-формат "${name}" навсегда удален.`,
				type: 'danger',
			})
		}
	}

	const handleModalShow = () => setShowModal(true)

	useEffect(() => {
		let tmp_unused = 0
		let tmp_used = 0
		let tmp_archive = 0
		provider?.mediatypes?.forEach((el, i) => {
			if (el?.state === 'unused' && !el.in_archive) tmp_unused++
			if (el?.state === 'used' && !el.in_archive) tmp_used++
			if (el?.in_archive) tmp_archive++
		})
		// @ts-ignore
		setTabs((prev) =>
			prev.map((el) => {
				if (el.title === 'all')
					return { ...el, count: tmp_used + tmp_unused }
				if (el.title === 'used') return { ...el, count: tmp_used }
				if (el.title === 'unused') return { ...el, count: tmp_unused }
				if (el.title === 'archive') return { ...el, count: tmp_archive }
				return null
			})
		)
	}, [provider])

	const FilteredData = useMemo(() => {
		if (!provider?.mediatypes) return []
		if (filter === 'all') {
			return provider?.mediatypes?.filter((el) => !el.in_archive)
		}
		if (filter === 'used') {
			return provider?.mediatypes?.filter(
				(el) => el.state === 'used' && !el.in_archive
			)
		}
		if (filter === 'unused') {
			return provider?.mediatypes?.filter(
				(el) => el.state === 'unused' && !el.in_archive
			)
		}
		if (filter === 'archive') {
			return provider?.mediatypes?.filter((el) => el.in_archive)
		}
	}, [filter, provider])

	useEffect(() => {
		params.delete('id')
		params.delete('state')

		params.append('id', selected.toString())
		params.append('state', filter.toString())

		history.push({ search: params.toString() })
	}, [filter, selected]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (FilteredData?.length === 0) return
		if (FilteredData?.find((el) => el.id === selected)) return
		if (FilteredData?.length !== 0) return setSelected(FilteredData[0].id)
		return setSelected('')
	}, [filter, FilteredData]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.body.style.overflowY = 'overlay'
		return () => {
			document.body.style.overflowY = ''
		}
	}, [showModal])

	useHeaderTitle('Медиа-форматы')

	return (
		<div className={cn()}>
			<div className={cn('title')}>
				<Button
					style={{ marginRight: '-227px' }}
					variant={'light'}
					onClick={() => setShowLocationQuickView(true)}
				>
					<svg
						width="14"
						height="13"
						viewBox="0 0 14 13"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={'me-1'}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M13.3895 0.125843C13.5633 0.251144 13.6663 0.452376 13.6663 0.666672V10C13.6663 10.2525 13.5237 10.4834 13.2978 10.5963L9.29782 12.5963C9.11013 12.6901 8.88922 12.6901 8.70153 12.5963L4.94852 10.7198L1.21049 11.9658C1.00719 12.0336 0.783713 11.9995 0.609868 11.8742C0.436022 11.7489 0.333008 11.5476 0.333008 11.3333V2.00001C0.333008 1.71305 0.516628 1.45829 0.788856 1.36755L4.78886 0.034216C4.9257 -0.0113988 5.07365 -0.0113988 5.21049 0.034216L8.99967 1.29728L12.7889 0.034216C12.9922 -0.0335502 13.2156 0.000542571 13.3895 0.125843ZM8.33301 2.48051L5.66634 1.59162V9.58798L8.33301 10.9213V2.48051ZM9.66634 10.9213L12.333 9.58798V1.59162L9.66634 2.48051V10.9213ZM4.33301 9.5195V1.59162L1.66634 2.48051V10.4084L4.33301 9.5195Z"
							fill="#212529"
						/>
					</svg>
					Смотреть на карте
				</Button>

				{
					showLocationQuickView && false
					// <ModalPlacementLocation
					// 	item={provider?.placements?.results?.find(
					// 		(e) =>
					// 			e?.id?.toString() === selected?.toString()
					// 	)}
					// 	show={showLocationQuickView}
					// 	onHide={() => setShowLocationQuickView(false)}
					// 	isTablet={isTablet}
					// 	setSelectedItem={setSelected}
					// 	selectedItem={selected}
					// 	props={props}
					// 	onEdit={handleEdit}
					// 	onDisable={handleDisable}
					// 	onEnable={handleEnable}
					// 	onDelete={handleDelete}
					// 	onArchive={handleToArchive}
					// 	onFromArchive={handleFromArchive}
					// />
				}
				<Button
					color="primary"
					size="sm"
					onClick={() => {
						setSelected('')
						handleModalShow()
					}}
				>
					Создать медиа-формат
				</Button>
			</div>

			<div className={cn('tabs')}>
				<AnimateSharedLayout>
					{tabs.map((tab) => {
						return (
							<motion.div
								key={tab.title}
								className={`tab`}
								onClick={(e) => setFilter(tab.title)}
								style={{
									position: 'relative',
									color:
										filter === tab.title
											? '#007BFF'
											: '#6C757D',
								}}
							>
								{tab.display}
								<Badge
									bg={
										filter === tab.title
											? 'primary'
											: 'secondary'
									}
								>
									{tab.count}
								</Badge>
								{filter === tab.title && <ActiveLine />}
							</motion.div>
						)
					})}
				</AnimateSharedLayout>
			</div>
			{provider?.mediatypes?.length === 0 ? (
				<NoContent showModal={handleModalShow} />
			) : (
				<>
					<div className={cn('content')}>
						<AnimateSharedLayout>
							<motion.div className="col col_left" layout>
								<AnimatePresence initial={false}>
									{FilteredData.map((i) => (
										<RenderMediaFormatItem
											i={i}
											selected={i.id === selected}
											setSelected={setSelected}
											handleModalShow={handleModalShow}
											handleMediaToArchive={
												handleMediaToArchive
											}
											handleMediaEdit={handleMediaEdit}
											handleMediaFromArchive={
												handleMediaFromArchive
											}
											handleMediaDelete={
												handleMediaDelete
											}
											setBottomModal={setBottomModal}
											key={i.id}
											isTablet={isTablet}
										/>
									))}
								</AnimatePresence>
							</motion.div>
						</AnimateSharedLayout>
						{!isTablet && (
							<>
								<div className={'separator'} />
								<div className="col col_right">
									<MediaInfo
										media={
											selected === ''
												? null
												: FilteredData.find(
														(el) =>
															el.id === selected
												  )
										}
										handleModalShow={handleModalShow}
										handleMediaEdit={handleMediaEdit}
									/>
								</div>
							</>
						)}
					</div>
				</>
			)}

			{showModal && (
				<ModalMediaFormat
					media={
						edited === ''
							? undefined
							: FilteredData.find((el) => el.id === edited)
					}
					handleClose={handleModalClose}
					show={showModal}
					setEdited={setEdited}
				/>
			)}
			{isTablet && (
				<BottomSheet
					show={bottomModal}
					onHide={() => setBottomModal(false)}
					fullSize
					Footer={
						<BottomControls
							media={
								selected === ''
									? null
									: FilteredData.find(
											(el) => el.id === selected
									  )
							}
							handleMediaToArchive={handleMediaToArchive}
							handleMediaEdit={handleMediaEdit}
							handleMediaFromArchive={handleMediaFromArchive}
							handleMediaDelete={handleMediaDelete}
						/>
					}
				>
					<div className={'p-4 pt-4 media_details'}>
						<MediaInfo
							media={
								selected === ''
									? null
									: FilteredData.find(
											(el) => el.id === selected
									  )
							}
							handleModalShow={handleModalShow}
							withControlsButtons={false}
						/>
					</div>
				</BottomSheet>
			)}
		</div>
	)
}
export default MediaformatsPage

export const ActiveLine = () => {
	return (
		<motion.div
			layoutId={'active-line'}
			style={{
				width: '100%',
				height: '2px',
				position: 'absolute',
				bottom: '-3px',
				left: '0',
				backgroundColor: '#0D6EFD',
			}}
		/>
	)
}

const NoContent: React.FC<any> = ({ showModal }) => (
	<div className={cn('nocontent')}>
		<span>🙂</span>
		<h2>Нет ни одного медиа-формата</h2>
		<Button color="primary" onClick={showModal}>
			Создать медиа-формат
		</Button>
	</div>
)
export const StatusToRus = (status) => {
	switch (status) {
		case 'used':
			return 'Забронирован'
		case 'unused':
			return 'Свободен'
		default:
			return null
	}
}

const BottomControls = ({
	media,
	handleMediaToArchive,
	handleMediaEdit,
	handleMediaFromArchive,
	handleMediaDelete,
}) => {
	if (!media) return null
	return (
		<>
			{/*  Кнопки, если статус свободен*/}
			{media.state === 'unused' && !media.in_archive && (
				<div className={cn('control_panel')}>
					<div
						className={'control-btn'}
						onClick={() => {
							handleMediaEdit(media.id)
						}}
					>
						<i className="bi bi-pencil" />
						<span style={{ fontWeight: 500 }}>Изменить</span>
					</div>

					<div
						className="control-btn"
						onClick={() => {
							handleMediaToArchive(media.id)
						}}
					>
						<i className="bi bi-trash" />
						<span style={{ fontWeight: 500 }}>Удалить</span>
					</div>
				</div>
			)}
			{media.state === 'unused' && media.in_archive && (
				<div className={cn('control_panel')}>
					<div
						className={'control-btn'}
						onClick={() => {
							handleMediaFromArchive(media.id)
						}}
					>
						<i className="bi bi-box-arrow-up" />

						<span style={{ fontWeight: 500 }}>Восстановить</span>
					</div>

					<div
						className="control-btn"
						onClick={() => {
							handleMediaDelete(media.id)
						}}
					>
						<i className="bi bi-trash" />
						<span style={{ fontWeight: 500 }}>
							Удалить навсегда
						</span>
					</div>
				</div>
			)}
		</>
	)
}
const RenderMediaFormatItem: React.FC<any> = ({
	i,
	selected,
	setSelected,
	setBottomModal,
	isTablet,
	handleMediaToArchive,
	handleMediaEdit,
	handleMediaFromArchive,
	handleMediaDelete,
}) => {
	return (
		<motion.div
			className={`list-item ${selected ? 'selected' : ''}`}
			key={i.id}
			onClick={() => {
				setSelected(i.id)
				setBottomModal(true)
			}}
			initial={{ opacity: 0 }}
			exit={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3 }}
			layout
			style={{ cursor: 'pointer' }}
		>
			<div className="left">
				{i.name}
				<span>
					{i.is_image && !i.is_video ? 'Изображение' : ''}
					{i.is_video && !i.is_image ? 'Видео' : ''}
					{i.is_video && i.is_image ? 'Изображение, видео' : ''}
				</span>
			</div>
			<div className="right">
				{i.state !== 'used' && !i.in_archive && (
					<>
						<OverlayTrigger
							placement="left"
							overlay={(props) => (
								<Tooltip id="media_edit-tooltip" {...props}>
									Изменить
								</Tooltip>
							)}
							delay={{ show: 250, hide: 400 }}
						>
							<i
								className="bi bi-pencil"
								onClick={(e) => {
									e.stopPropagation()
									handleMediaEdit(i.id)
								}}
							/>
						</OverlayTrigger>
						<OverlayTrigger
							placement="left"
							overlay={(props) => (
								<Tooltip id="media_delete-tooltip" {...props}>
									Удалить
								</Tooltip>
							)}
							delay={{ show: 0, hide: 100 }}
						>
							<i
								className="bi bi-trash"
								onClick={(e) => {
									e.stopPropagation()
									handleMediaToArchive(i.id)
								}}
							/>
						</OverlayTrigger>
					</>
				)}
				{i.in_archive && (
					<>
						<OverlayTrigger
							placement="left"
							overlay={(props) => (
								<Tooltip id="media_edit-tooltip" {...props}>
									Восстановить
								</Tooltip>
							)}
							delay={{ show: 250, hide: 400 }}
						>
							<i
								className="bi bi-box-arrow-up"
								onClick={(e) => {
									e.stopPropagation()
									handleMediaFromArchive(i.id)
								}}
							/>
						</OverlayTrigger>
						<OverlayTrigger
							placement="left"
							overlay={(props) => (
								<Tooltip id="media_delete-tooltip" {...props}>
									Удалить
								</Tooltip>
							)}
							delay={{ show: 0, hide: 100 }}
						>
							<i
								className="bi bi-trash"
								onClick={(e) => {
									e.stopPropagation()
									handleMediaDelete(i.id)
								}}
							/>
						</OverlayTrigger>
					</>
				)}
				{!i.in_archive && (
					<Badge bg={'used' === i.state ? 'primary' : 'secondary'}>
						{StatusToRus(i.state)}
					</Badge>
				)}
				{isTablet && (
					<Arrow
						fill={'#000'}
						style={{
							transform: 'rotate(180deg)',
							marginLeft: '30px',
						}}
					/>
				)}
			</div>
		</motion.div>
	)
}
