import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ReusableModalProps } from '../../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'

type onDone = false | string

const ModalBookingAdjustment: React.FC<ReusableModalProps<false | string>> = ({
	show,
	onHide,
	onDone,
}) => {
	const [comment, setComment] = useState('')
	async function handleSubmit() {
		if (comment === '') return onHide()
		onDone(comment)
	}
	return (
		<Modal show={show} onHide={onHide} centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>Исправление брони</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Напишите, что необходимо исправить заказчику, чтобы пройти
				модерацию
				<Form.Control
					as="textarea"
					rows={3}
					className={'mt-3'}
					placeholder={'Ваш комментарий'}
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onHide}>
					Отменить
				</Button>
				<Button variant="primary" onClick={handleSubmit}>
					Отправить
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalBookingAdjustment
