import React, { FC, useMemo, useState } from 'react'
import { Button, Card, Dropdown, Modal } from 'react-bootstrap'
import {
	deleteCompanyMedia,
	uploadCompanyMedia,
} from '../../../../../constants/api'
import {
	BookingType,
	PlacementShowingCountType,
	PlacementType,
} from '../../../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../../../stores/campaignData'
import useResponsive from '../../../../../utils/useResponsive'
import { spreadArray } from '../../../../_provider/Mediaformats/MediaInfo'
import BottomSheet from '../../../../UtilityComponents/Bottomsheet'
import ImageFullScreenWrapper from '../../../../UtilityComponents/ImageFullScreenWrapper'
import s from '../DataUpload.module.scss'
import { minMax } from './BookingUpload'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import { CreateCreativeFields } from '../CreateCreative/CreateCreativeForm'
import CreativeFileInput from '../../../../UtilityComponents/CreativeFileInput'
import useBidRequest, { bidInfo } from '../CreateCreative/useBidRequest'
import formatCreativeFormValues from '../CreateCreative/formatCreativeFormValues'
import LegalInformation from 'src/utils/LegalInformation'
import { authFetch } from 'src/utils/authFetch'
// import Iframe from 'react-iframe'
// import Embed from 'react-embed'

interface IBookingUploadItem {
	booking: BookingType
}

const BookingUploadItem: FC<IBookingUploadItem> = ({ booking }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	const { isHaveBidRequest } = useBidRequest()
	const [modalOpen, setModalOpen] = useState(false)
	const [iframeUrl, setIframeUrl] = useState('')

	const modalType = useMemo<REUSABLE_MODALS>(() => {
		return isMobile
			? REUSABLE_MODALS.createCreativeMobileModal
			: REUSABLE_MODALS.createCreativeDesktopModal
	}, [isMobile])
	const bidInfo: bidInfo = { id: booking.id, type: 'booking' }
	const showModal = useReusableModals()

	const [showDesktopModal, setShowDesktopModal] = useState<
		false | 'requirement' | 'info'
	>(false)
	const [showBottomModal, setShowBottomModal] = useState<
		false | 'requirement' | 'info'
	>(false)

	let file = useMemo(
		() =>
			campaign?.media.find(({ booking_id }) => booking.id === booking_id),
		[campaign?.media, booking]
	)

	const handleDesktopModalClose = () => setShowDesktopModal(false)

	const handleCreateMaterial = async () => {
		const requestData = {
			cls_name: 'booking',
			instance_id: booking.id,
		}

		try {
			const response = await authFetch({
				method: 'POST',
				url: `/core/api/v1/vinstant/adv_companies/${campaign.id}/`,
				body: requestData,
			})

			const url: any = response
			// setIframeUrl(url.redirect_url);
			// setModalOpen(true);

			// const editorWindow = window.open(url.redirect_url, 'Редактор', 'width=800,height=600');

			// window.location.href = url.redirect_url;

			window.open(url.redirect_url, '_blank')
		} catch (error) {
			console.error('Ошибка при отправке запроса:', error)
		}
	}

	const closeModal = () => {
		setModalOpen(false)
		setIframeUrl('')
	}

	return (
		<>
			{modalOpen && (
				<Modal
					onClose={closeModal}
					show={modalOpen}
					dialogClassName="modal-fullscreen"
				>
					<Modal.Header closeButton>
						<button className="close" onClick={closeModal}>
							&times;
						</button>
					</Modal.Header>
					<Modal.Body>
						{/* <iframe src={iframeUrl} title="Модальное окно" className="modal-iframe" /> */}
						{/* <Iframe
							url={iframeUrl}
							width="100%"
							height="500px"
							className="modal-iframe"
							display="initial"
							position="relative"
						/> */}

						{/* <Embed url={iframeUrl} /> */}
					</Modal.Body>
				</Modal>
			)}
			<Card className={s.card} style={{ marginBottom: '8px' }}>
				<div className={'d-flex mb-3'}>
					<div className={'me-3'}>
						<ImageFullScreenWrapper>
							<img
								src={booking.placement.image}
								alt={booking.placement.name}
								style={{
									width: '80px',
									height: '80px',
									objectFit: 'cover',
									objectPosition: '50% 50%',
									borderRadius: '4px',
								}}
							/>
						</ImageFullScreenWrapper>
					</div>
					<div
						className={'d-flex flex-column justify-content-center'}
					>
						<h5 className={'mb-1'}>
							{booking?.placement?.placement_type.name}
						</h5>
						<p className={'m-0 text-secondary'}>
							{booking?.placement?.name}
						</p>
					</div>
				</div>
				<CreativeFileInput
					bidInfo={{ id: booking.id, type: 'booking' }}
					initialValue={
						file
							? {
									src: file.file,
									name:
										file.file_name || 'Текущее изображение',
							  }
							: {
									src: null,
									name: '',
							  }
					}
					updateUrl={uploadCompanyMedia}
					removeUrl={deleteCompanyMedia.params(file?.id || 0)}
					updateMethod={'POST'}
					nameInBody={'file'}
					additionalBody={{ booking_id: booking.id }}
					description={`Допустимые форматы — ${spreadArray([
						...(booking.placement.media_type?.image_formats
							? booking.placement.media_type.image_formats
							: []),
						...(booking.placement.media_type?.video_formats
							? booking.placement.media_type.video_formats
							: []),
					])}. Ширина: ${minMax(
						booking.placement.media_type.min_width,
						booking.placement.media_type.max_width,
						'px'
					)}, высота: ${minMax(
						booking.placement.media_type.min_height,
						booking.placement.media_type.max_height,
						'px'
					)} , не более 20 МБ. Длительность — 5 секунд.`}
					allowedExtension={[
						...(booking.placement.media_type?.image_formats
							? booking.placement.media_type.image_formats
							: []),
						...(booking.placement.media_type?.video_formats
							? booking.placement.media_type.video_formats
							: []),
					]}
					maxSizeMb={20}
					onFileLoad={() => campaignInterface.refetchSelected()}
					onFileDelete={async () =>
						await campaignInterface.refetchSelected()
					}
				/>
			</Card>

			{/*Modals*/}

			{!isMobile && (
				<Modal
					className={s.modalContainer}
					show={showDesktopModal}
					onHide={handleDesktopModalClose}
					centered
					size={showDesktopModal === 'info' ? undefined : 'lg'}
					animation={true}
				>
					<Modal.Header closeButton className={s.modalHeader}>
						<Modal.Title as={'h5'}>
							{showDesktopModal === 'requirement' &&
								'Требования к загрузке материала'}
							{showDesktopModal === 'info' && 'Подробнее о месте'}
						</Modal.Title>
					</Modal.Header>

					{showDesktopModal === 'requirement' && (
						<Modal.Body>
							<PlaceRequirements placement={booking.placement} />
						</Modal.Body>
					)}
					{showDesktopModal === 'info' && (
						<InfoAboutPlace
							onHide={handleDesktopModalClose}
							placement={booking.placement}
							placement_showing_count={
								booking?.placement_showing_count
							}
						/>
					)}
				</Modal>
			)}
			{isMobile && (
				<BottomSheet
					show={Boolean(showBottomModal)}
					onHide={() => setShowBottomModal(false)}
					fullSize={true}
					bgOpacity={0.5}
					touchEvents={true}
				>
					<div className={'position-relative'}>
						{showBottomModal === 'requirement' ? (
							<PlaceRequirements placement={booking.placement} />
						) : (
							<InfoAboutPlace
								onHide={handleDesktopModalClose}
								placement={booking.placement}
								placement_showing_count={
									booking?.placement_showing_count
								}
							/>
						)}
					</div>
				</BottomSheet>
			)}
		</>
	)
}

export default BookingUploadItem

export const InfoAboutPlace = ({
	placement,
	onHide,
	placement_showing_count,
	withPrice = false,
	noMobileHeader = false,
}: {
	placement: Omit<PlacementType, 'placement_showing_counts'>
	onHide: () => any
	placement_showing_count: PlacementShowingCountType
	withPrice?: boolean
	noMobileHeader?: boolean
}) => {
	const [, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	return (
		<>
			{isMobile && !noMobileHeader && (
				<div
					style={{
						position: 'sticky',
						top: 0,
						left: 0,
						padding: '16px',
						borderBottom: '1px solid #CED4DA',
						backgroundColor: '#fff',
					}}
				>
					<h5 className={'mb-1'}>{placement?.placement_type.name}</h5>
					<p className={'m-0 text-secondary'}>{placement?.name}</p>
				</div>
			)}
			<div
				className={'p-0'}
				style={{ backgroundColor: '#fff', borderRadius: '8px' }}
			>
				{(!isMobile || noMobileHeader) && (
					<div
						style={{
							padding: isMobile ? '16px' : '40px 40px 24px 40px',
						}}
					>
						<h5 className={'mb-1'}>
							{placement?.placement_type.name}
						</h5>
						<p className={'m-0 text-secondary'}>
							{placement?.name}
						</p>
					</div>
				)}
				<div className={'d-flex justify-content-center'}>
					<ImageFullScreenWrapper>
						<img
							src={placement.image}
							alt={placement.name}
							style={{
								width: '100%',
								height: '320px',
								objectFit: 'cover',
								objectPosition: '50% 50%',
							}}
						/>
					</ImageFullScreenWrapper>
				</div>

				<div
					style={{
						padding: isMobile ? '16px' : '24px 40px 32px 40px',
					}}
					className={'d-grid'}
				>
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							Размер
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement?.size}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							Рекламу увидят
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement?.ots ? (
								`${parseFloat(placement.ots)} чел./день`
							) : (
								<span className={'text-secondary'}>
									Неизвестно
								</span>
							)}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							График показов
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement?.only_work_days
								? 'Только рабочие дни, '
								: 'В рабочие и выходные дни, '}
							<br />{' '}
							{placement?.works_on_holidays
								? 'включая праздничные'
								: 'не включая праздничные'}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							Срок размещения
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{campaignInterface.getCampaignPeriod()}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							{placement?.only_work_days
								? 'Рабочих дней'
								: 'Всего дней'}
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement_showing_count.ac_days_count}
						</div>
					</div>
					{withPrice && (
						<>
							<Dropdown.Divider className={'m-0 p-0'} />
							<div className={'mt-3 mb-3'}>
								{`${parseInt(
									placement_showing_count.price
								)} ₽ в день за ${placement_showing_count.name}`}
							</div>
							<Dropdown.Divider className={'m-0 p-0'} />
							<div
								className={
									'd-flex justify-content-between mt-3'
								}
							>
								<h5 style={{ fontSize: '20px' }}>
									Стоимость за период:
								</h5>
								<h5
									style={{
										fontSize: '20px',
										textAlign: 'right',
									}}
								>
									{`${placement_showing_count.ac_price} ₽`}
								</h5>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	)
}

export const PlaceRequirements = ({
	placement,
	noMobileHeader = false,
}: {
	placement: Omit<PlacementType, 'placement_showing_counts'>
	noMobileHeader?: boolean
}) => {
	const { media_type } = placement
	const { isMobile } = useResponsive()

	return (
		<>
			{isMobile && !noMobileHeader && (
				<div
					style={{
						position: 'sticky',
						top: 0,
						left: 0,
						padding: '16px',
						borderBottom: '1px solid #CED4DA',
						backgroundColor: '#fff',
					}}
				>
					<h6 className={'mb-0'}>
						Технические требования к загрузке материала
					</h6>
				</div>
			)}

			<div style={{ padding: isMobile ? '16px' : '' }}>
				<div className={'d-flex mb-3'}>
					<div className={'me-3'}>
						<ImageFullScreenWrapper>
							<img
								src={placement.image}
								alt={placement.name}
								style={{
									width: '80px',
									height: '80px',
									objectFit: 'cover',
									objectPosition: '50% 50%',
									borderRadius: '4px',
								}}
							/>
						</ImageFullScreenWrapper>
					</div>
					<div
						className={'d-flex flex-column justify-content-center'}
					>
						<h5 className={'mb-1'}>
							{placement?.placement_type.name}
						</h5>
						<p className={'m-0 text-secondary'}>
							{placement?.name}
						</p>
					</div>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Тип:</span>
					<span style={{ textAlign: 'right' }}>
						{media_type?.is_image && !media_type?.is_video
							? 'Изображение'
							: ''}
						{media_type?.is_video && !media_type?.is_image
							? 'Видео'
							: ''}
						{media_type?.is_video && media_type?.is_image
							? 'Изображение, видео'
							: ''}
					</span>
				</div>
				{(media_type?.video_formats || media_type?.image_formats) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Допустимый формат:
						</span>
						<span style={{ textAlign: 'right' }}>
							{spreadArray([
								...(media_type?.image_formats &&
								media_type?.is_image
									? media_type?.image_formats
									: []),
								...(media_type?.video_formats &&
								media_type?.is_video
									? media_type?.video_formats
									: []),
							])}
						</span>
					</div>
				)}
				{(media_type?.min_width || media_type?.max_width) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Ширина:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(
								media_type?.min_width,
								media_type?.max_width,
								'px'
							)}
						</span>
					</div>
				)}
				{(media_type?.min_height || media_type?.max_height) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Высота:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(
								media_type?.min_height,
								media_type?.max_height,
								'px'
							)}
						</span>
					</div>
				)}
				{media_type?.image_max_size && media_type?.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Размер изображения:
						</span>
						<span style={{ textAlign: 'right' }}>
							не более {media_type?.image_max_size} МБ
						</span>
					</div>
				)}
				{media_type?.video_max_size && media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Размер видео:</span>
						<span style={{ textAlign: 'right' }}>
							не более {media_type?.video_max_size} МБ
						</span>
					</div>
				)}
				{media_type?.aspect_ratio && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Соотношение сторон:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.aspect_ratio}
						</span>
					</div>
				)}
				{media_type?.color_model && media_type?.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Цветовая модель:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.color_model.toUpperCase()}
						</span>
					</div>
				)}
				{(media_type?.min_dpi || media_type?.max_dpi) &&
					media_type?.is_image && (
						<div
							className={'w-100 d-flex justify-content-between'}
							style={{
								borderBottom: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
							}}
						>
							<span className={'text-secondary'}>
								Точек на дюйм:
							</span>
							<span style={{ textAlign: 'right' }}>
								{minMax(
									media_type?.min_dpi,
									media_type?.max_dpi,
									'dpi'
								)}
							</span>
						</div>
					)}
				{media_type?.codec && media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Кодек:</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.codec}
						</span>
					</div>
				)}
				{(media_type?.min_fps || media_type?.max_fps) &&
					media_type?.is_video && (
						<div
							className={'w-100 d-flex justify-content-between'}
							style={{
								borderBottom: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
							}}
						>
							<span className={'text-secondary'}>
								Кадровая частота:
							</span>
							<span style={{ textAlign: 'right' }}>
								{minMax(
									media_type?.min_fps,
									media_type?.max_fps,
									'fps'
								)}
							</span>
						</div>
					)}
				{(media_type?.min_bitrate || media_type?.max_bitrate) &&
					media_type?.is_video && (
						<div
							className={'w-100 d-flex justify-content-between'}
							style={{
								borderBottom: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
							}}
						>
							<span className={'text-secondary'}>Битрейт:</span>
							<span style={{ textAlign: 'right' }}>
								{minMax(
									media_type?.min_bitrate,
									media_type?.max_bitrate,
									'Мбит/с'
								)}
							</span>
						</div>
					)}
				{media_type?.duration && media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Длительность:</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.duration} секунд
						</span>
					</div>
				)}
				{media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Звуковая дорожка:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.with_audio_track ? 'Да' : 'Нет'}
						</span>
					</div>
				)}
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>
						Юридическая информация:
					</span>
				</div>

				<div style={{ marginRight: '2px' }}>
					<LegalInformation />
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
					}}
				></div>
				<div
					className={'w-100 d-flex flex-column'}
					style={{
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>
						Дополнительная информация:
					</span>
					<p className={s.dopInfo}>
						Ознакомиться с требованиями вы также можете по{' '}
						<a href="https://www.21outdoor.ru/upload/medialibrary/fa3/21_vek_TV%20%D0%A2%D0%A2_6x3_vinyl_bez_polei.jpg">
							ссылке.
						</a>
					</p>
				</div>
			</div>
		</>
	)
}
