import React, { useContext, useState } from 'react'
import bem from '../../../utils/bem'
import { Badge, Dropdown, Modal } from 'react-bootstrap'
import { StatusToRus } from './MediaformatsPage'
import { minMax } from '../../_client/create-campaign/Step3-Upload/DataUpload'
import useProviderData from '../../../stores/providerData'
import { ToastsContext } from '../../UtilityComponents/ToastsContextProvider'
import { useConfirm } from '../../UtilityComponents/ConfirmModalProvider'
import { Link } from 'react-router-dom'
import declOfNum from '../../../utils/declOfNum'

export interface MediaType {
	id: string
	additional_info: string | null
	name: string
	is_image: boolean
	is_video: boolean
	video_formats: string[]
	image_formats: string[]
	min_height: number | null
	max_height: number | null
	min_width: number | null
	max_width: number | null
	aspect_ratio: string | null
	color_model: string | null
	codec: string | null
	min_fps: number | null
	max_fps: number | null
	min_bitrate: number | null
	max_bitrate: number | null
	duration: number | null
	with_audio_track: boolean
	min_dpi: number | null
	max_dpi: number | null
	image_max_size: number | null
	video_max_size: number | null
	external_link: string | null
	state: string
	in_archive: boolean
	placements: any[]
}

type MediaInfoProps = {
	media: MediaType | null | undefined
	handleModalShow: Function
	withControlsButtons?: boolean
	handleMediaEdit?: Function
}
const cn = bem('media-details')

const MediaInfo: React.FC<MediaInfoProps> = ({
	media,
	handleModalShow,
	withControlsButtons = true,
	handleMediaEdit,
}) => {
	const [, ProviderInterface] = useProviderData()
	const { addToast } = useContext(ToastsContext)
	const { confirm } = useConfirm()
	if (!media) return null
	return (
		<section className={cn()}>
			<div className={cn('info')}>
				<div className={'text-secondary mb-2'}>{media.id}</div>
				<div className={cn('name')}>
					<h5>{media.name}</h5>
					<Badge
						bg={'used' === media.state ? 'primary' : 'secondary'}
					>
						{StatusToRus(media.state)}
					</Badge>
				</div>
				{media?.placements.length !== 0 && (
					<RenderUsedPlacements placements={media.placements} />
				)}
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Тип:</span>
					<span style={{ textAlign: 'right' }}>
						{media.is_image && !media.is_video ? 'Изображение' : ''}
						{media.is_video && !media.is_image ? 'Видео' : ''}
						{media.is_video && media.is_image
							? 'Изображение, видео'
							: ''}
					</span>
				</div>
				{(media.video_formats || media.image_formats) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Допустимый формат:
						</span>
						<span style={{ textAlign: 'right' }}>
							{spreadArray([
								...(media?.image_formats && media.is_image
									? media.image_formats
									: []),
								...(media?.video_formats && media.is_video
									? media.video_formats
									: []),
							])}
						</span>
					</div>
				)}
				{(media?.min_width || media?.max_width) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Ширина:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_width, media.max_width, 'px')}
						</span>
					</div>
				)}
				{(media?.min_height || media?.max_height) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Высота:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_height, media.max_height, 'px')}
						</span>
					</div>
				)}
				{media?.image_max_size && media.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Размер изображения:
						</span>
						<span style={{ textAlign: 'right' }}>
							не более {media.image_max_size} МБ
						</span>
					</div>
				)}
				{media?.video_max_size && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Размер видео:</span>
						<span style={{ textAlign: 'right' }}>
							не более {media.video_max_size} МБ
						</span>
					</div>
				)}
				{media?.aspect_ratio && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Соотношение сторон:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.aspect_ratio}
						</span>
					</div>
				)}
				{media?.duration && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Длительность показа:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.duration} секунд
						</span>
					</div>
				)}
				{media?.color_model && media.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Цветовая модель:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.color_model.toUpperCase()}
						</span>
					</div>
				)}
				{(media?.min_dpi || media?.max_dpi) && media.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Точек на дюйм:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_dpi, media.max_dpi, 'dpi')}
						</span>
					</div>
				)}
				{media?.codec && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Кодек:</span>
						<span style={{ textAlign: 'right' }}>
							{media.codec}
						</span>
					</div>
				)}
				{(media?.min_fps || media?.max_fps) && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Кадровая частота:
						</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_fps, media.max_fps, 'fps')}
						</span>
					</div>
				)}
				{(media?.min_bitrate || media?.max_bitrate) && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Битрейт:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(
								media.min_bitrate,
								media.max_bitrate,
								'Мбит/с'
							)}
						</span>
					</div>
				)}
				{media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Звуковая дорожка:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.with_audio_track ? 'Да' : 'Нет'}
						</span>
					</div>
				)}

				<div
					className={'w-100 d-flex flex-column'}
					style={{
						paddingBottom: '8px',
						marginTop: '8px',
						marginBottom: '24px',
					}}
				>
					<span className={'text-secondary'}>
						Дополнительная информация:
					</span>
					<span className={'mt-2'}>
						{/*есть только текст*/}
						{media?.additional_info &&
							!media?.external_link &&
							media?.additional_info}
						{/*есть только ссылка*/}
						{!media?.additional_info && media?.external_link && (
							<a href={media.external_link}>
								{media.external_link}
							</a>
						)}
						{/*есть ссылка и текст*/}
						{media?.additional_info && media?.external_link && (
							<>
								{media.additional_info} <br />
								<a href={media.external_link}>
									{media.external_link}
								</a>
							</>
						)}
						{/*нет ничего*/}
						{!media?.additional_info &&
							!media?.external_link &&
							'Нет'}
					</span>
				</div>
			</div>
			{/*  Кнопки, если статус свободен*/}
			{media.state === 'unused' &&
				!media.in_archive &&
				withControlsButtons && (
					<div className={cn('control_panel')}>
						<div
							className={'control-btn'}
							onClick={() => {
								if (handleMediaEdit) handleMediaEdit(media?.id)
							}}
						>
							<i className="bi bi-pencil" />
							<span style={{ fontWeight: 500 }}>Изменить</span>
						</div>

						<div
							className="control-btn"
							onClick={() => {
								ProviderInterface.ArchiveMedia(media.id)
								addToast({
									text: 'Медиа-формат перемещен в архив',
									type: 'secondary',
									withAction: true,
									actionTitle: 'Отменить',
									actionCallBack: () =>
										ProviderInterface.RestoreMedia(
											media.id
										),
								})
							}}
						>
							<i className="bi bi-trash" />
							<span style={{ fontWeight: 500 }}>Удалить</span>
						</div>
					</div>
				)}
			{media.state === 'unused' &&
				media.in_archive &&
				withControlsButtons && (
					<div className={cn('control_panel')}>
						<div
							className={'control-btn'}
							onClick={() => {
								ProviderInterface.RestoreMedia(media.id)
								addToast({
									text: `Медиа-формат "${media.name}" восстановлен.`,
									type: 'success',
								})
							}}
						>
							<i className="bi bi-box-arrow-up" />

							<span style={{ fontWeight: 500 }}>
								Восстановить
							</span>
						</div>

						<div
							className="control-btn"
							onClick={async () => {
								const res = await confirm({
									text: `Медиа-формат «${media.name}» будет удален без возможности восстановления.`,
									title: 'Удалить навсегда?',
									closeButton: true,
									acceptText: 'Удалить',
									declineText: 'Отменить',
								})
								if (res) {
									await ProviderInterface.DeleteMedia(
										media.id
									)
									addToast({
										text: `Медиа-формат "${media.name}" навсегда удален.`,
										type: 'danger',
									})
								}
							}}
						>
							<i className="bi bi-trash" />
							<span style={{ fontWeight: 500 }}>
								Удалить навсегда
							</span>
						</div>
					</div>
				)}
		</section>
	)
}

export default MediaInfo

export const prettyThousands = (x: number | undefined | null | string) => {
	if (x === undefined) return ''
	if (!x) return ''
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
export const spreadArray = (ar: any[] | undefined, separator = ', ') => {
	if (ar === undefined) return ''
	let res = ''
	const length = ar.length
	ar.forEach((el, i) => {
		if (i !== length - 1) {
			res += el.toString() + separator
		} else {
			res += el.toString()
		}
	})
	return res
}

const PlacementLink = ({ placement, type = 'black' }) => {
	if (type === 'black')
		return (
			<Link
				className={'placement-link'}
				to={`/provider/placements?id=${placement.id}&state=all`}
			>
				{placement.name}
			</Link>
		)
	if (type === 'blue')
		return (
			<div style={{ marginTop: '8px', marginBottom: '8px' }}>
				<Link
					className={'placement-link text-decoration-none'}
					to={`/provider/placements?id=${placement.id}&state=all`}
				>
					{placement.name}
				</Link>
			</div>
		)
	return null
}

const RenderUsedPlacements = ({ placements }) => {
	const [more, setMore] = useState(false)
	const length = placements.length

	if (length <= 4)
		return (
			<div className={cn('placements')}>
				{'В '}
				{placements.map((placement, index) => (
					<React.Fragment key={placement.id}>
						<PlacementLink placement={placement} />
						{index === length - 1 ? '.' : ', '}
					</React.Fragment>
				))}
			</div>
		)

	return (
		<div className={cn('placements')}>
			{placements.map((placement, index) =>
				index <= 3 ? (
					<React.Fragment key={placement.id}>
						<PlacementLink placement={placement} />
						{index === 3 ? ' ' : ', '}
					</React.Fragment>
				) : null
			)}{' '}
			<span className={'text-secondary'}>
				и еще в {length - 4}{' '}
				{declOfNum(length - 4, ['месте', 'местах', 'местах'])}.
			</span>
			<div
				style={{ marginTop: '14px', marginBottom: '13px' }}
				className={'d-flex justify-content-center'}
			>
				<span
					onClick={(e) => {
						e.preventDefault()
						setMore(true)
					}}
					className={'text-primary text-decoration-none'}
					style={{
						fontWeight: 500,
						fontSize: 16,
						cursor: 'pointer',
					}}
				>
					Показать все
				</span>
			</div>
			{more && (
				<Modal show={more} onHide={() => setMore(false)} centered>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'}>Рекламные носители</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ padding: '24px' }}>
						{placements.map((placement, index) => (
							<>
								<PlacementLink
									placement={placement}
									type={'blue'}
								/>
								{index !== length - 1 && <Dropdown.Divider />}
							</>
						))}
					</Modal.Body>
				</Modal>
			)}
		</div>
	)
}
