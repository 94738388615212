import React, { FC, useMemo } from 'react'
import { ForeCast } from '../../../stores/ADMarketTypes.types'
import s from './mediaplan.module.scss'
import IntlFormatter from '../../../utils/intl-money-formatter'
import time from './images/time.svg'
interface IForeCastTable {
	forecast: ForeCast
}

const ForeCastTable: FC<IForeCastTable> = ({ forecast }) => {
	const bookingAD = useMemo<{
		[k in 'indoor' | 'outdoor']?: typeof forecast.booking
	}>(() => {
		let res: { [k in 'indoor' | 'outdoor']?: typeof forecast.booking } = {}
		if (forecast.booking.length === 0) return res
		forecast.booking.reduce((acc, b) => {
			if (b.placement.door_type === 'indoor') {
				if (acc.indoor) {
					acc.indoor = [...acc.indoor, b]
				} else {
					acc.indoor = [b]
				}
			}
			if (b.placement.door_type === 'outdoor') {
				if (acc.outdoor) {
					acc.outdoor = [...acc.outdoor, b]
				} else {
					acc.outdoor = [b]
				}
			}
			return acc
		}, res)
		return res
	}, [forecast])

	const internetAD = useMemo<{
		[k in 'vk' | 'yandex']?: typeof forecast.yandex
	}>(() => {
		const res: { [k in 'vk' | 'yandex']?: typeof forecast.yandex } = {}
		if (forecast.vk.is_selected) res.vk = forecast.vk
		if (forecast.yandex.is_selected) res.yandex = forecast.yandex
		return res
	}, [forecast])

	const tvAD = useMemo<{
		[k in 'teletarget' | 'adstream']?:
		| typeof forecast.adstream
		| typeof forecast.teletarget
	}>(() => {
		const res: {
			[k in 'teletarget' | 'adstream']?:
			| typeof forecast.adstream
			| typeof forecast.teletarget
		} = {}
		if (forecast.adstream.is_selected) res.adstream = forecast.adstream
		if (forecast.teletarget.is_selected)
			res.teletarget = forecast.teletarget
		return res
	}, [forecast])

	const totals = useMemo<{
		shows: number
		amount: number
	}>(() => {
		const res = { shows: 0, amount: 0 }
		for (const [, value] of Object.entries(internetAD)) {
			res.shows += value.showing_count ? value.showing_count : 0
			res.amount += value.amount ? value.amount : 0
		}
		for (const [, value] of Object.entries(tvAD)) {
			res.shows += value.showing_count ? value.showing_count : 0
			res.amount += value.amount ? parseFloat(value.amount) : 0
		}
		for (const [, bookings] of Object.entries(bookingAD)) {
			for (let value of bookings) {
				res.shows += value.showing_count ? value.showing_count : 0
				res.amount += value.booking_budget ? value.booking_budget : 0
			}
		}
		return res
	}, [bookingAD, internetAD, tvAD])
	return (
		<table className={s.forecastTable}>
			<tbody>
				<tr>
					<th
						rowSpan={2}
						style={{ width: '200px', borderTopLeftRadius: '8px' }}
					>
						Формат рекламы
					</th>
					<th rowSpan={2} style={{ width: '372px' }}>
						Место размещения
					</th>
					<th
						colSpan={3}
						style={{
							borderTopRightRadius: '8px',
							textAlign: 'center',
						}}
					>
						Прогнозные показатели
					</th>
				</tr>
				<tr>
					<th style={{ width: '180px', textAlign: 'right' }}>
						Показы
					</th>
					<th style={{ width: '148px', textAlign: 'right' }}>
						Стоимость без НДС
					</th>
				</tr>
				{Object.keys(bookingAD).length !== 0 &&
					Object.entries(bookingAD).map(([key, bookings]) =>
						bookings.map((b, index) => (
							<tr key={b.id}>
								{index === 0 && (
									<td
										rowSpan={bookings.length}
										style={{ verticalAlign: 'baseline' }}
									>
										{key === 'outdoor' &&
											'Наружная реклама'}
										{key === 'indoor' &&
											'Внутренняя реклама'}
									</td>
								)}
								<td >
									{b.placement.name}
								</td>
								<td className={s.tdNumbers}>{b.showing_count || 'Неизвестно'}</td>
								<td className={s.tdNumbers}>
									{IntlFormatter.format(
										b.booking_budget,
										false
									)}
								</td>
							</tr>
						))
					)}
				{Object.keys(internetAD).length !== 0 &&
					Object.entries(internetAD).map(([key, internet], index) => (
						<tr key={internet.id}>
							{index === 0 && (
								<td
									rowSpan={Object.keys(internetAD).length}
									style={{ verticalAlign: 'baseline' }}
								>
									Интернет реклама
								</td>
							)}
							<td>
								{key === 'yandex' && 'Яндекс'}
								{key === 'vk' && 'VK Реклама'}
							</td>
							<td className={s.tdNumbers}>{internet.showing_count || <span className={s.mediaplanLoad}>Загрузка <span className={s.mediaplanTime}><img src={time}/></span></span>}</td>
							<td className={s.tdNumbers}>
								{IntlFormatter.format(internet.amount, false)}
							</td>
						</tr>
					))}
				{Object.keys(tvAD).length !== 0 &&
					Object.entries(tvAD).map(([key, tv], index) => (
						<tr key={tv.id}>
							{index === 0 && (
								<td
									rowSpan={Object.keys(tvAD).length}
									style={{ verticalAlign: 'baseline' }}
								>
									ТВ-реклама{' '}
								</td>
							)}
							<td>
								{key === 'teletarget' && 'Teletarget'}
								{key === 'adstream' && 'Adstream '}
							</td>
							<td className={s.tdNumbers}>{tv.showing_count || <span className={s.mediaplanLoad}>Загрузка <span className={s.mediaplanTime}><img src={time}/></span></span>}</td>
							<td className={s.tdNumbers}>{IntlFormatter.format(tv.amount, false)}</td>
						</tr>
					))}
				<tr>
					<th
						colSpan={2}
						style={{
							borderBottomLeftRadius: '8px',
							textAlign: 'right',
						}}
					>
						Итого:
					</th>
					<th></th>
					<th style={{ borderBottomRightRadius: '8px' }}>
						{IntlFormatter.format(totals.amount, true)}
					</th>
				</tr>
			</tbody>
		</table>
	)
}

export default ForeCastTable
