import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import ReactDOM from 'react-dom'
import s from './NotificationsDesktop.module.scss'
import NotificationsContainer from './NotificationsContainer'
import useNotificationData from '../../stores/notificationsData'
import { useEventListeners } from '../../utils/useEventListeners'

const NotificationsDesktop = ({ desktopOpen, closeDesktop }) =>
	ReactDOM.createPortal(
		<AnimatePresence exitBeforeEnter>
			{desktopOpen && (
				<NotificationsDesktopContainer closeDesktop={closeDesktop} />
			)}
		</AnimatePresence>,
		document.body
	)

const NotificationsDesktopContainer = ({ closeDesktop }) => {
	const [notifications, notificationsInterface] = useNotificationData()
	const { addEventListener, cleanupListeners } = useEventListeners()

	useEffect(() => {
		addEventListener(document.body, 'click', (e) => {
			const path = e.composedPath()
			if (path.length !== 0) {
				let isClickOnContainer = false
				path.forEach((element) => {
					if (
						element.id === 'notification-desktop' ||
						element.id === 'notification-header-icon'
					)
						isClickOnContainer = true
				})
				if (!isClickOnContainer) closeDesktop()
			}
		})
		return () => {
			cleanupListeners()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<motion.div
			className={s.container}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			transition={{ duration: 0.2 }}
			id={'notification-desktop'}
		>
			<header>
				<h5>Уведомления</h5>
				{notifications.length !== 0 && (
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault()
							notificationsInterface.readAllNotifications()
						}}
					>
						Отметить все как прочитанное
					</a>
				)}
			</header>
			<section style={{ padding: '8px 16px', overflowY: 'auto' }}>
				<NotificationsContainer />
			</section>
		</motion.div>
	)
}

export default NotificationsDesktop
