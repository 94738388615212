import React, { useEffect, useMemo, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import useNotificationData from '../../stores/notificationsData'
import Notification from './Notification'
import NotificationsEmpty from './NotificationsEmpty'

const NotificationsContainer = (props) => {
	const [notifications, notificationsInterface] = useNotificationData()
	const [loadMoreAvailable, setLoadMoreAvailable] = useState(true)

	const loadMore = useMemo(() => {
		const notif_amount = notifications.length
		const total_amount = notificationsInterface.getCount()
		return notif_amount < total_amount
	}, [notifications]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!loadMoreAvailable) {
			setLoadMoreAvailable(true)
		}
	}, [notifications]) // eslint-disable-line react-hooks/exhaustive-deps

	if (!notifications || !Array.isArray(notifications)) return <></>
	if (notifications.length === 0) return <NotificationsEmpty />
	return (
		<>
			{notifications.map((notification, key) => (
				<React.Fragment key={notification.id}>
					<Notification notification={notification} />

					<Dropdown.Divider
						style={{ margin: 0, padding: 0, color: '#CED4DA' }}
					/>
				</React.Fragment>
			))}
			{loadMore && (
				<div
					style={{ marginTop: '22px', marginBottom: '22px' }}
					className={'d-flex justify-content-center'}
				>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault()
							if (!loadMoreAvailable) return
							setLoadMoreAvailable(false)
							notificationsInterface.loadNextNotifications()
						}}
						style={{
							textDecoration: 'none',
							fontWeight: 500,
						}}
					>
						<svg
							width="12"
							height="6"
							viewBox="0 0 12 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginRight: '7px' }}
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M5.52848 5.60947L0.861816 0.942809L1.80463 0L5.99989 4.19526L10.1952 0L11.138 0.942809L6.47129 5.60947C6.21094 5.86982 5.78883 5.86982 5.52848 5.60947Z"
								fill="#007BFF"
							/>
						</svg>
						Показать больше
					</a>
				</div>
			)}
		</>
	)
}

export default NotificationsContainer
