import React from 'react'
import s from './ErrorBoundary.module.scss'
import logo from '../../assets/images/logo.svg'
import { Button } from 'react-bootstrap'

const ErrorBoundary = (props) => {
	return (
		<div className={s.container}>
			<img src={logo} alt={'logo'} />
			<div>Что-то пошло не так...</div>
			<Button
				onClick={() => {
					window.location.href = window.location.origin
				}}
			>
				Вернуться на главную
			</Button>
		</div>
	)
}

export default ErrorBoundary
