import React from "react"
import { Button, ButtonGroup, Form } from "react-bootstrap"

interface AgeMarkingProps {
	value: string
	setFieldValue: (name: string, value: any) => null
	setFieldError: (name: string, error: any) => null
	error: string
}

export default function AgeMarking(props: AgeMarkingProps){
    const {value, setFieldValue, setFieldError, error} = props

    const MARKING = ['0', '6', '12', '16', '18']

    return (
        <Form.Group className={'mt-4'}>
            <Form.Label className="d-flex">
            Возрастная маркировка{' '}{value}
			</Form.Label>
        
            <ButtonGroup className="mb-3" vertical={false}>
				{MARKING.map((name, index) => (
					<Button
						disabled={String(value) !== String(name)}
						variant={
							String(value) === String(name)
								? 'primary'
								: 'secondary'
							}
						key={index}
					>
						{name}+
					</Button>
				))}
			</ButtonGroup>
        </Form.Group>
    )
}