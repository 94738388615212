import { useCallback, useEffect, useRef } from 'react'

export function autoDisposableEventListener(
	targetRefOrEl,
	type,
	listener,
	options
) {
	autoDisposableEventListener.stack.push(
		setEventListener(targetRefOrEl, type, listener, options)
	)
}

autoDisposableEventListener.stack = []
autoDisposableEventListener.dispose = function () {
	autoDisposableEventListener.stack.map((v) => v())
	autoDisposableEventListener.stack = []
}

export default function setEventListener(
	targetRefOrEl,
	type,
	listener,
	options = null
) {
	const target =
		targetRefOrEl.addEventListener && targetRefOrEl.removeEventListener
			? targetRefOrEl
			: targetRefOrEl.current
	target.addEventListener(type, listener, options)

	return () => target.removeEventListener(type, listener, options)
}

export function useEventListeners() {
	const cleanup = useRef<any>([])

	const addEventListener = useCallback(function addEventListener(
		target,
		type,
		listener,
		options = undefined
	) {
		const unlisten = setEventListener(target, type, listener, options)
		cleanup.current.push(unlisten)

		return () => {
			unlisten()
			cleanup.current = cleanup.current.filter(
				(callback) => callback !== unlisten
			)
		}
	},
	[])

	const cleanupListeners = useCallback(function cleanupListeners() {
		cleanup.current.forEach((callback) => callback())
		cleanup.current = []
	}, [])

	useEffect(() => () => cleanupListeners(), [cleanupListeners])

	return {
		addEventListener,
		cleanupListeners,
	}
}
