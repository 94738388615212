import React, { useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import s from '../components/_client/create-campaign/Step3-Upload/DataUpload.module.scss'
import urinfoimg from '../assets/images/img_admarket 1.svg'

const LegalInformation = () => {
	const [isImageExpanded, setIsImageExpanded] = useState(false)

	const handleImageClick = () => {
		setIsImageExpanded(!isImageExpanded)
	}
	const urinfoClassName = isImageExpanded
		? `${s.urinfo} ${s.expanded}`
		: s.urinfo
	const urinfoimgClassName = isImageExpanded
		? `${s.urinfoimg} ${s.urinfoimgExpanded}`
		: s.urinfoimg

	return (
		<Container className={s.urinfocontainer}>
			<Row>
				<Col xs={12} md={8} className={s.urinfo}>
					<span>
						В соответствии с федеральным законом «О рекламе»,
						<br />
						<a href="http://www.kremlin.ru/acts/bank/23532">
							N 38-ФЗ от 13.03.2006
						</a>
						, на рекламной конструкции должны быть указаны:
					</span>
					<ol>
						<li>Возрастная маркировка</li>
						<li>Пометка &quot;Реклама&quot;</li>
						<li>Название Юр. лица / Самозанятого</li>
						<li>Адрес регистрации Юр. лица / Самозанятого</li>
						<li>ИНН Юр. лица / Самозанятого</li>
					</ol>
				</Col>
			</Row>
			<Row>
				<Col
					xs={12}
					style={{ padding: 0, marginTop: 12 }}
					className={
						isImageExpanded ? s.urinfoimgExpandedWrapper : ''
					}
				>
					<div
						className={urinfoimgClassName}
						onClick={handleImageClick}
					>
						<Image
							className={urinfoimgClassName}
							src={urinfoimg}
							fluid
						/>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default LegalInformation
