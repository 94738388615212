import React, { FC } from 'react'
import { ReusableModalProps } from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import CreateCreativeForm, { CreateCreativeFields } from './CreateCreativeForm'
import { Button, Modal } from 'react-bootstrap'

const CreateCreativeSuccessModal: FC<ReusableModalProps<CreateCreativeFields>> =
	({ show, onHide, onDone }) => {
		return (
			<Modal show={show} onHide={onHide} centered>
				<div
					className={
						'position-relative d-flex flex-column gap-2 align-items-center'
					}
					style={{ padding: '40px' }}
				>
					<div
						style={{
							position: 'absolute',
							padding: '16px',
							cursor: 'pointer',
							top: 0,
							right: 0,
						}}
						onClick={onHide}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.0001 13.4143L18.293 19.7072L19.7072 18.293L13.4143 12.0001L19.7072 5.70727L18.293 4.29306L12.0001 10.5859L5.70718 4.29297L4.29297 5.70718L10.5859 12.0001L4.29297 18.2931L5.70718 19.7073L12.0001 13.4143Z"
								fill="#6C757D"
							/>
						</svg>
					</div>
					<svg
						width="40"
						height="40"
						viewBox="0 0 40 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={'mb-2'}
					>
						<path
							d="M19.4141 26.4141L29.4141 16.4141L26.5857 13.5857L17.9999 22.1715L12.4141 16.5857L9.58569 19.4141L16.5857 26.4141C17.3667 27.1952 18.6331 27.1952 19.4141 26.4141Z"
							fill="#28A745"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
							fill="#28A745"
						/>
					</svg>
					<h5 className={'mb-0'}>Заявка отправлена</h5>
					<span className={'mb-2 text-center'}>
						Если возникнут вопросы, специалист напишет вам на почту
					</span>
					<Button
						onClick={() => {
							onHide()
						}}
					>
						Хорошо, спасибо
					</Button>
				</div>
			</Modal>
		)
	}

export default CreateCreativeSuccessModal
