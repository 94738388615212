import React, { useEffect, useState } from 'react'
import '../../styles/file-input.scss'
import bem from '../../utils/bem'
import { Button } from 'react-bootstrap'
import useResponsive from '../../utils/useResponsive'
import ImageFullScreenWrapper from './ImageFullScreenWrapper'
import { PlaceholderPDF, PlaceholderVIDEO } from '../../utils/MediaPlaceHolders'
import { spreadArray } from '../_provider/Mediaformats/MediaInfo'

const cn = bem('file-input')
export interface FileInputProps {
	descr?: string
	onUpload: (file) => void
	value: any
	onRemove: () => void
	type?: string
	error?: any
	size: number
	disabled?: boolean
	allowedExtensions?: string[]
	withImageSizes?: boolean
}

export default function FileInput({
	descr = '',
	onUpload,
	value,
	onRemove,
	type = 'image',
	error = null,
	size = 0,
	disabled = false,
	allowedExtensions = [],
	withImageSizes = false,
}: FileInputProps) {
	const [light, setLight] = useState(false)
	// const inputRef: any = useRef(null)
	const [url, setUrl] = useState<string>(value?.src || '')
	const [fileError, setFileError] = useState('')
	const [blobType, setBlobType] = useState('')
	const { isMobile } = useResponsive()
	const [imgSize, setImgSize] = useState<null | string>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!value) {
			setUrl('')
			setBlobType('')
		} else if (value instanceof File) {
			const url = URL.createObjectURL(value)
			setUrl(url)
			setBlobType(value.type)
		} else if (value.src || value.url) {
			setUrl(value.src)
		}
	}, [value])

	function validateFile(file) {
		if (allowedExtensions.length !== 0) {
			//добавление jpg
			if (
				allowedExtensions?.includes('JPEG') ||
				allowedExtensions?.includes('jpeg')
			)
				allowedExtensions?.push('jpg')
			const fileExtension = file.name.split('.').pop()
			if (
				!allowedExtensions.find(
					(el) => el.toUpperCase() === fileExtension.toUpperCase()
				)
			) {
				setFileError('Некорректный формат файла')
				return false
			}
		} else {
			if (type === 'both') {
				if (
					!file.type.startsWith('image') &&
					!file.type.startsWith('video')
				) {
					setFileError('Некорректный формат файла')
					return false
				}
			} else {
				if (!file.type.startsWith(type)) {
					setFileError('Некорректный формат файла')
					return false
				}
			}
		}

		if (size && file.size > size * 2 ** 20) {
			setFileError(`Размер файла больше допустимого (${size} MБ)`)
			return false
		}
		return true
	}

	function handleDrop(e) {
		if (!disabled) {
			const file = e.dataTransfer.files[0]
			if (validateFile(file)) {
				handleEvent(e)
				setLight(false)
				onUpload(file)
			}
		}
	}

	async function handleChange(e) {
		if (!disabled) {
			setIsLoading(true)
			const file = e.target.files[0]
			if (validateFile(file)) {
				await onUpload(file)
				setIsLoading(false)
			} else {
				setIsLoading(false)
			}
			// inputRef.current.value = ''
		}
	}

	function handleEvent(e) {
		e.stopPropagation()
		e.preventDefault()
	}

	useEffect(() => {
		if (value && withImageSizes) {
			if (value?.src) {
				const img = new Image()
				img.src = value?.src
				img.onload = () => {
					setImgSize(`${img.width} x ${img.height} px`)
				}
			} else {
				try {
					const reader = new FileReader()
					reader.readAsDataURL(value)
					reader.onload = function (e) {
						const image = new Image()
						// @ts-ignore
						image.src = e?.target?.result.toString()
						image.onload = function () {
							setImgSize(`${image.width} x ${image.height} px`)
						}
					}
				} catch (e) {
					setImgSize(null)
				}
			}
		} else {
			setImgSize(null)
		}
	}, [value]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div
			className={cn(null, {
				highlighted: light,
				error: error || fileError,
			})}
			onDrop={handleDrop}
			onDragOver={(e) => {
				handleEvent(e)
				setLight(true)
			}}
			onDragLeave={(e) => {
				handleEvent(e)
				setLight(false)
			}}
		>
			<div className={cn('label')}>
				{fileError ? (
					<div className="d-flex justify-content-between w-100 align-items-center">
						<div
							className={cn(
								'error',
								bem.pass('d-flex justify-content-start')
							)}
						>
							{fileError}
						</div>
						<Button
							variant={'secondary'}
							size={'sm'}
							onClick={() => setFileError('')}
						>
							Отмена
						</Button>
					</div>
				) : url ? (
					<div className={cn('file-preview')}>
						<div className="d-flex overflow-hidden">
							<RenderPreview
								url={url}
								blobType={blobType !== '' ? blobType : null}
							/>
							<div
								className={
									'd-flex flex-column justify-content-center'
								}
							>
								{!!value?.name && (
									<div className={cn('file-name-text')}>
										{value.name}
									</div>
								)}
								{imgSize && (
									<span
										className={'text-secondary ms-3'}
										style={{ fontSize: '14px' }}
									>
										{imgSize}
									</span>
								)}
							</div>
						</div>
						{!disabled && (
							<i
								className={cn(
									'file-delete',
									bem.pass('bi bi-trash')
								)}
								onClick={async () => {
									if (isLoading) return
									setIsLoading(true)
									await onRemove()
									setIsLoading(false)
								}}
							/>
						)}
					</div>
				) : (
					<div
						className={cn('text')}
						style={isMobile ? { textAlign: 'center' } : {}}
					>
						<label className={cn('blue')}>
							Выберите файл
							<input
								disabled={disabled || isLoading}
								// ref={inputRef}
								className={cn('input')}
								type="file"
								onChange={handleChange}
								accept={
									allowedExtensions
										? spreadArray(
												allowedExtensions.map(
													(ext) =>
														'.' + ext.toLowerCase()
												),
												','
										  )
										: '*'
								}
							/>
						</label>{' '}
						{isMobile && <br />}
						<span className={'text-secondary'}>
							или перетащите его сюда
						</span>
					</div>
				)}
			</div>
			<div className={cn('restrictions')}>{descr}</div>
			<div className={cn('error')}>{error || fileError}</div>
		</div>
	)
}

const RenderPreview = ({ url, blobType }) => {
	const PDF = 'pdf',
		VIDEO = ['mp4', 'mov', 'avi', 'mpeg4'],
		IMAGE = ['png', 'jpeg', 'jpg', 'svg']
	const extension = url.split('.').pop().toLowerCase()
	const isPDF = extension === PDF
	const isImage = IMAGE.find((e) => e === extension)
	const isVideo = VIDEO.find((e) => e === extension)
	if (blobType) {
		if (blobType.startsWith('video')) return <PlaceholderVIDEO link={url} />
		if (blobType.startsWith('application'))
			return <PlaceholderPDF link={url} />
	}
	if (isPDF) return <PlaceholderPDF link={url} />
	if (isImage)
		return (
			<ImageFullScreenWrapper>
				<img src={url} className={cn('file-image')} alt={'preview'} />
			</ImageFullScreenWrapper>
		)
	if (isVideo) return <PlaceholderVIDEO link={url} />
	return (
		<ImageFullScreenWrapper>
			<img src={url} className={cn('file-image')} alt={'preview'} />
		</ImageFullScreenWrapper>
	)
}
