export const BASE_URL = '/core/api/v1'

export function withParams(urlFormatter) {
	return {
		params(...args) {
			return urlFormatter(...args)
		},

		toString() {
			throw new Error('API methods expects params')
		},
	}
}
// USER API
export const userData = `${BASE_URL}/me/`
export const login = `${BASE_URL}/login/`
export const register = `${BASE_URL}/registration/`
export const restorePassword = `${BASE_URL}/restore_password/`
export const changePassword = `${BASE_URL}/change_password/`
export const getJWTtoken = `${BASE_URL}/jwt_token/`

export const sendPhoneActivationCode = `${BASE_URL}/resend_phone_activation_code/`
export const sendPasswordRecoveryCode = `${BASE_URL}/resend_restore_password_code/`
export const sendPhoneChangeCode = `${BASE_URL}/resend_change_phone_code/`
export const acceptPhoneChangeCode = `${BASE_URL}/accept_change_phone_code/`
export const acceptPhoneActivationCode = `${BASE_URL}/accept_phone_activation_code/`
export const acceptPasswordRecoveryCode = `${BASE_URL}/accept_restore_password_code/`

export const companies = `${BASE_URL}/companies/`
export const company = withParams((id) => `${BASE_URL}/companies/${id}/`)
export const getCompanySuggestions = `${BASE_URL}/company_suggestions/`
export const getAddressSuggestions = `${BASE_URL}/address_suggestions/`
export const getIndustryTypes = `${BASE_URL}/industry_types/`

export const getPlacements = withParams(
	(door_type: 'indoor' | 'outdoor') =>
		`${BASE_URL}/adv_companies/placements/?door_type=${door_type}`
)

export const getPlacementInfo = withParams(
	(RKID, placement_id) =>
		`${BASE_URL}/adv_companies/${RKID}/available_placements/${placement_id}/`
)

export const createADCompany = `${BASE_URL}/adv_companies/`
export const advertisingCompanies = `${BASE_URL}/adv_companies/?limit=999&offset=0&order_by=ages`
export const advertisingCompaniesStats = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/stats/`
)
export const companyInfo = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/`
)
export const companyToModeration = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/to_moderation/`
)
export const companyToDraft = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/copy_to_draft/`
)
export const companyToCancel = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/to_canceled/`
)
export const updateBooking = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/booking/`
)

export const confirmBudget = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/confirm_budget/`
)
export const deleteBooking = withParams((id) => `${BASE_URL}/booking/${id}/`)
export const cancelBooking = withParams((id) => `${BASE_URL}/booking/${id}/`)

export const internetYandex = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/internet_yandex/`
)
export const internetVk = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/internet_vk/`
)
export const uploadCompanyMedia = `${BASE_URL}/adv_company_media/`
export const deleteCompanyMedia = withParams(
	(id) => `${BASE_URL}/adv_company_media/${id}/`
)
export const updateCompanyMedia = withParams(
	(id) => `${BASE_URL}/adv_company_media/${id}/`
)
export const teletargetFilters = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/banner-adv/`
)
export const channelAdv = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/channel-adv/`
)
export const campaignToPayment = withParams(
	(id) => `${BASE_URL}/adv_companies/${id}/to_payment_waiting/`
)
export const invoices = `${BASE_URL}/invoices/`
export const payments = `${BASE_URL}/payments/`
export const BidRequest = `${BASE_URL}/feedback/creative_bid/`

// PROVIDER API
export const changeProviderProfile = `${BASE_URL}/provider/profile/`
export const providerCheckInvite = `${BASE_URL}/provider/check_invite/`
export const providerInvites = `${BASE_URL}/provider/invites/`
export const providerCreateInvite = `${BASE_URL}/provider/invites/`
export const providerDeleteInvite = withParams(
	(id) => `${BASE_URL}/provider/invites/${id}/`
)
export const providerMembers = `${BASE_URL}/provider/members/`
export const registerProvider = `${BASE_URL}/provider/registration/`

export const providerCounts = `${BASE_URL}/provider/counts/`

export const providerMediaTypes = `${BASE_URL}/provider/media_types/`
export const updateMediaType = withParams(
	(id) => `${BASE_URL}/provider/media_types/${id}/`
)
export const archiveMedia = withParams(
	(id) => `${BASE_URL}/provider/media_types/${id}/to_archive/`
)
export const restoreMediaType = withParams(
	(id) => `${BASE_URL}/provider/media_types/${id}/restore/`
)
export const deleteMediaType = withParams(
	(id) => `${BASE_URL}/provider/media_types/${id}/delete/`
)
export const patchMediaType = withParams(
	(id) => `${BASE_URL}/provider/media_types/${id}/`
)
export const createMediaType = `${BASE_URL}/provider/media_types/`

export const providerPlacements = withParams(
	(state = '', limit = 10, offset = 0, in_archive = false) =>
		`${BASE_URL}/provider/placements/?state=${state}&limit=${limit}&offset=${offset}&in_archive=${in_archive}`
)
export const providerCreatePlacement = `${BASE_URL}/provider/placements/`
export const providerEditPlacement = withParams(
	(id) => `${BASE_URL}/provider/placements/${id}/`
)
export const providerArchivePlacement = withParams(
	(id) => `${BASE_URL}/provider/placements/${id}/to_archive/`
)
export const providerRestorePlacement = withParams(
	(id) => `${BASE_URL}/provider/placements/${id}/to_archive/`
)
export const providerDeletePlacement = withParams(
	(id) => `${BASE_URL}/provider/placements/${id}/archived/`
)

export const providerPlacementsTypes = `${BASE_URL}/provider/placement_types/`
export const providerCreateShowingPrice = withParams(
	(id) => `${BASE_URL}/provider/placements/${id}/showing_counts/`
)
export const providerDeleteShowingPrice = withParams(
	(id) => `${BASE_URL}/provider/showing_counts/${id}/`
)
export const providerBookingsList = `${BASE_URL}/provider/bookings/`
export const providerBookingInfo = withParams(
	(id) => `${BASE_URL}/provider/bookings/${id}/`
)
export const providerPatchBooking = withParams(
	(id) => `${BASE_URL}/provider/bookings/${id}/`
)
export const providerAdvCampaigns = `${BASE_URL}/provider/adv_campaigns/`
export const providerCompanies = `${BASE_URL}/provider/companies/`
export const exportBookings = `${BASE_URL}/provider/bookings/export/`
export const BookingsGroupOperations = `${BASE_URL}/provider/bookings/bulk_update/`
