import s from './NotificationsAlertProvider.module.scss'
import ReactDOM from 'react-dom'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { NotificationText } from '../Notification'
import useNotificationData from '../../../stores/notificationsData'

const NotificationsAlertProvider = () => {
	const [, notificationInterface] = useNotificationData()

	const [notifs, setNotifs] = useState<any>(notificationInterface.getAlerts())

	useEffect(() => {
		notificationInterface.subscribeOnAlerts(() => {
			setNotifs(notificationInterface.getAlerts())
		})
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	async function removeAlert(id) {
		await notificationInterface.removeAlert(id)
	}

	return ReactDOM.createPortal(
		<AnimateSharedLayout>
			<motion.div className={s.notificationAlertContainer} layout>
				<AnimatePresence>
					{notifs.map((notif) => (
						<NotificationAlert
							alert={notif}
							key={notif.id}
							onRemove={removeAlert}
						/>
					))}
				</AnimatePresence>
			</motion.div>
		</AnimateSharedLayout>,
		document.body
	)
}

const NotificationAlert = ({ alert, onRemove }) => {
	const [timer, setTimer] = useState<number>(0)
	const intervalRef = useRef<null | ReturnType<typeof setTimeout>>(null)
	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setTimer((p) => p + 100)
		}, 100)
	}, [])
	useEffect(() => {
		if (timer < 4000) return
		onRemove(alert.id)
	}, [timer]) // eslint-disable-line react-hooks/exhaustive-deps
	const onMouseEnter = (e) => {
		if (intervalRef.current) clearInterval(intervalRef.current)
	}
	const onMouseLeave = (e) => {
		intervalRef.current = setInterval(() => {
			setTimer((p) => p + 100)
		}, 100)
	}
	return (
		<motion.div
			className={s.notification}
			initial={{ x: 1000 }}
			animate={{ x: 0 }}
			exit={{ x: 1000 }}
			layout
			transition={{ duration: 0.5, ease: 'easeInOut' }}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className={s.closeBtn} onClick={() => onRemove(alert.id)}>
				<svg
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.00016 6.94273L10.1954 11.138L11.1382 10.1952L6.94297 5.99992L11.1382 1.80469L10.1954 0.861878L6.00016 5.05711L1.80487 0.861816L0.862061 1.80463L5.05735 5.99992L0.862061 10.1952L1.80487 11.138L6.00016 6.94273Z"
						fill="#212529"
					/>
				</svg>
			</div>
			<div className={s.notificationText}>
				<NotificationText msg={alert.message} links={alert.links} />
			</div>
		</motion.div>
	)
}

export default NotificationsAlertProvider
