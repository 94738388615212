import Banner from '../../../../../assets/images/teletarget/banner.png'
import LBanner from '../../../../../assets/images/teletarget/l-banner.png'
import BannerGuide from '../../../../../assets/images/teletarget/banner_guide.png'
import LBannerGuide from '../../../../../assets/images/teletarget/l-banner_guide.png'

type bannerVariantName = 'small_banner' | 'l_banner'
type bannerVariantsData = {
	key: bannerVariantName
	title: string
	desc: string
	image: string
	guideImage: string
	requirements: {
		desc: string
		duration: string
		format: string
		includes: string[]
		accessibility: string
	}
}

export type BannerVariantsType = {
	[k in bannerVariantName]: bannerVariantsData
}
const bannerVariants: BannerVariantsType = {
	small_banner: {
		key: 'small_banner',
		title: 'Баннер 750 x 100',
		desc: 'Баннер появляется поверх эфира телеканала. Мы рекомендуем данный формат для кратких и емких рекламных сообщений. Можно загрузить только изображение.',
		image: Banner,
		guideImage: BannerGuide,
		requirements: {
			desc: 'Находится по центру в нижней части экрана, перекрывая контент и занимая 8% от площади экрана.',
			duration: 'от 30 до 60 секунд',
			format: '750 x 100 px, статичный',
			includes: [
				'Изображение бренда'
			],
			accessibility: 'На всех каналах в HbbTV, IPTV, ОТТ 1TV',
		},
	},
	l_banner: {
		key: 'l_banner',
		title: 'L-баннер',
		desc: 'Появление L-баннера приводит к уменьшению окна с эфиром телеканала. Остальную площадь экрана (30%) занимает ваша реклама. Данный формат позволяет разместить больше информации. Расположите QR-код, чтобы получить переходы на ваш сайт или социальную сеть. Можно загрузить изображение или видеоролик.',
		image: LBanner,
		guideImage: LBannerGuide,
		requirements: {
			desc: `Занимает 30% от площади экрана и имеет форму буквы L.
			Окно с эфиром может быть расположено слева или справа на экране.
			Область эфира 896 x 504 px на изображении оставляйте пустой.`,
			duration: '20 секунд',
			format: '1280 x 720 px, статичный',
			includes: [
				'Изображение бренда',
				'QR-код для перехода по ссылке \n     на лендинг',
			],
			accessibility: 'На всех каналах в HbbTV, IPTV, ОТТ 1TV',
		},
	},
}

export default bannerVariants
