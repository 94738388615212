import { useFormikContext } from 'formik'
import React from 'react'

export function ScrollToError() {
	const formik = useFormikContext()
	const submitting = formik?.isSubmitting

	React.useEffect(() => {
		const input = document.querySelector('.is-invalid')
		if (input) {
			;(input as HTMLElement).focus()
			try {
				input.scrollIntoView({
					block: 'center',
				})
			} catch (e: any) {
				console.log('Error scrolling: ' + e.message)
			}
		}
	}, [submitting])
	return null
}
