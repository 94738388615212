import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import ProgressFileInput, { IProgressFileInput } from './ProgressFileInput'
import useCampaignData from '../../stores/campaignData'
import { Button } from 'react-bootstrap'
import useBidRequest, {
	bidInfo,
} from '../_client/create-campaign/Step3-Upload/CreateCreative/useBidRequest'

interface ICreativeFileInput {
	bidInfo: bidInfo
}
const CreativeFileInput: FC<ICreativeFileInput & IProgressFileInput> = memo(
	({ bidInfo, ...progressFileInputProps }) => {
		const [campaign] = useCampaignData()
		const { isHaveBidRequest } = useBidRequest()
		const [showBidRequest, setShowBidRequest] = useState<boolean>(() =>
			isHaveBidRequest(bidInfo)
		)
		const isHaveFileUploaded = useMemo<boolean>(() => {
			return (
				progressFileInputProps.initialValue?.src !== null &&
				progressFileInputProps.initialValue?.src !== ''
			)
		}, [progressFileInputProps.initialValue])

		useEffect(() => {
			setShowBidRequest(isHaveBidRequest(bidInfo))
		}, [campaign.bid_requests, bidInfo])

		if (isHaveFileUploaded) {
			return <ProgressFileInput {...progressFileInputProps} />
		}
		return showBidRequest ? (
			<div
				className={
					'd-flex w-100 justify-content-between align-items-center'
				}
				style={{
					border: '1px solid #CED4DA',
					borderRadius: '4px',
					padding: '10px 16px',
					gap: '16px',
				}}
			>
				<div>Материал будет создан с помощью специалиста Admarket</div>
				<Button
					variant={'secondary'}
					onClick={() => setShowBidRequest(false)}
				>
					Отменить
				</Button>
			</div>
		) : (
			<ProgressFileInput {...progressFileInputProps} />
		)
	}
)
CreativeFileInput.displayName = 'CreativeFileInput'

export default CreativeFileInput
