import React, { FC } from 'react'
import RowLabelValue from '../../../utils/RowLabelValue'
import { ForeCast } from '../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../stores/campaignData'
import declOfNum from '../../../utils/declOfNum'
import { spreadArray } from '../../_provider/Mediaformats/MediaInfo'
import removeSecondsFromTime from '../../../utils/removeSecondsFromTime'
import moment from 'moment'

interface ICampaignSummaryInfo {
	mediaplan: ForeCast
}

const CampaignSummaryInfo: FC<ICampaignSummaryInfo> = ({ mediaplan }) => {
	const [campaign, campaignInterface] = useCampaignData()

	function getCampaignPeriod() {
		const startMoment = moment(mediaplan.start_date)
		const endMoment = moment(mediaplan.end_date)
		const withYear = startMoment.year() !== endMoment.year()
		const withMonth = withYear || startMoment.month() !== endMoment.month()
		const start_text = startMoment.format(
			'D' + (withMonth ? ' MMMM' : '') + (withYear ? ' YYYY' : '')
		)
		return `c ${start_text} по ${endMoment.format('D MMMM YYYY')}`
	}

	return (
		<>
			<RowLabelValue label={'Название кампании'} value={mediaplan.name} />
			<RowLabelValue
				label={'Дата начала и окончания'}
				value={getCampaignPeriod()}
			/>
			<RowLabelValue
				label={'Срок размещения'}
				value={
					campaign.days_duration +
					' ' +
					declOfNum(campaign.days_duration, ['день', 'дня', 'дней'])
				}
			/>
			<RowLabelValue
				label={'Регион показа'}
				value={spreadArray(campaign.show_regions.map((e) => e.title))}
			/>
			<RowLabelValue
				label={'Время показа (по мск)'}
				value={`c ${removeSecondsFromTime(
					campaign.start_time
				)} по ${removeSecondsFromTime(campaign.end_time)}`}
			/>
			<RowLabelValue
				label={'Пол'}
				value={spreadArray(campaign.genders.map((e) => e.title))}
			/>
			<RowLabelValue
				label={'Возраст'}
				value={spreadArray(campaign.ages.map((e) => e.title))}
			/>
		</>
	)
}

export default CampaignSummaryInfo
