import React from 'react'

const NotificationsEmpty = () => {
	return (
		<div
			style={{ height: '100%' }}
			className={
				'd-flex justify-content-center align-items-center flex-column'
			}
		>
			<div style={{ fontSize: '40px' }}>🙂</div>
			<div
				style={{
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '24px',
					lineHeight: '32px',
					textAlign: 'center',
					letterSpacing: '-0.04em',
					color: '#6C757D',
					marginTop: '16px',
				}}
			>
				У вас пока не было уведомлений
			</div>
		</div>
	)
}

export default NotificationsEmpty
