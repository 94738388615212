import * as Sentry from '@sentry/react'
import { Integrations as Tracing } from '@sentry/tracing'

Sentry.init({
	dsn: 'https://e75af7bd96694cf5a542d25add0967ed@o1003533.ingest.sentry.io/5964041',
	environment: window.location.hostname,
	// @ts-ignore
	integrations: [
		new Tracing.BrowserTracing(),
		process.env.NODE_ENV === 'development' &&
			new Sentry.Integrations.Breadcrumbs({ console: false }),
	].filter(Boolean),
	tracesSampleRate: 1.0, // Be sure to lower this in production
})
