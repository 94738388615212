import React, { FC, useEffect, useMemo, useState } from 'react'
import useCampaignData from '../../../../../stores/campaignData'
import { BannerIcon, VideoIcon } from '../../Step2-ADformats/TVAd/TVSelector'
import useResponsive from '../../../../../utils/useResponsive'
import { Button, Modal, Spinner } from 'react-bootstrap'
import BottomSheet from '../../../../UtilityComponents/Bottomsheet'
import { AdStreamMediaRequirements } from './TvUploadConstants'
import { channelAdv, teletargetFilters } from '../../../../../constants/api'
import DetailItem from '../../../../../utils/DetailItem'
import bannerVariantsData from '../../Step2-ADformats/TVAd/bannerVariants.data'
import ImageFullScreenWrapper from '../../../../UtilityComponents/ImageFullScreenWrapper'
import useBidRequest, { bidInfo } from '../CreateCreative/useBidRequest'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import CreativeFileInput from '../../../../UtilityComponents/CreativeFileInput'
import { CreateCreativeFields } from '../CreateCreative/CreateCreativeForm'
import formatCreativeFormValues from '../CreateCreative/formatCreativeFormValues'
import LegalInformation from 'src/utils/LegalInformation'
import s from '../DataUpload.module.scss'
import { authFetch } from 'src/utils/authFetch'
import MediaPlanModal from 'src/components/_client/campaign-mediaplan/MediaPlanModal'

interface ITvUploadItem {
	adName: 'teletarget' | 'adstream' | 'tel'
	teletarget_banner_type?: 'l_banner' | 'small_banner'
	onChange?: (fileUrl: string) => any
}

const TvUploadItem: FC<ITvUploadItem> = ({
	adName,
	teletarget_banner_type,
	onChange,
}) => {
	const { isMobile } = useResponsive()
	const [campaign, campaignInterface] = useCampaignData()
	const [showDesktopModal, setShowDesktopModal] = useState<
		false | 'requirement'
	>(false)
	const [showBottomModal, setShowBottomModal] = useState<
		false | 'requirement'
	>(false)
	const [isLoading, setIsLoading] = useState(false)
	const [showMediaplan, setShowMediaplan] = useState(false)
	const isTeletarget = adName === 'teletarget'
	const isVideo = adName === 'adstream'

	const file = isTeletarget
		? campaign.teletarget[
				teletarget_banner_type === 'small_banner' ? 'image' : 'l_image'
		  ]
		: campaign.adstream.image

	const { isHaveBidRequest } = useBidRequest()
	const modalType = useMemo<REUSABLE_MODALS>(() => {
		return isMobile
			? REUSABLE_MODALS.createCreativeMobileModal
			: REUSABLE_MODALS.createCreativeDesktopModal
	}, [isMobile])
	const showModal = useReusableModals()
	const bidInfo: bidInfo = {
		id: isTeletarget ? campaign.teletarget.id : campaign.adstream.id,
		type: isTeletarget ? 'teletarget' : 'adstream',
	}
	const ModalTitle = useMemo(() => {
		if (isMobile) {
			switch (showBottomModal) {
				case 'requirement':
					if (adName === 'teletarget') {
						if (teletarget_banner_type === 'small_banner')
							return 'Требования к баннеру 750 x 100 px'
						if (teletarget_banner_type === 'l_banner')
							return 'Требования к L-баннеру'
						return ''
					}
					if (adName === 'adstream')
						return 'Технические требования к видеофайлу'
					return ''
				default:
					return ''
			}
		} else {
			switch (showDesktopModal) {
				case 'requirement':
					if (adName === 'teletarget') {
						if (teletarget_banner_type === 'small_banner')
							return 'Требования к баннеру 750 x 100 px'
						if (teletarget_banner_type === 'l_banner')
							return 'Требования к L-баннеру'
						return ''
					}
					if (adName === 'adstream')
						return 'Технические требования к видеофайлу'
					return ''
				default:
					return ''
			}
		}
	}, [isMobile, showDesktopModal, showBottomModal, adName])

	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isLoading) {
			timeoutId = setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isLoading])

	const handleMediaPlanClick = async () => {
		setIsLoading(true)
		try {
			await showModal(REUSABLE_MODALS.showMediaPlanModal)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<>
			<MediaPlanModal
				campaign={campaign}
				show={showMediaplan}
				onDone={() => setShowMediaplan(false)}
				onHide={() => setShowMediaplan(false)}
			/>
			<div className={'d-flex flex-column'}>
				<Button
					disabled={isLoading}
					variant={'light'}
					className={'mb-3'}
					onClick={() => setShowMediaplan(true)}
				>
					{isLoading ? (
						<>
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
								className="me-2"
							/>
							Загрузка медиаплана...
						</>
					) : (
						'Смотреть медиаплан'
					)}
				</Button>
				<div>
					<div className={'mb-2'}>
						{isVideo && 'Загрузка видеоролика'}
						{isTeletarget &&
							teletarget_banner_type === 'small_banner' &&
							'Загрузка баннера'}
						{isTeletarget &&
							teletarget_banner_type === 'l_banner' &&
							'Загрузка L-баннера'}
					</div>
					<CreativeFileInput
						bidInfo={bidInfo}
						initialValue={{
							src: '/img_billboard.jpg',
							name: 'Текущее изображение',
						}}
						updateUrl={
							isVideo
								? channelAdv.params(campaign.id)
								: teletargetFilters.params(campaign.id)
						}
						removeMethod={'PATCH'}
						nameInBody={
							isTeletarget
								? teletarget_banner_type === 'small_banner'
									? 'image'
									: 'l_image'
								: 'image'
						}
						description={
							isVideo
								? '1920 × 1080 px, в формате MP4, длительность 5 секунд.'
								: teletarget_banner_type === 'small_banner'
								? '750 × 100 px, в формате PNG'
								: '1280 х 720 px, в формате PNG'
						}
						allowedExtension={
							isVideo ? ['MP4', 'JPG'] : ['JPG', 'PNG', 'MP4']
						}
						onFileLoad={(data) => {
							onChange?.(data.image)
							campaignInterface.refetchSelected()
						}}
						onFileDelete={async () =>
							await campaignInterface.refetchSelected()
						}
					/>
				</div>
			</div>
			{/*Modals*/}
			{!isMobile && (
				<Modal
					show={showDesktopModal}
					onHide={() => setShowDesktopModal(false)}
					centered
					// size={'lg'}
					animation={true}
				>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} style={{ paddingLeft: '8px' }}>
							{ModalTitle}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={'p-0'}>
						{showDesktopModal === 'requirement' && (
							<TvAdRequirements
								adName={adName}
								teletarget_banner_type={teletarget_banner_type}
							/>
						)}
						{/*{showDesktopModal === 'mediaplan' && (*/}
						{/*	<TvAdMediaPlan adName={adName} />*/}
						{/*)}*/}
					</Modal.Body>
				</Modal>
			)}
			{isMobile && (
				<BottomSheet
					show={Boolean(showBottomModal)}
					onHide={() => setShowBottomModal(false)}
					fullSize={true}
					bgOpacity={0.5}
					touchEvents={true}
				>
					{showBottomModal === 'requirement' && (
						<TvAdRequirements
							adName={adName}
							modalTitle={ModalTitle}
							teletarget_banner_type={teletarget_banner_type}
						/>
					)}
					{/*{showBottomModal === 'mediaplan' && (*/}
					{/*	<TvAdMediaPlan*/}
					{/*		adName={adName}*/}
					{/*		modalTitle={ModalTitle}*/}
					{/*	/>*/}
					{/*)}*/}
				</BottomSheet>
			)}
		</>
	)
}

const TvAdRequirements: React.FC<ITvUploadItem & { modalTitle?: string }> = ({
	adName,
	modalTitle,
	teletarget_banner_type,
}) => {
	const { isMobile } = useResponsive()
	const [{ teletarget }] = useCampaignData()
	const banner_data =
		teletarget_banner_type === 'small_banner'
			? bannerVariantsData.small_banner
			: bannerVariantsData.l_banner
	const fontSize = isMobile ? '14px' : '16px'
	if (adName === 'adstream')
		return (
			<div className={'position-relative'}>
				{isMobile && (
					<div
						style={{
							position: 'sticky',
							top: 0,
							left: 0,
							padding: '16px',
							borderBottom: '1px solid #CED4DA',
							backgroundColor: '#fff',
						}}
					>
						<h6 className={'mb-0'}>{modalTitle}</h6>
					</div>
				)}
				<div
					style={isMobile ? { padding: '16px' } : { padding: '24px' }}
				>
					<h5 className={'mb-3'}>Видео</h5>
					{AdStreamMediaRequirements.video.map((el, index, array) => (
						<div
							className={'w-100 d-flex justify-content-between'}
							key={index}
							style={{
								borderBottom:
									index === array.length - 1
										? ''
										: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
								fontSize: fontSize,
							}}
						>
							<span className={'text-secondary'}>{el.name}</span>
							<span style={{ textAlign: 'right' }}>
								{el.value}
							</span>
						</div>
					))}
					<h5 className={'mb-3 mt-4'}>Аудио</h5>
					{AdStreamMediaRequirements.audio.map((el, index, array) => (
						<div
							className={'w-100 d-flex justify-content-between'}
							key={index}
							style={{
								borderBottom:
									index === array.length - 1
										? ''
										: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
								fontSize: fontSize,
							}}
						>
							<span className={'text-secondary'}>{el.name}</span>
							<span style={{ textAlign: 'right' }}>
								{el.value}
							</span>
						</div>
					))}
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
						}}
					></div>

					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Юридическая информация:
						</span>
					</div>
					<div className={s.legalInformationWrapper}>
						<LegalInformation />
					</div>
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
						}}
					></div>
					<span className={'text-secondary'}>
						Дополнительная информация:
					</span>
					<p className={s.dopInfo}>
						Ознакомиться с требованиями вы также можете по{' '}
						<a href="https://www.21outdoor.ru/upload/medialibrary/fa3/21_vek_TV%20%D0%A2%D0%A2_6x3_vinyl_bez_polei.jpg">
							ссылке.
						</a>
					</p>
				</div>
			</div>
		)
	if (adName === 'teletarget')
		return (
			<div className={'position-relative'}>
				{isMobile && (
					<div
						style={{
							position: 'sticky',
							top: 0,
							left: 0,
							padding: '16px',
							borderBottom: '1px solid #CED4DA',
							backgroundColor: '#fff',
						}}
					>
						<h6 className={'mb-0'}>{modalTitle}</h6>
					</div>
				)}
				<div
					style={isMobile ? { padding: '16px' } : { padding: '24px' }}
				>
					<ImageFullScreenWrapper>
						<img
							src={banner_data.guideImage}
							alt={''}
							style={isMobile ? { maxWidth: '100%' } : {}}
						/>
					</ImageFullScreenWrapper>
					<div
						className={'mt-4 mb-2'}
						style={{
							width: '492px',
							height: '72px',
							left: '24px',
							top: '325px',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '24px',
							letterSpacing: '-0.03em',
							color: '#212529',
						}}
					>
						{banner_data.requirements.desc}
					</div>
					<DetailItem
						left={'Длительность:'}
						right={banner_data.requirements.duration}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Формат:'}
						right={banner_data.requirements.format}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Что включает:'}
						right={
							<div className={'d-flex flex-column gap-2'}>
								{teletarget_banner_type === 'small_banner' ? (
									<p>
										{
											bannerVariantsData.small_banner
												.requirements.includes[0]
										}
									</p>
								) : (
									banner_data.requirements.includes.map(
										(e) => <li key={e}>{e}</li>
									)
								)}
							</div>
						}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Доступность:'}
						right={banner_data.requirements.accessibility}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Юридическая информация:'}
						right={''}
						bottomDivider={false}
						leftFixWidth={'200px'}
					/>
					<div className={s.legalInformationWrapper}>
						<LegalInformation />
					</div>

					<span className={'text-secondary'}>
						Дополнительная информация:
					</span>
					<p className={s.dopInfo}>
						Ознакомиться с требованиями вы также можете по{' '}
						<a href="https://www.21outdoor.ru/upload/medialibrary/fa3/21_vek_TV%20%D0%A2%D0%A2_6x3_vinyl_bez_polei.jpg">
							ссылке.
						</a>
					</p>
				</div>
			</div>
		)
	return <></>
}

export default TvUploadItem
