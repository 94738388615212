import React, {
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react'
import '../../styles/dropdown.scss'
import bem from '../../utils/bem'
import { useEventListeners } from '../../utils/useEventListeners'

const DropdownContext = React.createContext<any>(null)
const cn = bem('dropdown')

interface LayoutProps {
	children?: any
	trigger?: any
	initialIsOpen?: boolean
	behavior?: string
	direction?: string
	align?: string
	offset?: number
	closeDelayMs?: number
	block?: boolean
	disabled?: boolean
	closeOnTriggerClick?: boolean
	menuWidth?: string | number
	zIndex?: number
	portal?: boolean
	additionalStyles?: any
	forceOpen?: boolean
	highlighted?: boolean
	menuNearCursor?: boolean
	onOpen?: (...args: any) => any
	onClose?: (...args: any) => any
	triggerWidth?: string
}

interface MenuProps extends LayoutProps {
	menu: any
	shadow?: string
	[pass: string]: any
}

function flipDirection(direction) {
	switch (direction) {
		case 'top':
			return 'bottom'
		case 'right':
			return 'left'
		case 'bottom':
			return 'top'
		case 'left':
			return 'right'
		default:
			throw new Error(`Not a valid direction: "${direction}"`)
	}
}

function getAbsolutePositions(direction, align) {
	return { [flipDirection(direction)]: '100%', [align]: 0 }
}

function getMenuOffset(direction, offset) {
	return {
		[flipDirection(direction)]: offset,
	}
}

function getMenuPositionCssVars(position, offset, zIndex) {
	const style = {}
	for (const key of ['top', 'right', 'bottom', 'left']) {
		const currentPosition = position[key]
		const currentOffset = offset[key]
		style[`--dropdown-menu-position-${key}`] =
			`${currentPosition}`?.endsWith('%')
				? currentPosition
				: Number.isFinite(currentPosition)
				? `${currentPosition}px`
				: 'auto'
		style[`--dropdown-menu-offset-${key}`] = Number.isFinite(currentOffset)
			? `${currentOffset}rem`
			: '0'
	}
	style['zIndex'] = zIndex

	return style
}

function getEventCoordinates(e) {
	let clickCoords = { x: 0, y: 0 }
	if (e.pageX || e.pageY) {
		clickCoords.x = e.pageX
		clickCoords.y = e.pageY
	} else if (e.clientX || e.clientY) {
		clickCoords.x =
			e.clientX +
			document.body.scrollLeft +
			document.documentElement.scrollLeft
		clickCoords.y =
			e.clientY +
			document.body.scrollTop +
			document.documentElement.scrollTop
	}
	return clickCoords
}

function trasform(transform, clickCoords, triggerCoords, direction) {
	switch (direction) {
		case 'right':
			transform.x = clickCoords.x - triggerCoords.x - triggerCoords.width
			break
		case 'left':
			transform.x = clickCoords.x - triggerCoords.x
			break
		case 'top':
			transform.y = clickCoords.y - triggerCoords.y
			break
		case 'bottom':
			transform.y = clickCoords.y - triggerCoords.y - triggerCoords.height
			break
		default:
			break
	}

	return transform
}

function getTransform(clickCoords, triggerCoords, direction, align) {
	const transform = { x: 0, y: 0 }
	trasform(transform, clickCoords, triggerCoords, direction)
	trasform(transform, clickCoords, triggerCoords, align)
	return transform
}

function DropdownLayoutInner(
	{
		children,
		trigger,
		initialIsOpen = false,
		behavior = 'click',
		direction = 'bottom',
		align = 'left',
		offset = 1,
		closeDelayMs = 50,
		block = false,
		disabled = false,
		closeOnTriggerClick = true,
		menuWidth,
		zIndex = 2,
		additionalStyles,
		forceOpen = false,
		highlighted = false,
		menuNearCursor = false,
		onOpen,
		onClose,
		triggerWidth,
	}: LayoutProps,
	ref
) {
	const [isOpen, setIsOpen] = useState(initialIsOpen)
	const triggerRef = useRef<any>(null)
	const menuRef = useRef<any>(null)
	const closeMenuTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const menuPosition = useMemo(
		() => getAbsolutePositions(direction, align),
		[direction, align]
	)
	const menuOffset = useMemo(
		() => getMenuOffset(direction, offset),
		[direction, offset]
	)
	const { addEventListener, cleanupListeners } = useEventListeners()
	const [menuStyles, setMenuStyles] = useState(
		getMenuPositionCssVars(menuPosition, menuOffset, zIndex)
	)
	const [transform, setTransform] = useState({ x: 0, y: 0 })

	function open(e: any) {
		setIsOpen(true)
		if (behavior === 'click') {
			if (menuNearCursor && e) {
				const clickCoords = getEventCoordinates(e)
				const trigger: any = triggerRef.current
				const triggerCoords = {
					x: trigger.getBoundingClientRect().x + window.pageXOffset,
					y: trigger.getBoundingClientRect().y + window.pageYOffset,
					height: trigger.offsetHeight,
					width: trigger.offsetWidth,
				}
				const newTransform = getTransform(
					clickCoords,
					triggerCoords,
					direction,
					align
				)
				setTransform(newTransform)
			}
			const unlisten = addEventListener(document, 'click', (e) => {
				if (
					!(
						e.composedPath().includes(menuRef.current) ||
						(!closeOnTriggerClick &&
							e.composedPath().includes(triggerRef.current))
					)
				) {
					if (!forceOpen) {
						setIsOpen(false)
						unlisten()
					}
				}
			})
		} else if (behavior === 'hover') {
			const timeout: any = closeMenuTimeoutRef.current
			if (timeout) {
				clearTimeout(timeout)
			}
		}
	}

	function close(force = false) {
		if (isOpen && (force || !forceOpen)) {
			setIsOpen(false)
			const timeout: any = closeMenuTimeoutRef.current
			if (timeout) {
				clearTimeout(timeout)
				closeMenuTimeoutRef.current = null
			}

			cleanupListeners()
		}
	}

	const context = { isOpen, disabled, menuPosition, close, open, setIsOpen }

	useImperativeHandle(ref, () => ({ open, close }))

	function handleClick(e) {
		if (!disabled && behavior === 'click' && !isOpen) {
			e.stopPropagation()
			open(e)
		}
	}

	function handleMouseEnter() {
		if (!disabled && behavior === 'hover') {
			open(null)
		}
	}

	function handleMouseLeave() {
		if (behavior === 'hover') {
			closeMenuTimeoutRef.current = setTimeout(() => {
				close()
			}, closeDelayMs)
		}
	}

	useEffect(() => {
		if (isOpen) {
			void onOpen?.()
		} else {
			void onClose?.()
		}
	}, [isOpen, onOpen, onClose])

	useEffect(() => {
		const width = triggerRef.current.offsetWidth
		const newPositions = getAbsolutePositions(direction, align)
		const newStyles = getMenuPositionCssVars(
			newPositions,
			menuOffset,
			zIndex
		)
		if (width && menuWidth === '100%') {
			newStyles['width'] = width
			newStyles['maxWidth'] = '100%'
			newStyles['minWidth'] = '100%'
		} else if (menuWidth) {
			newStyles['width'] = menuWidth
		}
		setMenuStyles(newStyles)
	}, [menuPosition, menuOffset, menuWidth, zIndex, align, direction])

	const style = {
		...additionalStyles,
		...menuStyles,
		...(transform.x || transform.y
			? {
					transform: `translateX(${transform.x}px) translateY(${transform.y}px)`,
			  }
			: {}),
	}

	return (
		<div
			className={cn(null, { block, disabled, highlighted })}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={triggerWidth ? { width: triggerWidth } : {}}
		>
			<DropdownContext.Provider value={{ isOpen, close }}>
				<div
					ref={triggerRef}
					className={cn('trigger')}
					data-click-target-parent
					onClick={handleClick}
				>
					{typeof trigger === 'function' ? trigger(context) : trigger}
				</div>
				{isOpen && (
					<div
						ref={menuRef}
						className={cn('menu', 'absolute')}
						style={style}
					>
						{typeof children === 'function'
							? children(context)
							: children}
					</div>
				)}
			</DropdownContext.Provider>
		</div>
	)
}

export const DropdownLayout = React.forwardRef(DropdownLayoutInner)

function DropdownMenuInner(
	{
		trigger,
		menu,
		children,
		shadow,
		closeOnItemClick,
		className,
		button,
		...pass
	}: MenuProps,
	ref
) {
	trigger = trigger || children

	const dropdownLayoutRef = useRef<any>(null)
	useImperativeHandle(ref, () => dropdownLayoutRef.current)

	return (
		<DropdownLayout ref={dropdownLayoutRef} trigger={trigger} {...pass}>
			{menu}
		</DropdownLayout>
	)
}

export function DropdownArrow({ isOpen }) {
	const direction = isOpen ? 'up' : 'down'
	return <i className={cn('arrow', direction)} />
}

const Dropdown: React.FC<MenuProps> = React.forwardRef(DropdownMenuInner)

export default Dropdown
