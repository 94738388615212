import React, { useMemo, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { Button, Card, Dropdown, Form, Spinner } from 'react-bootstrap'
import useCampaignData from '../../../../../stores/campaignData'
import useResponsive from '../../../../../utils/useResponsive'
import { ConnectedFocusError } from 'focus-formik-error'
import { prettyThousands } from '../../../../_provider/Mediaformats/MediaInfo'
import s from '../DataUpload.module.scss'
import { AdIcon } from '../../Step2-ADformats/AdFormatSelector'
import BookingUploadItem from './BookingUploadItem'
import useBidRequest from '../CreateCreative/useBidRequest'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'

export default function BookingUpload({
	cn,
	onBack,
	onNext,
	isStepper,
	setGlobalStep,
	isLastStep,
	moveToModeration,
	onStepBack,
	step,
	totalSteps,
	door_type = '',
}) {
	const [campaign] = useCampaignData()
	const { isMobile } = useResponsive()
	const { isHaveBidRequest } = useBidRequest()
	const showModal = useReusableModals()
	const [isLoading, setIsLoading] = useState(false)

	const typed_bookings = useMemo(() => {
		switch (door_type) {
			case 'indoor':
				return campaign.indoor_bookings
			case 'outdoor':
				return campaign.outdoor_bookings
			default:
				return campaign.booking
		}
	}, [campaign, door_type])
	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isLoading) {
			timeoutId = setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isLoading])

	async function handleSubmit(values) {
		if (!isLastStep) {
			onNext()
		} else {
			await moveToModeration()
		}
	}
	function handleValidation(values) {
		const errors: any = {}
		return errors
	}

	const isContinueDisabled = useMemo<boolean>(() => {
		const media_ids = campaign.media.map((media) => media.booking_id)
		let result = false
		for (let i = 0; i < typed_bookings.length; i++) {
			const booking = typed_bookings[i]
			if (
				!media_ids.includes(booking.id) &&
				!isHaveBidRequest({ id: booking.id, type: 'booking' })
			) {
				result = true
				break
			}
		}
		return result
	}, [typed_bookings, campaign])

	const handleMediaPlanClick = async () => {
		setIsLoading(true)
		try {
			await showModal(REUSABLE_MODALS.showMediaPlanModal)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}
	return (
		<>
			<Formik
				initialValues={{}}
				onSubmit={handleSubmit}
				validate={handleValidation}
				validateOnChange={true}
			>
				{({
					values,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<Form>
						<ConnectedFocusError />

						<>
							<Card
								className={s.card}
								style={{ marginBottom: '8px' }}
							>
								<h2 style={{ marginBottom: '8px' }}>
									Загрузка материалов
								</h2>
								<Dropdown.Divider className={' p-0 m-0 mb-4'} />
								<Button
									variant={'light'}
									className={'mb-3'}
									onClick={handleMediaPlanClick}
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
												className="me-2"
											/>
											Загрузка медиаплана...
										</>
									) : (
										'Смотреть медиаплан'
									)}
								</Button>
								<div
									className={
										'd-flex justify-content-between mb-2'
									}
								>
									<h4 className={'mb-0'}>
										{door_type === 'outdoor' &&
											'Наружная реклама'}
										{door_type === 'indoor' &&
											'Внутренняя реклама'}
									</h4>
									<AdIcon name={'outdoor'} />
								</div>
								<p className={'mb-0'}>
									Загрузите материал для каждого рекламного
									места в соответствии с их техническими
									требованиями
								</p>
							</Card>
							{!!typed_bookings.length && (
								<BookingUploadList booking={typed_bookings} />
							)}
						</>

						<div className={cn('buttons')}>
							<Button
								variant={'primary'}
								onClick={handleSubmit}
								style={
									isMobile
										? { width: '100%', marginLeft: '10px' }
										: {}
								}
								disabled={isContinueDisabled}
							>
								Далее
							</Button>
							<Button variant={'secondary'} onClick={onBack}>
								Назад
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	)
}

function BookingUploadList({ booking }) {
	const orderedBookings = useMemo(() => {
		if (booking?.length !== 0) {
			const tmp = [...booking]
			return tmp.sort((a, b) => -b.id + a.id)
		}
		return booking
	}, [booking])

	return (
		<div>
			{orderedBookings.map((data) => (
				<BookingUploadItem booking={data} key={data.id} />
			))}
		</div>
	)
}

export const minMax = (min, max, prefix) => {
	if (min === max) return `${prettyThousands(min)} ${prefix}`
	if (min === null && max) return `${prettyThousands(max)} ${prefix}`
	if (max === null && min) return `${prettyThousands(min)} ${prefix}`
	return `${prettyThousands(min)} - ${prettyThousands(max)} ${prefix}`
}
