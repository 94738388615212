import React from 'react'

export default function BusinessDataForm({
	cn,
	onBack,
	onNextStep,
	initialValues,
	...props
}) {
	return <></>
}
