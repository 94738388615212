import React, { FC, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

interface IShowMore {
	children: React.ReactNode | React.ReactNode[]
	toggleComponent?: React.ReactNode
	initialOpenState?: boolean
}
const content = {
	hidden: { opacity: 1, height: 0 },
	visible: {
		opacity: 1,
		height: 'fit-content',
		transition: {
			duration: 0.4,
		},
	},
	exit: { display: 'none' },
}

const ShowMore: FC<IShowMore> = React.memo(
	({ children, toggleComponent, initialOpenState = false }) => {
		const [showMore, setShowMore] = useState<boolean>(initialOpenState)

		const onToggle = () => setShowMore((p) => !p)

		const Toggle: React.FC = () => {
			if (React.isValidElement(toggleComponent)) {
				return React.cloneElement(toggleComponent)
			}
			return <div>Показать все</div>
		}
		return (
			<>
				<div
					className={'d-flex position-relative'}
					style={{ paddingRight: '50px' }}
					onClick={() => onToggle()}
				>
					<Toggle />
					<i
						style={{
							rotate: !showMore ? '0deg' : '180deg',
							right: 2,
							top: 2,
						}}
						className={'position-absolute'}
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.52995 11.6095L2.86328 6.94281L3.80609 6L8.00135 10.1953L12.1966 6L13.1394 6.94281L8.47276 11.6095C8.21241 11.8698 7.7903 11.8698 7.52995 11.6095Z"
								fill="#212529"
							/>
						</svg>
					</i>
				</div>

				<AnimatePresence>
					{showMore && (
						<motion.div
							variants={content}
							initial={'hidden'}
							animate={'visible'}
							exit={'exit'}
						>
							{children}
						</motion.div>
					)}
				</AnimatePresence>
			</>
		)
	}
)
ShowMore.displayName = 'ShowMore'

export default ShowMore
