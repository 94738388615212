import React, { useContext, useReducer } from 'react'
import { Button, Modal } from 'react-bootstrap'
import useResponsive from '../../utils/useResponsive'

const ConfirmContext = React.createContext<any>(null)
const SHOW_CONFIRM = 'SHOW_CONFIRM'
const HIDE_CONFIRM = 'HIDE_CONFIRM'

const initialState = {
	show: false,
	text: '',
	title: '',
	closeButton: false,
	acceptText: '',
	declineText: '',
	acceptVariant: 'primary',
	isStatic: false,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_CONFIRM:
			return {
				show: true,
				text: action.payload.text,
				title: action.payload.title,
				closeButton: action.payload.closeButton,
				acceptText: action.payload.acceptText,
				declineText: action.payload.declineText,
				acceptVariant: action.payload.acceptVariant,
				isStatic: action.payload.isStatic,
			}
		case HIDE_CONFIRM:
			return initialState
		default:
			return initialState
	}
}

export const ConfirmModalProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<ConfirmContext.Provider value={[state, dispatch]}>
			{children}
			<ConfirmDialog />
		</ConfirmContext.Provider>
	)
}
const ConfirmDialog = () => {
	const { onConfirm, onCancel, confirmState, onCancelWithoutCallBack } =
		useConfirm()
	const { isMobile } = useResponsive()
	return confirmState.show ? (
		<Modal
			show={confirmState.show}
			onHide={onCancelWithoutCallBack}
			centered
			keyboard={!confirmState.isStatic}
			{...(confirmState.isStatic ? { backdrop: 'static' } : {})}
		>
			<Modal.Header closeButton={confirmState.closeButton}>
				<Modal.Title as={'h5'}>{confirmState.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{confirmState.text}</Modal.Body>
			<Modal.Footer
				className={`d-flex flex-${
					isMobile ? 'column' : 'row'
				} justify-content-end flex-nowrap`}
				style={{ padding: '1rem' }}
			>
				<Button
					variant="secondary"
					onClick={onCancel}
					className={isMobile ? 'w-100' : ''}
				>
					{confirmState.declineText}
				</Button>
				<Button
					variant={confirmState.acceptVariant}
					onClick={onConfirm}
					className={isMobile ? 'w-100' : ''}
				>
					{confirmState.acceptText}
				</Button>
			</Modal.Footer>
		</Modal>
	) : null
}

let resolveCallback
export function useConfirm() {
	const [confirmState, dispatch] = useContext(ConfirmContext)
	const onConfirm = () => {
		closeConfirm()
		try {
			resolveCallback(true)
		} catch (e) {}
	}

	const onCancel = () => {
		closeConfirm()
		try {
			resolveCallback(false)
		} catch (e) {}
	}

	const onCancelWithoutCallBack = () => {
		closeConfirm()
	}

	const confirm = ({
		text,
		title,
		closeButton,
		acceptText,
		declineText,
		acceptVariant = 'primary',
		isStatic = false,
	}) => {
		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				text,
				title,
				closeButton,
				acceptText,
				declineText,
				acceptVariant,
				isStatic,
			},
		})
		return new Promise<boolean>((res, rej) => {
			resolveCallback = res
		})
	}

	const closeConfirm = () => {
		dispatch({
			type: HIDE_CONFIRM,
		})
	}

	return {
		confirm,
		onConfirm,
		onCancel,
		confirmState,
		onCancelWithoutCallBack,
	}
}
