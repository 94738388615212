import React, { useContext, useEffect, useMemo, useState } from 'react'
import InternetUpload from './Internet/InternetUpload'
import BookingUpload from './Booking/BookingUpload'
import ReactDOM from 'react-dom'
import s from './DataUpload.module.scss'
import useResponsive from '../../../../utils/useResponsive'
import useCampaignData from '../../../../stores/campaignData'
import { prettyThousands } from '../../../_provider/Mediaformats/MediaInfo'
import TVUpload from './Tv/TVUpload'
import UploadStatus from './UploadStatus'
import yandexTracking from '../../../../utils/yandexTracking'
import { useConfirm } from '../../../UtilityComponents/ConfirmModalProvider'
import { useHistory } from 'react-router-dom'
import OutDoorUpload from './Booking/OutDoorUpload'
import InDoorUpload from './Booking/InDoorUpload'
import { ToastsContext } from '../../../UtilityComponents/ToastsContextProvider'
import { Button, Card } from 'react-bootstrap'
import { AutoPostingLegalInfo } from './auto-posting-legal-info/AutoPostingLegalInfo'

const DataUploadSteps = [
	{ label: 'Наружная реклама', Component: OutDoorUpload },
	{ label: 'Внутренняя реклама', Component: InDoorUpload },
	{ label: 'Интернет-реклама', Component: InternetUpload },
	{ label: 'ТВ-реклама', Component: TVUpload },
]
const DataUpload = ({ cn, onBack, setStep: setGlobalStep }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const history = useHistory()
	const { addToast } = useContext(ToastsContext)
	const { isMobile } = useResponsive()

	const isOutDoor = useMemo<boolean>(
		() => campaign?.outdoor_adv_is_selected,
		[campaign]
	)
	const isInDoor = useMemo<boolean>(
		() => campaign?.indoor_adv_is_selected,
		[campaign]
	)
	const isInternet = useMemo<boolean>(
		() =>
			campaign.internet_yandex.is_selected ||
			campaign.internet_vk.is_selected,
		[campaign]
	)
	const isTV = useMemo(() => campaign?.teletarget?.is_selected, [campaign])

	const isStepper = useMemo(
		() =>
			(isOutDoor && (isTV || isInternet || isInDoor)) ||
			(isInDoor && (isTV || isInternet || isOutDoor)) ||
			(isInternet && (isOutDoor || isTV || isInDoor)) ||
			(isTV && (isOutDoor || isInternet || isInDoor)),
		[isOutDoor, isInternet, isTV, isInDoor]
	)

	const DataUploadStepsOnlySelected = useMemo(() => {
		return DataUploadSteps.filter((step: any) => {
			if (step.label === 'Наружная реклама')
				if (isOutDoor) return { ...step }
			if (step.label === 'Внутренняя реклама')
				if (isInDoor) return { ...step }
			if (step.label === 'Интернет-реклама')
				if (isInternet) return { ...step }
			if (step.label === 'ТВ-реклама') if (isTV) return { ...step }
			return null
		})
	}, [isStepper]) // eslint-disable-line react-hooks/exhaustive-deps

	const [step, setStep] = useState(3)

	const moveToModeration = async () => {}

	function handleSubmit() {
		window.open('https://app.admarket.ru/auth/#/register')
	}

	function setNext() {
		if (step + 1 < DataUploadStepsOnlySelected.length) {
			setStep(step + 1)
		} else {
			handleSubmit()
		}
	}

	function UploadElement(label) {
		if (label === 'ТВ-реклама') {
			return (
				<TVUpload
					title={'ТВ-Реклама'}
					type="tv"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					moveToModeration={moveToModeration}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}

		if (label === 'Интернет-реклама') {
			return (
				<TVUpload
					title={'Интернет-реклама'}
					type="internet"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					moveToModeration={moveToModeration}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}

		if (label === 'Внутренняя реклама') {
			return (
				<TVUpload
					title={'Внутренняя реклама'}
					type="indoor"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					moveToModeration={moveToModeration}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}
		if (label === 'Наружная реклама') {
			return (
				<TVUpload
					title={'Наружная Реклама'}
					type="outdoor"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					moveToModeration={moveToModeration}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}
	}

	return (
		<Card className={cn('form')}>
				
						{/* <div className={s.container}></div> */}
			<AutoPostingLegalInfo/>
		
	
			
			{/* {JSON.stringify(DataUploadStepsOnlySelected)} */}
			{UploadElement(DataUploadStepsOnlySelected[0].label)} 
			{/* {UploadElement(DataUploadStepsOnlySelected[1].label)} */}
			{/* {UploadElement(DataUploadStepsOnlySelected[2].label)} */}
			{/* {UploadElement(DataUploadStepsOnlySelected[3].label)}  */}

	
		<div className={cn('buttons')}>
				<Button
					variant={'primary'}
					onClick={setNext}
					style={
						isMobile ? { width: '100%', marginLeft: '10px' } : {}
					}
				>
					Далее
				</Button>
				<Button variant={'secondary'} onClick={() => onBack()}>
					Назад
				</Button>
			</div>
		</Card>
	)
}

export default DataUpload

export const minMax = (min, max, prefix) => {
	if (min === max) return `${prettyThousands(min)} ${prefix}`
	if (min === null && max) return `${prettyThousands(max)} ${prefix}`
	if (max === null && min) return `${prettyThousands(min)} ${prefix}`
	return `${prettyThousands(min)} - ${prettyThousands(max)} ${prefix}`
}
