import { useEffect, useRef, useState } from 'react'
import { authFetch, plainFetch } from './authFetch'

type useQueryParams = {
	url: string
	AuthRequest?: boolean
	method?: 'GET' | 'POST' | 'PATCH' | 'DELETE'
	body?: {}
	cacheByUrl?: boolean
}

type useQueryResult<T> = [null | T, boolean, any]

const CACHE_TIME = 10000

export const cache: any = {}
export const promises: any = {}
const isInitialized: any = {}
const cacheTime: any = {}

export function getPromiseOrValue(url: string): any {
	return promises[url] || cache[url]
}

const useQuery = <T>({
	AuthRequest = true,
	url,
	cacheByUrl = false,
	method = 'GET',
	body = {},
}: useQueryParams): useQueryResult<T> => {
	const [data, setData] = useState<null | T>(null)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [error, setError] = useState<any>(null)
	const urlRef = useRef(url)
	urlRef.current = url

	if (!isInitialized[url] && !promises[url] && cacheByUrl) {
		isInitialized[url] = false
		promises[url] = getRequestOrValue()
	}

	useEffect(() => {
		;(async () => {
			if (cacheByUrl) {
				if (cache[url]) {
					setData(cache[url])
				}
			} else setData(null)

			setIsLoading(true)
			setError(null)

			const promise = (cacheByUrl && promises[url]) || getRequestOrValue()

			try {
				promises[url] = promise
				let data: any = await promise
				cache[url] = data
				if (urlRef.current === url) setData(data)
			} catch (e) {
				console.log('err', e)
				setData(null)
				setError(e)
			} finally {
				setIsLoading(false)
				delete promises[url]
			}
		})()
	}, [url])

	function getRequestOrValue() {
		if (!cacheByUrl) return request()

		const url = urlRef.current
		const cahceTimeDiff = Date.now() - cacheTime[url] || Infinity
		const isUpdate = cahceTimeDiff > CACHE_TIME

		let result
		if (isUpdate) {
			cacheTime[url] = Date.now()
			result = request()
		} else result = cache[url]

		return result
	}

	function request() {
		return AuthRequest
			? authFetch<T>({
					url,
					method,
					body,
			  })
			: plainFetch<T>({ url, method, body })
	}

	return [data, isLoading, error]
}

export default useQuery
