import React, { FC } from 'react'
import s from './Header.module.scss'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/logo.svg'
import useResponsive from '../../../utils/useResponsive'
import HeaderNotifications from '../../notifications/HeaderNotifications'
import Profile from '../Profile/Profile'

interface IHeader {
	onSidebarOpen: any
	noSidebar: any
	sidebarForceOpen: any
}

const Header: FC<IHeader> = ({
	onSidebarOpen,
	noSidebar,
	sidebarForceOpen,
}) => {
	const { isDesktop } = useResponsive()
	return (
		<header className={s.header}>
			<div className={s.logo}>
				<a href={'https://admarket.ru/'}>
					<img alt={'AdMarket'} src={logo} />
				</a>
			</div>
			<div id={'header-page-title'} className={s.pageTitle} />
			<HeaderNotifications />
			<Profile />
		</header>
	)
}

export default Header
