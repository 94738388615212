import {
	BookingType,
	CampaignInfoType,
	CampaignInfoTypeBookingsSeparation,
} from './ADMarketTypes.types'

const makeBookingsSeparation = (
	campaignInfo: CampaignInfoType
): CampaignInfoTypeBookingsSeparation => {
	const outdoor: BookingType[] = []
	const indoor: BookingType[] = []
	if (!campaignInfo) return campaignInfo
	if (campaignInfo.booking.length !== 0) {
		campaignInfo.booking.forEach((booking) => {
			if (booking.placement.door_type === 'outdoor') outdoor.push(booking)
			if (booking.placement.door_type === 'indoor') indoor.push(booking)
		})
	}

	return {
		...campaignInfo,
		indoor_bookings: indoor,
		outdoor_bookings: outdoor,
	}
}

export default makeBookingsSeparation
