import React, { useMemo } from 'react'
import s from './Notification.module.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useNotificationData from '../../stores/notificationsData'
import moment from 'moment'

export interface INotification {
	id: string
	created_at: string
	is_readed: boolean
	msg_type: string
	message: string
	links: any[]
	buttons: any[]
}

interface INotificationProps {
	notification: INotification
}

const Notification: React.FC<INotificationProps> = ({
	notification,
	...props
}) => {
	const { is_readed, message, links, id } = notification
	const [, notificationInterface] = useNotificationData()

	const display_date = useMemo(() => {
		const created_at = moment(notification.created_at)
		return created_at.format('D MMMM YYYY, HH:mm')
	}, [notification])

	return (
		<div className={s.notification}>
			<OverlayTrigger
				placement={'left'}
				overlay={
					<Tooltip id={`tooltip-notification`}>
						{is_readed
							? 'Отметить как непрочитанное'
							: 'Отметить как прочитанное'}
					</Tooltip>
				}
			>
				<div
					className={`${s.notificationIndicator} ${
						is_readed ? s.notificationRead : s.notificationNotRead
					}`}
					onClick={() => {
						if (!is_readed)
							return notificationInterface.readNotification(id)
						return notificationInterface.unReadNotification(id)
					}}
				/>
			</OverlayTrigger>

			<div className={s.notificationText}>
				<NotificationText msg={message} links={links} />
			</div>
			<div className={s.notificationDate}>{display_date}</div>
		</div>
	)
}

export const NotificationText: React.FC<any> = ({ msg, links }) => {
	const array = useMemo(() => {
		return msg.split('{}')
	}, [msg])

	const RenderLink = ({ index }) => {
		if (links[index].style === 'text') return <>{links[index].text}</>
		return <Link to={links[index].path}>{links[index].text}</Link>
	}

	return array.map((text, index) => (
		<React.Fragment key={text + index}>
			{text}
			{index !== array.length - 1 && links[index] && (
				<RenderLink index={index} />
			)}
		</React.Fragment>
	))
}

export default Notification
