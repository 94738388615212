import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Form, InputGroup, Modal } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { DatePicker } from './DropdownDatePicker'
import bem from '../../utils/bem'

export function normalizeMomentDate(momentDate) {
	return momentDate ? momentDate.toDate() : momentDate
}

function isValidDate(d: Date) {
	return !isNaN(d.getTime())
}

const cn = bem('custom-date-picker')

function getDate(string: string) {
	if (string.length === 10) {
		const res = /(\d{2})\.(\d{2})\.(\d{4})/.exec(string)
		if (res) {
			const [, day, month, year] = res
			if (!day || !month || !year) {
				return new Date()
			} else {
				return new Date(+year, +month - 1, +day)
			}
		} else {
			return new Date()
		}
	} else {
		return new Date()
	}
}

const ModalDateInput = ({
	value,
	onChange,
	error,
	title = '',
	disabled = false,
	className = '',
	firstAvaliableDate,
}) => {
	const [pendingValue, setPendingValue] = useState(value)
	const dateValue = useMemo(() => getDate(pendingValue), [pendingValue])
	const [currentMonth, setCurrentMonth] = useState(firstAvaliableDate)
	const [key, setKey] = useState(0)
	const [show, setShow] = useState(false)
	useEffect(() => {
		setPendingValue(value)
	}, [value])

	function handleChange(v) {
		setPendingValue(v)
		const newDate = moment(v, 'DD.MM.YYYY').toDate()
		if (isValidDate(newDate)) {
			onChange(newDate)
		}
	}

	function handleCurrentMonthChange(date) {
		setCurrentMonth(date)
		setKey((k) => (k ? 0 : 1))
	}
	return (
		<div className={cn('input', bem.pass(className))}>
			<InputGroup hasValidation>
				{!!title && <Form.Label>{title}</Form.Label>}
				<InputMask
					mask={'99.99.9999'}
					value={pendingValue}
					onChange={(e) => {
						handleChange(e.target.value)
					}}
					disabled={disabled}
				>
					{(inputProps) => (
						<Form.Control
							size="lg"
							type={'text'}
							isInvalid={!!error}
							disabled={disabled}
							{...inputProps}
							onClick={() => setShow(true)}
						/>
					)}
				</InputMask>
				<Modal
					show={show}
					onHide={() => setShow(false)}
					centered
					contentClassName={'modal-date-picker-content'}
					dialogClassName={'modal-date-picker'}
				>
					<div key={key}>
						<DatePicker
							value={dateValue}
							onChange={(date) => {
								const text = moment(date).format('DD.MM.YYYY')
								handleChange(text)
								setShow(false)
							}}
							initialVisibleMonth={() => currentMonth}
							onPrevYear={() =>
								handleCurrentMonthChange(
									currentMonth.clone().subtract(1, 'y')
								)
							}
							onNextYear={() =>
								handleCurrentMonthChange(
									currentMonth.clone().add(1, 'y')
								)
							}
							firstAvaliableDate={firstAvaliableDate}
						/>
					</div>
				</Modal>
				<Form.Control.Feedback type="invalid">
					{error}
				</Form.Control.Feedback>
			</InputGroup>
		</div>
	)
}

export default ModalDateInput
