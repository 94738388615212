import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import s from './indoorAdvHeader.module.scss'
import { ReactComponent as RadiusIcon } from 'src/assets/images/radius.svg'
import { ReactComponent as Location3Icon } from 'src/assets/images/location-3.svg'
import { ReactComponent as Railway2Icon } from 'src/assets/images/railway-2.svg'
import { ReactComponent as TransportIcon } from 'src/assets/images/transport.svg'
import { ReactComponent as YandexIcon } from 'src/assets/images/yandex.svg'
import { ReactComponent as VkIcon } from 'src/assets/images/vk.svg'
import { ReactComponent as ADStreamIcon } from 'src/assets/images/adstream.svg'
import { ReactComponent as TeletargetIcon } from 'src/assets/images/teletarget.svg'
import { ReactComponent as OkIcon } from 'src/assets/images/ok.svg'
import { ReactComponent as BillboardIcon } from 'src/assets/images/billboard_2.svg'
import { ReactComponent as PinIcon } from 'src/assets/images/pin-2.svg'
import { ReactNode } from 'react'

const BookingADFullScrean: React.FC<{
	show: boolean
	close: () => void
}> = ({ show, close }) => {
	if (show) {
		return (
			<div
				onClick={() => close()}
				style={{
					width: '100%',
					height: '100%',
					position: 'fixed',
					top: '0',
					left: '0',
					right: '0',
					bottom: '0',
					background: 'url(/advertisingOnMap2.png)',
					backgroundSize: 'cover',
					zIndex: 9999,
					cursor: 'pointer',
				}}
			>
				<div
					style={{
						width: '100%',
						height: '100%',
						position: 'fixed',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						background: 'rgba(0,0,0,0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#fff',
						fontSize: '22px',
						fontWeight: 'bold',
						textAlign: 'center'
					}}
				>
					Тут вы будете выбирать нужный адрес и экран для вашей
					рекламы
				</div>
				<div style={{ position: 'fixed', top: '13px', right: '24px' }}>
					<img src="/close-white.svg" alt="" />
				</div>
			</div>
		)
	}

	return null
}


export const IndoorPresentationCard = ({scene, cn}) => {
	const [show, setShow] = useState(false)

	const scenes = [
		{title: 'Наружная реклама', desc: 'Выберите способ размещения'},
		{title: 'Внутренняя реклама', desc: 'Реклама внутри помещений общественного назначения — в торговых центрах, фитнес-центрах, лифтах, кинотеатрах, ресторанах.'},
		{title: 'Общественный транспорт', desc: 'Реклама на станциях и в поездах Московского центрального кольца (МЦК) и Московских Центральных Диаметров (МЦД)'},
		{title: 'Интернет-реклама', desc: 'Выберите способы показа рекламы. Реклама будет транслироваться в Яндексе и VK.'},
		{title: 'ТВ-реклама', desc: 'Реклама будет транслироваться на площадках AdStream и Teletarget.'},
	]

	const currentScene = scenes[scene] ?? scenes[0]


	return (
		<>
			{scene === 0 ? <>
				<Card className={cn('form')}>
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							<BillboardIcon/>
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>
			
			<div className={s.selectors}>
				<OutdoorVariantSelector icon={<RadiusIcon/>} title="Максимальный охват" description="Размещение минимум на 3 экранах. Нельзя указать время показа." isSelected={true}/>
				<OutdoorVariantSelector icon={<Location3Icon/>} title="Гарантированные показы" description='Размещение на 1 и более экранах. Можно указать время показа.' isSelected={true}/>
			</div>
			</Card>
				<Card className={cn('form')}>
				<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Гарантированные показы</h5>
						<Location3Icon/>
					</div>
					<p className='mb-3'>
					Данное размещение доступно минимум на 2 недели. Выгодно для тех кому нужен гарантированный показ в заданный промежуток времени на выбранном экране.
					</p>
				</div>
			</div>

			<Button
				disabled={false}
				onClick={() => setShow(true)}
			>
				<i className="bi bi-plus-lg me-2" />
				Добавить места
			</Button>

				
				
				</Card>
				<Card className={cn('form')}>
			<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Mаксимальный охват</h5>
						<RadiusIcon/>
					</div>
					<p>
						Распределение показов в рамках бюджета рекламной
						кампании по всем свободным экранам в выбранных городах.
						Выгодно для тех, кому важна минимальная стоимость за 1
						выход ролика.
					</p>
				</div>
			</div>

			<div style={{display: 'flex', gap: '20px', margin: '30px 0'}}>

				<Form.Group className={s.controlCity}>
					<Form.Label>{'Город'}</Form.Label>
					<Form.Control type="text" value="Москва" size="lg" disabled />

				</Form.Group>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Бюджет на город'}</Form.Label>
					<div style={{ display: 'flex', gap: '0.5rem' }}>
						<Form.Control type="text" value="30 000,00 ₽" size="lg" disabled />
					</div>
					<div style={{marginTop: '0.25rem', color: 'rgb(108, 117, 125)', fontSize: '12px'}}>
						Минимальный бюджет: 21&nbsp;000&nbsp;₽</div>
				</Form.Group>

				<div style={{display: 'flex', alignItems: 'center', paddingTop: '12px'}}>
					<i className="bi bi-trash"></i>
				</div>


			</div>

			<Button
				disabled={false}
				onClick={() => setShow(true)}
			>
				<PinIcon/>
				Уточнить места
			</Button>

			<div style={{marginTop: '1.5rem', marginBottom: '1.5rem', borderTop: '1px solid #ced4da'}}></div>

			<Button
				disabled={true}
				
			>
				<i className="bi bi-plus-lg me-2" />
				Добавить еще город
			</Button>
			</Card>
			
			</> : null}
			{scene === 1 ? 
				(
					<div className={s.indoorPresentationCardContent}>
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>

				<Button onClick={() => setShow(true)} >Добавить места</Button>
				
			</div>
				) : null
			}

			{scene === 2 ? <>
	
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>
			<div className={s.selectors}>
				<OutdoorVariantSelector icon={<Railway2Icon/>} title="Москва – МЦК" description="64 экрана на 30 станциях Московского Центрального Кольца.
Средний пассажиропоток: > 58 млн. в месяц." isSelected={true}/>
				<OutdoorVariantSelector icon={<TransportIcon/>} title="Москва – МЦД" description='Экраны на станциях и в поездах Московских Центральных Диаметров.

Временно недоступно' isSelected={false}/>
			</div>
			<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Цифровые экраны на МЦК</h5>
			
					</div>
					<p>
					Равномерное распределение показов по всем 64 поверхностям на 30 станциях. Выбор станций прорабатывается оператором рекламных экранов и будет доступен позже.
					</p>
				</div>
			</div>

			<div style={{display: 'flex', gap: '20px', margin: '30px 0'}}>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Бюджет'}</Form.Label>
					<Form.Control type="text" value="30 000,00 ₽" size="lg" disabled />

				</Form.Group>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Оплата'}</Form.Label>
					<div style={{ display: 'flex', gap: '0.5rem' }}>
						<Form.Control type="text" value="За показы" size="lg" disabled />
					</div>
				</Form.Group>


			</div>
			
			</> : null}

			{scene === 3 ? <>
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>
			<div className={s.selectors}>
				<OutdoorVariantSelector icon={<YandexIcon/>} title="Яндекс" description="Авито, Mail.ru, Яндекс.Новости, Яндекс.Дзен, Яндекс.Спорт, Gismeteo и другие" isSelected={true}/>
				<OutdoorVariantSelector icon={<VkIcon/>} title="VK" description='ВКонтакте, Одноклассники, Юла, Mail.ru, MyTarget' isSelected={false}/>
			</div>

			</> : null}

			{scene === 4 ? <>
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>
			<div className={s.selectors}>
				<OutdoorVariantSelector icon={<ADStreamIcon/>} title="" description="Реклама в сетях операторов IPTV и SmartTV

Лидер на рынке генерации, автоматизации, и учета трафика видео - рекламы" isSelected={true}/>
				<OutdoorVariantSelector icon={<TeletargetIcon/>} title="" description='Интерактивная реклама на ТВ

Временно недоступно' isSelected={false}/>
			</div>

			</> : null}
			

			<BookingADFullScrean show={show} close={() => setShow(false)} />
		</>
	)
}


type Props = {
	title: string
	isSelected: boolean
	description: string
	icon?: ReactNode
}
export const OutdoorVariantSelector = ({
	title,
	isSelected,
	description,
	icon
}: Props) => {

	return (
		<div
			className={`${isSelected ? s.format_selected : s.format} `}
		>
			<div className={s.icon}>
				{isSelected ? <OkIcon/> : (icon ? icon : null)}
			</div>
			<div className={s.title}>
				<div>{title}</div>
				<span>{description}</span>
			</div>
		</div>
	)
}
